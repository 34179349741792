import React, { useEffect, useRef, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import './CarrierManager.css';
import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  TextField,
  Typography,
  Grid
} from '@mui/material';
import { Col, Row } from 'reactstrap';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  AddMoreRate,
  AddMoreRateRequest,
  GetQuoteDetail,
  LogTLCarrierResponse
} from '../../apis/Agency';
import { MetaTags } from 'react-meta-tags';
import { base_logo_url, base_url } from '../../apis/constants';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import { ErrorToast } from '../Toasts';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import DatePicker from '@mui/lab/DatePicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // Import the calendar icon from Material-UI
import TodayIcon from '@mui/icons-material/Today';
import { data } from 'isotope-layout';
import { useIsMobile } from '../IsMobileHook/IsMobileHook';

export default function CarrierManagerArea(props) {
  const sessionId = props?.location?.search?.split('?')[1].split('=')[1];

  const [userInput, setUserInput] = useState('');
  const [userInputArray, setUserInputArray] = useState([]);
  const [showInputValue, setShowInputValue] = useState(false);

  const [charges, setCharges] = useState(0);
  const [refPickNmb, setRefPickNmb] = useState('--select--');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [pickupDate, setPickupDate] = useState('');
  const [htmlApiRes, setHtmlApiRes] = useState('');
  const [shipperApiMsgs, setShipperApiMsgs] = useState('');
  const [carrierApiMsgs, setCarrierApiMsgs] = useState('');
  const [sessionIdForCarrier, setSessionIdForCarrier] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [chatLoader, setChatLoader] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [expiryInMinutes, setExpiryInMinutes] = useState('');
  const [isBooked, setIsBooked] = useState(false);
  const [quoteApiError, setQuoteApiError] = useState('');
  const history = useHistory();
  const [timeExpired, setTimeExpired] = useState(false);
  const [quoteInitiatedTime, setQuoteInitiatedTime] = useState('');
  const [showMoreRateBtn, setShowMoreRateBtn] = useState(false);
  const [localTimeState, setLocalTimeState] = useState('');
  const [addMoreRateDialog, setAddMoreRateDialog] = useState(false);
  const [quoteTitle, setQuoteTitle] = useState('');

  function parseISODate(dateString) {
    const [datePart, timePart] = dateString.split('T');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');
    const milliseconds = parseFloat(timePart.split('.')[1] || 0);

    return new Date(
      year,
      month - 1,
      day,
      hours,
      minutes,
      seconds,
      milliseconds
    );
  }
  const handleSuccessResponse = apiRes => {
    const lastIndex = apiRes.data.data.length - 1;
    const expireyTime = apiRes.data.data.details[0].date;
    setQuoteTitle(apiRes.data.data.title);
    setChatLoader(false);
    setMainLoader(false);
    setExpiryInMinutes(apiRes.data.data.expiryInMinutes);
    // setQuoteInitiatedTime(expireyTime);
    const utcTimeString = expireyTime + 'Z';
    const utcDate = new Date(utcTimeString);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const localTime = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: userTimeZone
    }).format(utcDate);

    const finalTime = parseISODate(expireyTime);
    setLocalTimeState(localTime);
    const isBookedApi = apiRes.data.data.isBooked;
    isBookedApi && setTimeExpired(false);
    setIsBooked(isBookedApi);
    const carrierData = apiRes.data.data.details.filter(
      items => items.actionByFlag.toLowerCase() === 'carrier'
    );
    setHtmlApiRes(apiRes.data.data.details);
    setShipperApiMsgs(apiRes.data.data.details);
    setCarrierApiMsgs(carrierData);
  };

  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    // replace this with your specific time
    const checkTimePassed = () => {
      if (localTimeState) {
        const currentTime = new Date();
        const startTime = new Date(localTimeState);
        const timeDifference = currentTime - startTime;
        const thirtyMinutesInMilliseconds = 60 * 60 * 1000; // 30 minutes in milliseconds

        if (timeDifference >= thirtyMinutesInMilliseconds) {
          // Time has expired
          setTimeExpired(true);
        }
        // else {
        //   // Time is still valid
        //   alert('Time is still valid!');
        // }
      }
    };

    // Check time when the component mounts
    checkTimePassed();

    // Set up an interval to check the time every minute (adjust as needed)
    const intervalId = setInterval(checkTimePassed, 1 * 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [localTimeState]);

  const CarrierQuoteReqHandler = sessionId => {
    // setDeliveryDate('');
    // setCharges(0);
    // setRefPickNmb('');
    GetQuoteDetail(sessionId)
      .then(res => res.status === 200 && handleSuccessResponse(res))
      .catch(err => {
        setMainLoader(false);
        setQuoteApiError(err?.message);
      });
  };

  useEffect(() => {
    setMainLoader(true);
    // const sessionId = props?.location?.search?.split('?')[1].split('=')[1];
    setSessionIdForCarrier(sessionId);
    CarrierQuoteReqHandler(sessionId);
  }, []);

  const handleUserInput = input => {
    setUserInput(input);
  };

  const handleShowUserInputMsgs = () => {
    // setShowInputValue(true);
    if (!charges) {
      validationErrors['charges'] = 'Enter Charges';
    } else if (refPickNmb === '--select--') {
      validationErrors['serviceType'] = 'Select service type';
    } else if (!pickupDate) {
      validationErrors['pickupDate'] = 'Select Pickup Date';
    } else if (!deliveryDate) {
      validationErrors['deliveryDate'] = 'Select Estimated Delivery Date';
    }
    setValidationErrors(validationErrors);
    let temp = [...userInputArray];
    temp.push({ msg: userInput });
    setUserInputArray(temp);
    getCurrentDateTime();
    setUserInput('');

    const logTlRequestDateHandler = dateString => {
      const date = new Date(dateString);

      // Extracting date components
      const year = date.getFullYear();
      const month = date.toLocaleString('default', { month: 'numeric' });
      const day = date.getDate();

      // Creating formatted date string
      const formattedDate = `${year}-${month}-${day}`;

      return formattedDate;
    };
    if (charges && pickupDate && deliveryDate && refPickNmb !== '--select--') {
      setChatLoader(true);
      const chatObj = {
        sessionId: sessionIdForCarrier,
        charges: charges,
        pickupNumber: refPickNmb,
        pickupDate: logTlRequestDateHandler(pickupDate),
        estimatedDeliveryDate: logTlRequestDateHandler(deliveryDate),
        comments: userInput
      };
      LogTLCarrierResponse(chatObj)
        .then(res => {
          res.status === 200 && setShowMoreRateBtn(true);
          CarrierQuoteReqHandler(sessionIdForCarrier);
        })
        .catch(err => {
          setChatLoader(false);
          setShowMoreRateBtn(false);
        });
    }
  };
  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      handleShowUserInputMsgs();
    }
  };
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    // alert('scrollled');
    bottomRef.current &&
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Scroll to the bottom whenever userInputArray is updated
    scrollToBottom();
  }, [shipperApiMsgs]);

  const getCurrentDateTime = (() => {
    let initialTimestamp = null;

    return () => {
      if (!initialTimestamp) {
        initialTimestamp = new Date();
        let temp = [...userInputArray];
        temp.push({ timeStamp: initialTimestamp, msg: userInput });
        // temp.push({ msg: userInput });
        setUserInputArray(temp);
      }

      const now = new Date();
      const diff = now - initialTimestamp;

      const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ];
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];

      const dayName = daysOfWeek[initialTimestamp.getDay()];
      const monthName = months[initialTimestamp.getMonth()];
      const day = initialTimestamp.getDate();
      const year = initialTimestamp.getFullYear();

      const hours = initialTimestamp.getHours();
      const minutes = initialTimestamp.getMinutes();
      const ampm = hours >= 12 ? 'PM' : 'AM';

      const formattedTime = `${hours % 12 || 12}:${
        minutes < 10 ? '0' : ''
      }${minutes} ${ampm}`;
      let temp = [...userInputArray];
      temp.push({
        timeStamp: ` ${monthName} ${day}, ${year} ${formattedTime}`,
        msg: userInput
      });
      // temp.push({ msg: userInput });
      setUserInputArray(temp);
      // return ` ${monthName} ${day}, ${year} ${formattedTime}`;
    };
  })();
  const handleDateTimeShow = (timeStamp, type) => {
    const dateTimeString = timeStamp + 'Z';
    const dateTime = new Date(dateTimeString);
    const timeOptions = {
      hour: 'numeric',
      minute: '2-digit',
      // second: '2-digit',
      hour12: true,
      // weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    };

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const localTime = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZone: userTimeZone
    }).format(dateTime);
    if (type === 'time') {
      const formattedTime = dateTime.toLocaleTimeString(undefined, localTime);

      return localTime;
    }
  };

  const handleAddMoreRates = () => {
    AddMoreRateRequest(sessionId)
      .then(res => {
        res.status === 200 &&
          window.open(`/carrier-manager?sessionId=${res.data.data}`, '_blank');
      })
      .catch(err => ErrorToast(err?.message));
  };
  const handleCloseAddMoreDialog = () => {
    setAddMoreRateDialog(false);
  };
  const handleDateChange = (userDate, type) => {
    // alert(type);
    const dateString = userDate;

    if (type === 'deliveryDate') {
      setDeliveryDate(userDate);
      setValidationErrors({
        ...validationErrors,
        deliveryDate: null
      });
    }
    if (type === 'pickupDate') {
      setPickupDate(userDate);
      setValidationErrors({
        ...validationErrors,
        pickupDate: null
      });
    }
  };
  const isMobile = useIsMobile();

  return (
    <div className='Carrier_Manager_Parent'>
      <Dialog
        sx={isMobile ? {} : { minWidth: 400 }}
        open={addMoreRateDialog}
        onClose={handleCloseAddMoreDialog}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Do you want to add more rates ?
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleCloseAddMoreDialog}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              handleAddMoreRates();
              handleCloseAddMoreDialog();
            }}
            // autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <MetaTags>
        <title>ShipTech Pro | Request for TL Quote</title>
        {/* <title>
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'ShipTech Pro - Request for TL Quote'
            : 'TMS - Request for TL Quote'}
        </title> */}
      </MetaTags>

      {timeExpired && (
        <Box
          sx={{
            textAlign: 'center',
            height: '90vh',
            width: '100%',
            overflow: 'hidden'
          }}
          className='Chat_Child_View_Shipment'
        >
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* <br />
                    <br /> */}
          <WarningAmberIcon
            style={
              isMobile
                ? { fontSize: 80, color: '#D32F2F' }
                : { fontSize: 150, color: '#D32F2F' }
            }
          />{' '}
          {/* <br /> */}
          <br />
          <Typography style={isMobile ? { fontSize: 16 } : {}} variant='h5'>
            Quote time expired!
          </Typography>
          {/* <br />
          <br />
          <br /> */}
        </Box>
      )}
      {/* {inputsDisabled && (
        <Alert sx={{ m: 1 }} severity='info'>
          Shipment time is expired!
        </Alert>
      )} */}
      {showInputValue ? (
        <div
          className='Chat_Parent_Success'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <CheckCircleOutlineIcon
              sx={{ color: 'green', width: 130, height: 130 }}
            />
            <Typography color={'green'} variant='h4'>
              Quote sent to shipping Manager!
            </Typography>
          </div>
        </div>
      ) : mainLoader ? (
        <Box>
          <Skeleton sx={{ height: 80 }} />
          <Skeleton sx={{ height: 880, mt: -25 }} />
          <Box
            sx={{ mt: -20, display: 'flex', justifyContent: 'space-between' }}
          >
            <Skeleton sx={{ height: 40, width: 450 }} />
            <Skeleton sx={{ height: 40, width: 450 }} />
            <Skeleton sx={{ height: 40, width: 450 }} />
            <Skeleton sx={{ height: 40, width: 450 }} />
          </Box>
          <Skeleton sx={{ height: 70, mt: -2 }} />
        </Box>
      ) : (
        !timeExpired && (
          <div className={'Chat_Parent'}>
            <div
              style={
                isMobile
                  ? {
                      display: 'flex',
                      flexDirection: 'column',
                      margin: 4
                    }
                  : {
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: 10
                    }
              }
            >
              <Typography sx={isMobile ? { fontSize: 14 } : {}} variant='h6'>
                ShipTech Pro - {quoteTitle}
              </Typography>

              <Button
                sx={isMobile ? { mt: 1 } : {}}
                fullWidth={isMobile ? true : false}
                onClick={() => CarrierQuoteReqHandler(sessionId)}
                variant='outlined'
                size='small'
              >
                Refresh
                <SyncOutlinedIcon
                  sx={{ cursor: 'pointer', color: '#4e79d2' }}
                />
              </Button>
            </div>
            <hr />

            <div className='Chat_Child'>
              {shipperApiMsgs &&
                shipperApiMsgs.map(carrierRes => (
                  <div
                    style={
                      carrierRes.actionByFlag.toLowerCase() === 'shipper'
                        ? {
                            display: 'flex',
                            justifyContent: 'start',
                            marginLeft: 10,
                            marginRight: 10
                          }
                        : {
                            display: 'flex',
                            // flexDirection: 'column',
                            justifyContent: 'flex-end',
                            // width: 400,
                            marginLeft: 10,
                            marginRight: 10
                          }
                    }
                  >
                    <div>
                      {carrierRes.actionByFlag.toLowerCase() === 'shipper' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'start'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 0,
                              marginRight: 1,
                              textAlign: 'left',
                              marginBottom: 10
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: '#eceff1',
                                borderRadius: '50%',
                                width: '45',
                                height: '45',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                marginRight: 10
                              }}
                            >
                              <img
                                style={{
                                  borderRadius: '50%',
                                  width: '45',
                                  height: '45',
                                  textAlign: 'center',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  objectFit: 'cover'
                                }}
                                alt=''
                                src={
                                  base_logo_url +
                                  shipperApiMsgs[0]?.actionByImage
                                }
                              />
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column'
                              }}
                            >
                              <div>
                                <b
                                  style={{
                                    fontSize: 18
                                  }}
                                >
                                  {shipperApiMsgs &&
                                    shipperApiMsgs[0]?.actionByName}
                                </b>
                              </div>
                              <div
                                style={{
                                  fontWeight: 600,
                                  textTransform: 'uppercase'
                                }}
                              >
                                {shipperApiMsgs[0]?.actionByDesignation}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {carrierRes.actionByFlag.toLowerCase() === 'carrier' && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'end',
                            marginBottom: 10,
                            textAlign: 'right'
                          }}
                        >
                          <div>
                            <b style={{ fontSize: 18 }}>
                              {carrierApiMsgs &&
                                carrierApiMsgs[0]?.actionByName}
                            </b>
                            <br />
                            <span
                              style={{
                                fontWeight: 600,
                                textTransform: 'capitalize'
                              }}
                            >
                              {carrierApiMsgs[0]?.actionByDesignation}
                            </span>
                          </div>
                          <div
                            style={{
                              backgroundColor: '#e0f2f1',
                              borderRadius: '50%',
                              width: '45',
                              height: '45',
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              marginLeft: 10
                            }}
                          >
                            <img
                              style={{
                                borderRadius: '50%',
                                width: '45',
                                height: '45',
                                textAlign: 'center',
                                display: 'flex',
                                justifyContent: 'center',
                                objectFit: 'cover'
                              }}
                              alt=''
                              src={
                                base_logo_url + carrierApiMsgs[0]?.actionByImage
                              }
                            />
                          </div>
                        </div>
                      )}
                      <div
                        style={
                          carrierRes.actionByFlag.toLowerCase() === 'carrier'
                            ? {
                                marginBottom: '10vh',
                                backgroundColor: '#e0f2f1',
                                borderRadius: '10px ',
                                padding: '10px',
                                position: 'relative'
                              }
                            : {
                                marginBottom: '10vh',
                                backgroundColor: '#eceff1',
                                padding: '10px',
                                borderRadius: '10px ',
                                position: 'relative'
                              }
                        }
                      >
                        <div
                          className={
                            carrierRes.actionByFlag.toLowerCase() === 'carrier'
                              ? 'carrier_triangle'
                              : 'manager_triangle'
                          }
                        ></div>
                        {/* <div
                        style={{
                          marginBottom: '10vh',
                          backgroundColor: '#eceff1',
                          margin: '-16 0 0 5',
                          position: 'absolute',
                          width: '16',
                          height: '16',
                          padding: '1',
                          rotate: '45deg'
                        }}
                      ></div> */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: carrierRes.actionData
                          }}
                        ></div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            fontSize: 11
                          }}
                        >
                          {handleDateTimeShow(carrierRes.date, 'time')}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              <div ref={bottomRef} />
            </div>

            {!isBooked && (
              <div>
                <Grid container>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <label
                      style={validationErrors.charges ? { color: 'red' } : {}}
                      htmlFor='Charges $'
                    >
                      {validationErrors.charges
                        ? validationErrors.charges
                        : 'All in Freight Cost *'}{' '}
                    </label>
                    <br />
                    <input
                      className='Rates_Input'
                      style={
                        validationErrors.charges
                          ? {
                              width: '100%',
                              borderColor: 'red'
                              // border: '2px solid red'
                            }
                          : { width: '100%' }
                      }
                      placeholder='$'
                      type='number'
                      id='Charges'
                      name='Charges'
                      onChange={e => {
                        setValidationErrors({
                          ...validationErrors,
                          charges: null
                        });
                        setCharges(e.target.value);
                      }}
                      value={charges === 0 ? '' : charges}
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <label
                      style={
                        validationErrors.serviceType ? { color: 'red' } : {}
                      }
                      htmlFor='Pickup Date'
                    >
                      {validationErrors.serviceType
                        ? validationErrors.serviceType
                        : 'Service type *'}{' '}
                    </label>
                    <br />
                    <select
                      className='Rates_Input'
                      style={{ width: '100%' }}
                      id='Ref/Pickup # (if any)'
                      name='Ref/Pickup # (if any)'
                      onChange={e => {
                        setValidationErrors({
                          ...validationErrors,
                          serviceType: null
                        });
                        setRefPickNmb(e.target.value);
                      }}
                      value={refPickNmb}
                    >
                      <option value='--select--'>--select--</option>
                      <option value='Solo'>Solo</option>
                      <option value='Team'>Team</option>
                    </select>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <label
                      style={
                        validationErrors.pickupDate ? { color: 'red' } : {}
                      }
                      htmlFor='Pickup Date'
                    >
                      {validationErrors.pickupDate
                        ? validationErrors.pickupDate
                        : 'Pickup Date *'}{' '}
                    </label>
                    <br />

                    <DatePicker
                      // clearButtonTitle='Clear pickup date'
                      // title='Pickup Date'
                      // isClearable
                      wrapperClassName='datePicker'
                      todayButton={<TodayIcon />}
                      placeholderText='mm/dd/year'
                      className='Rates_Input_delivery'
                      selected={pickupDate}
                      onChange={e => handleDateChange(e, 'pickupDate')}
                      minDate={new Date()}
                    />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
                    <label
                      style={
                        validationErrors.deliveryDate ? { color: 'red' } : {}
                      }
                      htmlFor='Delivery Date'
                    >
                      {validationErrors.deliveryDate
                        ? validationErrors.deliveryDate
                        : 'Estimated Delivery Date *'}{' '}
                    </label>
                    <br />

                    <DatePicker
                      wrapperClassName='datePicker'
                      todayButton={<TodayIcon />}
                      placeholderText='mm/dd/year'
                      style={
                        validationErrors.deliveryDate
                          ? {
                              width: '100%',
                              borderColor: 'red'
                              // border: '2px solid red'
                            }
                          : { width: '100%', backgroundColor: 'yellow' }
                      }
                      className='Rates_Input_delivery'
                      selected={pickupDate > deliveryDate ? '' : deliveryDate}
                      onChange={e => handleDateChange(e, 'deliveryDate')}
                      minDate={new Date(pickupDate)}
                    />
                  </Grid>
                </Grid>
                <div style={{ position: 'relative' }}>
                  <input
                    style={{ width: '100%', height: 60 }}
                    placeholder='comments ...'
                    type='text'
                    id='userInput'
                    name='userInput'
                    value={userInput}
                    onChange={e => handleUserInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  {chatLoader ? (
                    <CircularProgress
                      // color='success'
                      sx={{
                        fontSize: 30,
                        marginBottom: 1,
                        position: 'absolute',
                        top: '30%',
                        right: 10,
                        transform: 'translateY(-50%)',
                        cursor: 'pointer'
                      }}
                      size={25}
                    />
                  ) : (
                    <Button
                      sx={{
                        textTransform: 'capitalize',
                        // fontSize: 15,
                        marginBottom: 1,
                        position: 'absolute',
                        top: '50%',
                        right: 10,
                        transform: 'translateY(-50%)',
                        cursor: 'pointer'
                      }}
                      color='success'
                      size='small'
                      variant='contained'
                      id='submitButton'
                      onClick={handleShowUserInputMsgs}
                    >
                      {carrierApiMsgs.length > 0 ? 'Update Rate' : 'Send Rate'}
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
        )
      )}
      {!isBooked && !timeExpired && carrierApiMsgs.length > 0 && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => setAddMoreRateDialog(true)}
            sx={{ mt: 1 }}
            variant='contained'
            size='small'
          >
            Add more rates
          </Button>
        </div>
      )}
      {console.log(isBooked, timeExpired, 'isBooked')}

      {isBooked && !timeExpired && (
        <Alert color='error' sx={{ m: 1 }} severity='info'>
          This Shipment has been booked!
        </Alert>
      )}
    </div>
  );
}
