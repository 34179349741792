import { useState, React, useRef, useEffect } from 'react';
import {
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  StepButton,
  Switch
} from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
  CreateShipment,
  DispatchShipment,
  PickupShipment
} from '../../apis/Agency';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import './PickupShipment.css';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Paper from '@mui/material/Paper';
import {
  Autocomplete,
  Button,
  createFilterOptions,
  TextField
} from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { useStyles } from './styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import GetShipmentRates from '../GetRate/GetRates';
import { useHistory } from 'react-router-dom';
import { CardTitle } from 'reactstrap';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import ReactQuill from 'react-quill';
import { ErrorToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

export default function BookShipmentReview(props) {
  let UpdatedStoredServices = useRef(null);
  const detectBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = '';

    if (userAgent.indexOf('Firefox') > -1) {
      browser = 'Firefox';
    } else if (userAgent.indexOf('Chrome') > -1) {
      browser = 'Chrome';
    } else {
      browser = 'Unknown';
    }

    return browser;
  };

  const browser = detectBrowser();
  const history = useHistory();
  const PreviousProps =
    props?.location?.state?.pickupRowData !== undefined &&
    JSON.parse(props?.location?.state?.pickupRowData);

  const selectedLocDetails = props?.location?.state?.userLocationsArr?.filter(
    items => items.id === props?.location?.state?.locationId
  );
  const dateNow = new Date();
  const year = dateNow.getFullYear();
  const monthWithOffset = dateNow.getUTCMonth() + 1;
  const month =
    monthWithOffset.toString().length < 2
      ? `0${monthWithOffset}`
      : monthWithOffset;
  const date =
    dateNow.getUTCDate().toString().length < 2
      ? `0${dateNow.getUTCDate()}`
      : dateNow.getUTCDate();

  const materialDateInput = `${year}-${month}-${date}`;

  const classes = useStyles();
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const CarrierRateData = useSelector(state => state.getCarrierRates);

  const [pickupNumber, setPickupNumber] = useState(
    PreviousProps?.pickupNumber ? PreviousProps?.pickupNumber : ''
  );
  const assignedProNmb = JSON.parse(
    props?.location?.state?.pickupRowData
  ).proNumber;
  const assignedBolNmb = JSON.parse(
    props?.location?.state?.pickupRowData
  ).bolNumber;
  const [proNumber, setProNumber] = useState(assignedProNmb);
  const [refOrQuoteNmb, setRefOrQuoteNmb] = useState(
    PreviousProps?.quoteNumber ? PreviousProps?.quoteNumber : ''
  );
  const [open, setOpen] = useState(false);
  const [openOtherCarrier, setOpenOtherCarrier] = useState(false);
  const [otherCarrierName, setOtherCarrierName] = useState('');
  const [truckNumber, setTruckNumber] = useState('');
  const [driverName, setDriverName] = useState('');
  const [driverNumber, setDriverNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(
    PreviousProps?.paymentType == 0 ? selectedLocDetails[0]?.contactPhone : ''
  );
  const [destinationPhoneNumber, setDestinationPhoneNumber] = useState(
    PreviousProps?.paymentType == 1 ? selectedLocDetails[0]?.contactPhone : ''
  );
  const [carrierName, setCarrierName] = useState(null);
  const [originEmail, setOriginEmail] = useState(
    PreviousProps?.paymentType == 0
      ? selectedLocDetails[0]?.contactEmail.split(',')[0]
      : ''
  );
  const [destinationEmail, setDestinationEmail] = useState(
    PreviousProps?.paymentType == 1
      ? selectedLocDetails[0]?.contactEmail.split(',')[0]
      : ''
  );
  const [selectedCarrierID, setSelectedCarrierID] = useState(null);
  const [carrierIdForApi, setCarrierIdForApi] = useState(0);

  const [originDate, setOriginDate] = useState(materialDateInput);
  const [destinationDate, setDestinationDate] = useState(new Date());
  const [originOpenTimeValue, setOriginOpenTimeValue] = useState('08:00');
  const [originCloseTimeValue, setOriginCloseTimeValue] = useState('16:00');
  const [destinationOpenTimeValue, setDestinationOpenTimeValue] =
    useState('09:00');
  const [destinationCloseTimeValue, setDestinationCloseTimeValue] =
    useState('17:00');
  const [shipmentBooked, setShipmentBooked] = useState(false);
  const [pickUpLoader, setPickUpLoader] = useState(false);
  const [shipmentDispatchMsg, setShipmentDispatchMsg] = useState('');
  const [shipmentDispatchFailed, setShipmentDispatchFailed] = useState(false);
  const [pickupReferenceNumber, setPickupReferenceNumber] = useState('');
  const [pickupSuccessRemarks, setPickupSuccessRemarks] = useState('');
  const [shipmentFailed, setShipmentFailed] = useState(false);
  const [shipmentFailedMsg, setShipmentFailedMsg] = useState('');
  const ClassArray = [
    '50',
    '55',
    '60',
    '65',
    '70',
    '77.5',
    '85',
    '92.5',
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
    '250',
    '300',
    '400',
    '500'
  ];
  const [value, setValue] = useState(0);
  const [finalCommodityInfoApi, setFinalCommodityInfoApi] = useState([]);
  const [totalWeighSum, setTotalWeightSum] = useState('');
  const [newAddedClassRow, setNewAddedClassRow] = useState([
    PreviousProps?.carrierApiNames === 'carrierApiNames'
      ? {
        WeightLBS: CarrierRateData?.commodityInfo?.[0]?.weight?.value,
        Class: CarrierRateData?.commodityInfo?.[0]?.freightClass,
        NMFCnumber: CarrierRateData?.commodityInfo?.[0]?.nmfc
      }
      : {
        WeightLBS: PreviousProps?.pickupRowData?.details?.[0]?.grossWeight,
        Class: CarrierRateData?.commodityInfo?.[0]?.freightClass,
        NMFCnumber: CarrierRateData?.commodityInfo?.[0]?.nmfc
      }
  ]);
  function formatDate(date) {
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ).toLocaleDateString('en-CA');
  }
  const [totalLBSWeightDimensions, setTotalLBSWeightDimensions] = useState('');
  const [totalLBSWeightClass, setTotalLBSWeightClass] = useState('');
  const handlePushAddedCommodityInfo = TotalWeightSum => {
    let CommodityInfoApi = [
      {
        freightClass: newAddedClassRow[0].Class,
        packaging: 'Pallet',
        pieces: 1,
        weight: {
          units: 'lbs',
          value: TotalWeightSum ? TotalWeightSum : 0
        },
        nmfc: newAddedClassRow[0].NMFCnumber,
        dimensions: {
          length: 0,
          width: 0,
          height: 0,
          dimensionsUom: 'cm',
          isStackable: false
        }
      }
    ];

    setFinalCommodityInfoApi(CommodityInfoApi);
  };
  const [BOLNumber, setBOLNumber] = useState(assignedBolNmb);
  const [PoRefNumber, setPoRefNumber] = useState('');
  const [deliveryRemarks, setDeliveryRemarks] = useState('');
  const [pickupRemarks, setPickupRemarks] = useState('');
  const [overLengthValue, setOverLengthValue] = useState('');
  const [isOverLength, setIsOverLength] = useState(false);
  const [stackableSwitch, setStackableSwitch] = useState(false);
  const [CODAmount, setCODAmount] = useState('');
  const [DeclaredValue, setDeclaredValue] = useState('');
  const [pickUpChecked, setPickUpChecked] = useState(true);
  const [deliveryChecked, setDeliveryChecked] = useState(true);
  const [hazmatSwitch, setHazmatSwitch] = useState(false);
  const [insidePickupSwitch, setInsidePickupSwitch] = useState(false);
  const [liftgatePickupSwitch, setLiftgatePickupSwitch] = useState(false);
  const [residentialPickupSwitch, setResidentialPickupSwitch] = useState(false);
  const [secureAccesPickupSwitch, setSecureAccesPickupSwitch] = useState(false);
  const [limitedPickupAccessSwitch, setLimitedPickupAccessSwitch] =
    useState(false);
  const [freezeProtectionPickupSwitch, setFreezeProtectionPickupSwitch] =
    useState(false);
  const [overlengthPickupSwitch, setOverlengthPickupSwitch] = useState(false);
  //delivery switch states
  const [insideDeliverySwitch, setInsideDeliverySwitch] = useState(false);
  const [liftgateDeliverySwitch, setLiftgateDeliverySwitch] = useState(false);
  const [residentialDeliverySwitch, setResidentialDeliverySwitch] =
    useState(false);
  const [limitedDeliverySwitch, setLimitedDeliverySwitch] = useState(false);
  const [secureAccesDeliverySwitch, setSecureAccesDeliverySwitch] =
    useState(false);
  const [
    callBeforeDeliveryServicesSwitch,
    setCallBeforeDeliveryServicesSwitch
  ] = useState(false);

  const [reloadQuote, setReloadQuote] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCarrierIDQuoteRates, setSelectedCarrierIDQuoteRates] =
    useState('');
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState('');
  const [selectedCarrierQuotaionID, setSelectedCarrierQuotaionID] =
    useState('');
  const [totalNetChargeState, setTotalNetChargeState] = useState('');
  const [serviceTypeApi, setServiceTypeApi] = useState('');
  const [transitDays, setTransitDays] = useState('');
  const [selectedCarrierNameQuoteRates, setSelectedCarrierNameQuoteRates] =
    useState('');
  const [originName, setOriginName] = useState(
    PreviousProps?.paymentType == 0 ? selectedLocDetails[0]?.streetLines : ''
  );
  const [originCompanyName, setOriginCompanyName] = useState(
    PreviousProps?.paymentType == 0 ? selectedLocDetails[0]?.companyName : ''
  );
  const [destinationName, setDestinationName] = useState(
    PreviousProps?.paymentType == 1 ? selectedLocDetails[0]?.streetLines : ''
  );
  const [destinationCompanyName, setDestinationCompanyName] = useState(
    PreviousProps?.paymentType == 1 ? selectedLocDetails[0]?.companyName : ''
  );
  const [saleOrderNumber, setSaleOrderNumber] = useState('');
  const [customerPoNmb, setCustomerPoNmb] = useState('');
  const [shipmentId, setShipmentId] = useState('');
  const [apiCalDate, setApiCalDate] = useState('');
  const [apiDisplayDate, setApiDisplayDate] = useState('');
  const [originTerminalInfo, setOriginTerminalInfo] = useState({
    contact: {
      personName: '',
      phoneNumber: '',
      faxNumber: '',
      companyName: '',
      phoneExtension: '',
      email: ''
    },
    address: {
      city: '',
      stateOrProvinceCode: '',
      postalCode: '',
      countryCode: '',
      residential: true,
      streetLines: ''
    }
  });

  const [destinationTerminalInfo, setDestinationTerminalInfo] = useState({
    contact: {
      personName: '',
      phoneNumber: '',
      faxNumber: '',
      companyName: '',
      phoneExtension: '',
      email: ''
    },
    address: {
      city: '',
      stateOrProvinceCode: '',
      postalCode: '',
      countryCode: '',
      residential: true,
      streetLines: ''
    }
  });
  const [carrierShipType, setCarrierShipType] = useState('');
  const [surchargesArr, setSurchargesArr] = useState({
    surchageType: '',
    level: '',
    description: '',
    amount: {
      currency: '',
      amount: 0
    },
    percentage: 0,
    order: 0
  });

  const handleListItemClick = (items, index) => {
    // event.preventDefault();
    const numericValue = parseInt(items.transitTime, 10); // Convert '05' to 5
    console.log(items, 'rate items');
    setSelectedCarrierNameQuoteRates(items.carrierName);
    setSelectedCarrierIDQuoteRates(items.carrierID);
    setSelectedDeliveryDate(items.deliveryTime);
    setSelectedIndex(index);
    setSelectedCarrierQuotaionID(items.quotationNumber);
    setTotalNetChargeState(items.totalNetCharge);
    setServiceTypeApi(items.serviceType);
    setTransitDays(isNaN(numericValue) ? '' : numericValue);
    setOriginTerminalInfo(items.originTerminal);
    setDestinationTerminalInfo(items.destinationTerminal);
    setCarrierShipType(items.carrierType);
    // setSurchargesArr(items.surcharges);

    // Recursive function to replace null values with empty strings in any nested structure
    const replaceNulls = obj => {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
          if (value === null) {
            return [key, ''];
          } else if (typeof value === 'object' && value !== null) {
            // If the value is an object, recursively apply replaceNulls
            return [key, replaceNulls(value)];
          }
          return [key, value];
        })
      );
    };

    // Apply the function to each item in the surcharges array
    const cleanSurcharges = items.surcharges.map(surcharge =>
      replaceNulls(surcharge)
    );

    setSurchargesArr(cleanSurcharges);

    return false;
  };

  useEffect(() => {
    if (transitDays) {
      calculateFutureDate(transitDays);
    }
  }, [originDate, transitDays]);

  const calculateFutureDate = days => {
    // const today = new Date(originDate); // Get the current date
    // const futureDate = new Date(today); // Create a copy of the current date
    // const apiDate = futureDate.toLocaleDateString('en-US', {
    //   // weekday: 'long', // Day of the week
    //   year: 'numeric', // Full year
    //   month: 'numeric', // Full month name
    //   day: 'numeric' // Day of the month
    // });
    axios
      .get(
        `https://a.maxease.net/business-days/?pickup_date=${originDate}&transit_days=${days}`
      )
      .then(res => {
        setApiCalDate(res.data.final_date);

        setApiDisplayDate(res.data.display_date);
      })
      .catch(err => console.log(err, 'days api err_________>>>.'));
    // futureDate.setDate(today.getDate() + days); // Add the number of days

    // Format the date (optional, for readability)
  };

  const handlePaymentType = () => {
    console.log(
      PreviousProps?.locationName,
      PreviousProps?.paymentTerms,
      'PreviousProps'
    );
    if (
      PreviousProps?.locationName === '' ||
      PreviousProps?.locationName.toLowerCase() === 'dir' ||
      PreviousProps?.paymentTerms === 'Third Party'
    ) {
      return '2';
    }
    if (PreviousProps?.paymentTerms === 'Outbound Prepaid') {
      return '0';
    }
    if (PreviousProps?.paymentTerms === 'Inbound Prepaid') {
      return '1';
    } else return '0';
  };

  const [onclickSelectedRow, setOnclickSelectedRow] = useState();

  !onclickSelectedRow === null &&
    setTimeout(() => {
      setOnclickSelectedRow(null);
    }, 5000);

  const handlePickupServices = v => {
    setPickUpChecked(c => !c);
    // checkPickupStatus();
    // setDeliveryChecked(false);
  };

  const handleDeliveryServices = () => {
    setDeliveryChecked(c => !c);
    // checkDeliveryStatus();
    // setPickUpChecked(false);
  };

  const handleDeliveryServicesChange = switchName => {
    if (switchName === 'Inside Delivery') {
      setInsideDeliverySwitch(c => !c);
    } else if (switchName === 'Liftgate Delivery') {
      setLiftgateDeliverySwitch(c => !c);
    } else if (switchName === 'Residential Delivery') {
      setResidentialDeliverySwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesDeliverySwitch(c => !c);
    } else if (switchName === 'Call Before Delivery') {
      setCallBeforeDeliveryServicesSwitch(c => !c);
    } else setLimitedDeliverySwitch(c => !c);
    DisableReloadButton();
  };

  const handlePickUpServicesChange = switchName => {
    if (switchName === 'hazmat') {
      setHazmatSwitch(c => !c);
    } else if (switchName === 'Stackable') {
      setStackableSwitch(c => !c);
    } else if (switchName === 'Inside Pickup') {
      setInsidePickupSwitch(c => !c);
    } else if (switchName === 'Inside Lift Gate Services') {
      setLiftgatePickupSwitch(c => !c);
    } else if (switchName === 'Residential Pickup') {
      setResidentialPickupSwitch(c => !c);
    } else if (switchName === 'Secure Access') {
      setSecureAccesPickupSwitch(c => !c);
    } else if (switchName === 'Freeze Protection') {
      setFreezeProtectionPickupSwitch(c => !c);
    } else if (switchName === 'Overlength') {
      setOverlengthPickupSwitch(c => !c);
      setIsOverLength(!isOverLength);
    } else setLimitedPickupAccessSwitch(c => !c);
  };
  const rando = max => Math.floor(Math.random() * max);
  const words = ['Paper', 'Rock', 'Scissors'];
  const rawData = [];

  for (let i = 0; i < 100; i++)
    rawData.push({ id: rando(300), name: words[i % words.length] });

  const handleResetAllData = () => {
    handleReset();
    setCarrierName(null);
    setPickupNumber('');
    setProNumber('');
    setOriginOpenTimeValue('');
    setOriginCloseTimeValue('');
    setDestinationDate(new Date());
    setDestinationOpenTimeValue('');
    setDestinationCloseTimeValue('');
    setPaymentType('');
    setBOLNumber('');
    setHazmatSwitch(!hazmatSwitch);
    setDeliveryRemarks('');
    setPickupRemarks('');
    setInsidePickupSwitch(false);
    setLiftgatePickupSwitch(false);
    setResidentialPickupSwitch(false);
    setSecureAccesPickupSwitch(false);
    setLimitedPickupAccessSwitch(false);
    setFreezeProtectionPickupSwitch(false);
    setOverlengthPickupSwitch(false);
    setOverLengthValue('');
    setInsideDeliverySwitch(false);
    setLiftgateDeliverySwitch(false);
    setResidentialDeliverySwitch(false);
    setSecureAccesDeliverySwitch(false);
    setLimitedDeliverySwitch(false);
    setCallBeforeDeliveryServicesSwitch(false);
    setCODAmount('');
    setDeclaredValue('');
  };

  const handleOtherCarrierClose = () => {
    setOpenOtherCarrier(false);
    setOpen(true);
    setCarrierName(null);
    // setOtherCarrierName('');
  };
  const handleSaveOtherCarrier = () => {
    const errors = {};
    if (!otherCarrierName) {
      errors['otherCarrierName'] = 'Enter carrier name';
    } else if (!truckNumber) {
      errors['truckNumber'] = 'Enter truck number';
    } else if (!driverName) {
      errors['driverName'] = 'Enter driver name';
    } else if (!driverNumber) {
      errors['driverNumber'] = 'Enter driver number';
    } else if (phoneNumberValidError) {
      errors['driverNumber'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
    }
    setValidationErrors(errors);
    if (otherCarrierName && truckNumber && driverName && driverNumber) {
      handleOtherCarrierClose();
    }
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // Update the runtime state directly
  const updateRuntimeState = () => {
    UpdatedStoredServices.current = {
      insideDeliverySwitch: insideDeliverySwitch,
      liftgateDeliverySwitch: liftgateDeliverySwitch,
      residentialDeliverySwitch: residentialDeliverySwitch,
      secureAccesDeliverySwitch: secureAccesDeliverySwitch,
      limitedDeliverySwitch: limitedDeliverySwitch,
      callBeforeDeliveryServicesSwitch: callBeforeDeliveryServicesSwitch,
      insidePickupSwitch: insidePickupSwitch,
      liftgatePickupSwitch: liftgatePickupSwitch,
      residentialPickupSwitch: residentialPickupSwitch,
      secureAccesPickupSwitch: secureAccesPickupSwitch,
      limitedPickupAccessSwitch: limitedPickupAccessSwitch,
      freezeProtectionPickupSwitch: freezeProtectionPickupSwitch,
      overlengthPickupSwitch: overlengthPickupSwitch,
      hazmatSwitch: hazmatSwitch,
      CODAmount: CODAmount,
      DeclaredValue: DeclaredValue,
      OverLengthValue: overLengthValue
    };
    // No re-render will be triggered
  };

  const DisableReloadButton = () => {
    if (UpdatedStoredServices?.current === null) {
      return true;
    } else if (
      insideDeliverySwitch ===
      UpdatedStoredServices?.current?.insideDeliverySwitch &&
      liftgateDeliverySwitch ===
      UpdatedStoredServices?.current?.liftgateDeliverySwitch &&
      residentialDeliverySwitch ===
      UpdatedStoredServices?.current?.residentialDeliverySwitch &&
      secureAccesDeliverySwitch ===
      UpdatedStoredServices?.current?.secureAccesDeliverySwitch &&
      limitedDeliverySwitch ===
      UpdatedStoredServices?.current?.limitedDeliverySwitch &&
      callBeforeDeliveryServicesSwitch ===
      UpdatedStoredServices?.current?.callBeforeDeliveryServicesSwitch &&
      insidePickupSwitch ===
      UpdatedStoredServices?.current?.insidePickupSwitch &&
      liftgatePickupSwitch ===
      UpdatedStoredServices?.current?.liftgatePickupSwitch &&
      residentialPickupSwitch ===
      UpdatedStoredServices?.current?.residentialPickupSwitch &&
      secureAccesPickupSwitch ===
      UpdatedStoredServices?.current?.secureAccesPickupSwitch &&
      limitedPickupAccessSwitch ===
      UpdatedStoredServices?.current?.limitedPickupAccessSwitch &&
      freezeProtectionPickupSwitch ===
      UpdatedStoredServices?.current?.freezeProtectionPickupSwitch &&
      hazmatSwitch === UpdatedStoredServices?.current?.hazmatSwitch &&
      CODAmount === UpdatedStoredServices?.current?.CODAmount &&
      DeclaredValue === UpdatedStoredServices?.current?.DeclaredValue &&
      overLengthValue === UpdatedStoredServices?.current?.OverLengthValue
    ) {
      return true;
    } else return false;
  };
  const CallCarrierRatesApi = () => {
    setReloadQuote(!reloadQuote);
    updateRuntimeState();
    setSelectedIndex(null);
    // handleDisableReQuoteBtn();
  };

  useEffect(() => {
    updateRuntimeState();
  }, []);

  const filter = createFilterOptions();
  const [validationErrors, setValidationErrors] = useState({});
  const [phoneNumberValidError, setPhoneNumberValidError] = useState(false);
  const [
    destinationphoneNumberValidError,
    setDestinationphoneNumberValidError
  ] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [activeTabLabel, setActiveTabLabel] = useState('origin');

  const handlePickupResponse = ApiRes => {
    setPickUpLoader(false);
    if (ApiRes.status === 200) {
      setShipmentBooked(true);
      setPickupReferenceNumber(ApiRes?.data?.data?.referenceNumber);
      setPickupSuccessRemarks('Your shipment has been booked');
    } else {
      setShipmentFailed(true);
      setShipmentFailedMsg(JSON.stringify(ApiRes?.response?.data));
    }
  };
  const DispatchApiResHandler = ApiRes => {
    setPickUpLoader(false);
    if (ApiRes?.status === 200) {
      setShipmentDispatchMsg(ApiRes?.data?.data?.status);
      setShipmentDispatchFailed(false);
    } else {
      setShipmentDispatchMsg('');
      setShipmentDispatchFailed(true);
    }
  };
  const CallDispatchShipmentApi = () => {
    setPickUpLoader(true);

    const DispatchObj = {
      shipmentId: JSON.parse(props?.location?.state?.pickupRowData).shipmentId
        ? JSON.parse(props?.location?.state?.pickupRowData).shipmentId
        : 0,
      agencyId: AgencyID ? AgencyID : 0,
      type: otherCarrierName ? 1 : 0,
      proNumber: proNumber ? proNumber : '',
      bolNumber: BOLNumber ? BOLNumber : '',
      refNumber: refOrQuoteNmb ? refOrQuoteNmb : '',
      pickupNumber: pickupNumber ? pickupNumber : '',
      carrierId: JSON.parse(props?.location?.state?.pickupRowData).carrierId
        ? JSON.parse(props?.location?.state?.pickupRowData).carrierId
        : 0,
      carrierName:
        carrierName !== null
          ? carrierName.title
          : otherCarrierName
            ? otherCarrierName
            : '',
      driverName: driverName ? driverName : '',
      vehicleNumber: truckNumber ? truckNumber : '',
      phoneNumber: driverNumber ? driverNumber : ''
    };
    DispatchShipment(DispatchObj)
      .then(res => DispatchApiResHandler(res))
      .catch(err => DispatchApiResHandler(err));
  };
  const handleNext = index => {
    let errors = {};

    if (index === 0 && props?.location?.state?.disptachOpen !== 'Get Quote') {
      if (
        carrierName == null &&
        !otherCarrierName &&
        PreviousProps?.shipmentStatus !== 'Booked'
      ) {
        errors['carrierName'] = 'Select carrier Name';
      } else if (!proNumber && PreviousProps?.shipmentStatus === 'Booked') {
        errors['proNumber'] = 'Enter Pro Number';
      } else if (!refOrQuoteNmb) {
        errors['refOrQuoteNmbErr'] = 'Enter Quote Number';
      } else setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else if (index === 1) {
      if (props?.location?.state?.disptachOpen === 'Get Quote') {
        if (!originOpenTimeValue) {
          errors['originOpenTime'] = 'Select pickup open time';
        } else if (!originCloseTimeValue) {
          errors['originCloseTime'] = 'Select pickup close time';
        }
        // else if (!pickupRemarks) {
        //   errors['pickupRemarks'] = 'Enter pickup remarks';
        // }
        else if (!destinationOpenTimeValue) {
          errors['destinationOpenTime'] = 'Select delivery open time';
        } else if (!destinationCloseTimeValue) {
          errors['destinationCloseTime'] = 'Select delivery close time';
        }
        // else if (!deliveryRemarks) {
        //   errors['deliveryRemarks'] = 'Enter delivery remarks';
        // }
        else setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else if (!destinationOpenTimeValue) {
        errors['destinationOpenTime'] = 'Select delivery open time';
      } else if (!destinationCloseTimeValue) {
        errors['destinationCloseTime'] = 'Select delivery close time';
      }
      // else if (!deliveryRemarks) {
      //   errors['deliveryRemarks'] = 'Enter delivery remarks';
      // }
      else setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else if (
      index === 2 &&
      props?.location?.state?.rateType === 'Quick Quote'
    ) {
      if (!originCompanyName) {
        errors['originCompanyNameError'] = 'Enter origin company name';
        setValidationErrors(errors);
      } else if (!phoneNumber) {
        setPhoneNumberValidError(true);
        errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
        setValidationErrors(errors);
      } else if (!originEmail) {
        errors['originEmailError'] = 'Enter origin email';
        setValidationErrors(errors);
      } else if (!destinationCompanyName) {
        errors['destinationCompanyNameError'] =
          'Enter destination company name';
        setValidationErrors(errors);
      } else if (!destinationPhoneNumber) {
        setDestinationphoneNumberValidError(true);
        errors['destinationPhoneNumberError'] =
          'Enter Valid Phone Number (XXX) XXX-XXXX';
        setValidationErrors(errors);
      } else if (!destinationEmail) {
        errors['destinationEmailError'] = 'Enter destination email';
        setValidationErrors(errors);
      } else if (!saleOrderNumber) {
        errors['saleOrderNumberError'] = 'Enter sale order number';
        setValidationErrors(errors);
      }
    } else setActiveStep(prevActiveStep => prevActiveStep + 1);
    // setSelectedIndex(null);
    // sessionStorage.setItem('updatedStoredServices', null);

    setValidationErrors(errors);
    if (
      props?.location?.state?.rateType !== 'Quick Quote' &&
      props?.location?.state?.disptachOpen === 'Get Quote' &&
      activeStep === 2
    ) {
      CallPickupApi();
    }
    if (
      props?.location?.state?.rateType === 'Quick Quote' &&
      props?.location?.state?.disptachOpen === 'Get Quote' &&
      activeStep === 2 &&
      originCompanyName &&
      phoneNumber &&
      originEmail &&
      destinationCompanyName &&
      destinationPhoneNumber &&
      destinationEmail &&
      saleOrderNumber
    ) {
      CallPickupApi();
    }
    if (
      props?.location?.state?.disptachOpen !== 'Get Quote' &&
      stepsArray().length === 2 &&
      index === 1
    ) {
      CallDispatchShipmentApi();
    }
  };
  const handlePickupShipmentReq = newShipId => {
    // pickup through quick quote

    const ManageShipPickupObj = {
      carrier: {
        id: selectedCarrierIDQuoteRates ? selectedCarrierIDQuoteRates : 0,
        name: selectedCarrierNameQuoteRates
          ? selectedCarrierNameQuoteRates
          : '',
        logo: ''
      },
      quotationId: selectedCarrierQuotaionID ? selectedCarrierQuotaionID : '',
      shipmentType: carrierShipType,
      paymentType: props?.location?.state?.paymentType.toString(),
      isHazmat: hazmatSwitch,
      isStablale: stackableSwitch,
      lineItems: PreviousProps?.commodityInfo,
      shipper: {
        contact: {
          personName: PreviousProps?.destinationContactPerson
            ? PreviousProps?.destinationContactPerson
            : originCompanyName,
          phoneNumber: PreviousProps?.destinationContactPhone
            ? PreviousProps?.destinationContactPhone
            : phoneNumber.replace(/\D/g, ''),
          faxNumber: '',
          companyName: PreviousProps?.originCompany
            ? PreviousProps?.originCompany
            : originCompanyName,
          phoneExtension: '',
          email: PreviousProps?.destinationContactEmail
            ? PreviousProps?.destinationContactEmail
            : originEmail
        },
        address: {
          city: PreviousProps?.originCity
            ? PreviousProps?.originCity.trim()
            : PreviousProps?.shipFrom?.city,
          stateOrProvinceCode: PreviousProps?.originState
            ? PreviousProps?.originState
            : PreviousProps?.shipFrom?.stateOrProvinceCode,
          postalCode: PreviousProps?.originZipCode
            ? PreviousProps?.originZipCode
            : PreviousProps?.shipFrom?.postalCode,
          countryCode: 'USA',
          residential: true,
          streetLines: PreviousProps?.originAddress
            ? PreviousProps?.originAddress.trim()
            : PreviousProps?.shipFrom?.streetLines
        }
      },
      recipient: {
        contact: {
          personName: PreviousProps?.originContactPerson
            ? PreviousProps?.originContactPerson
            : destinationCompanyName,
          phoneNumber: PreviousProps?.originContactPhone
            ? PreviousProps?.originContactPhone
            : destinationPhoneNumber.replace(/\D/g, ''),
          faxNumber: '',
          companyName: PreviousProps?.destinationCompany
            ? PreviousProps?.destinationCompany
            : destinationCompanyName,
          phoneExtension: '',
          email: PreviousProps?.originContactEmail
            ? PreviousProps?.originContactEmail
            : destinationEmail
        },
        address: {
          city: PreviousProps?.destinationCity
            ? PreviousProps?.destinationCity.trim()
            : PreviousProps?.shipTo?.city,
          stateOrProvinceCode: PreviousProps?.destinationState
            ? PreviousProps?.destinationState
            : PreviousProps?.shipTo?.stateOrProvinceCode,
          postalCode: PreviousProps?.destinationZipCode
            ? PreviousProps?.destinationZipCode
            : PreviousProps?.shipTo?.postalCode,
          countryCode: 'USA',
          residential: true,
          streetLines: PreviousProps?.destinationAddress
            ? PreviousProps?.destinationAddress.trim()
            : PreviousProps?.shipTo?.streetLines
        }
      },
      billTo: {
        contact: {
          personName: '',
          phoneNumber: '',
          faxNumber: '',
          companyName: '',
          phoneExtension: '',
          email: ''
        },
        address: {
          city: '',
          stateOrProvinceCode: '',
          postalCode: '',
          countryCode: '',
          residential: true,
          streetLines: ''
        }
      },
      originTerminal: {
        contact: {
          personName: originTerminalInfo?.contact?.personName
            ? originTerminalInfo?.contact?.personName
            : '',
          phoneNumber: originTerminalInfo?.contact?.phoneNumber
            ? originTerminalInfo?.contact?.phoneNumber
            : '',
          faxNumber: originTerminalInfo?.contact?.faxNumber
            ? originTerminalInfo?.contact?.faxNumber
            : '',
          companyName: originTerminalInfo?.contact?.companyName
            ? originTerminalInfo?.contact?.companyName
            : '',
          phoneExtension: originTerminalInfo?.contact?.phoneExtension
            ? originTerminalInfo?.contact?.phoneExtension
            : '',
          email: originTerminalInfo?.contact?.email
            ? originTerminalInfo?.contact?.email
            : ''
        },
        address: {
          city: originTerminalInfo?.address?.city
            ? originTerminalInfo?.address?.city
            : '',
          stateOrProvinceCode: originTerminalInfo?.address?.stateOrProvinceCode
            ? originTerminalInfo?.address?.stateOrProvinceCode
            : '',
          postalCode: originTerminalInfo?.address?.postalCode
            ? originTerminalInfo?.address?.postalCode
            : '',
          countryCode: originTerminalInfo?.address?.countryCode
            ? originTerminalInfo?.address?.countryCode
            : '',
          residential: true,
          streetLines: originTerminalInfo?.address?.streetLines
            ? originTerminalInfo?.address?.streetLines
            : ''
        }
      },
      destinationTerminal: {
        contact: {
          personName: destinationTerminalInfo?.contact?.personName
            ? destinationTerminalInfo?.contact?.personName
            : '',
          phoneNumber: destinationTerminalInfo?.contact?.phoneNumber
            ? destinationTerminalInfo?.contact?.phoneNumber
            : '',
          faxNumber: destinationTerminalInfo?.contact?.faxNumber
            ? destinationTerminalInfo?.contact?.faxNumber
            : '',
          companyName: destinationTerminalInfo?.contact?.companyName
            ? destinationTerminalInfo?.contact?.companyName
            : '',
          phoneExtension: destinationTerminalInfo?.contact?.phoneExtension
            ? destinationTerminalInfo?.contact?.phoneExtension
            : '',
          email: destinationTerminalInfo?.contact?.email
            ? destinationTerminalInfo?.contact?.email
            : ''
        },
        address: {
          city: destinationTerminalInfo?.address?.city
            ? destinationTerminalInfo?.address?.city
            : '',
          stateOrProvinceCode: destinationTerminalInfo?.address
            ?.stateOrProvinceCode
            ? destinationTerminalInfo?.address?.stateOrProvinceCode
            : '',
          postalCode: destinationTerminalInfo?.address?.postalCode
            ? destinationTerminalInfo?.address?.postalCode
            : '',
          countryCode: destinationTerminalInfo?.address?.countryCode
            ? destinationTerminalInfo?.address?.countryCode
            : '',
          residential: true,
          streetLines: destinationTerminalInfo?.address?.streetLines
            ? destinationTerminalInfo?.address?.streetLines
            : ''
        }
      },
      instructionForShipper: deliveryRemarks ? deliveryRemarks : '',
      instructionForConsignee: pickupRemarks ? pickupRemarks : '',
      pickupDate: originDate,
      PickupTime: originOpenTimeValue ? originOpenTimeValue : '',
      requestedDeliveryDate: apiCalDate
        ? apiCalDate
        : new Date().toISOString().split('T')[0],
      // ? formatDate(new Date(selectedDeliveryDate))
      // : formatDate(new Date(calculateFutureDate(transitDays))),
      requestedDeliveryTime: destinationOpenTimeValue
        ? destinationOpenTimeValue
        : '',
      pickupClosingTime: originCloseTimeValue ? originCloseTimeValue : '',
      deliveryStartTime: destinationOpenTimeValue
        ? destinationOpenTimeValue
        : '',
      deliveryEndTime: destinationCloseTimeValue
        ? destinationCloseTimeValue
        : '',
      pickupOptions: {
        inside: insidePickupSwitch,
        liftGateServices: liftgatePickupSwitch,
        residential: residentialPickupSwitch,
        secureAccess: secureAccesPickupSwitch,
        limitedAccess: limitedPickupAccessSwitch,
        freezeProtection: freezeProtectionPickupSwitch,
        overLength: overlengthPickupSwitch,
        overLengthValue: overLengthValue ? parseFloat(overLengthValue) : 0,
        callBeforeDelivery: false
      },
      estimatedTransitDays: transitDays ? transitDays : 0,

      deliveryOptions: {
        inside: insideDeliverySwitch,
        liftGateServices: liftgateDeliverySwitch,
        residential: residentialDeliverySwitch,
        secureAccess: secureAccesDeliverySwitch,
        limitedAccess: limitedDeliverySwitch,
        freezeProtection: false,
        overLength: false,
        overLengthValue: 0,
        callBeforeDelivery: callBeforeDeliveryServicesSwitch
      },
      importIndicator: {
        indicator: '',
        lastFreeday: '',
        paperwork: '',
        airway: {
          bill: '',
          reference: '',
          containerReference: ''
        },
        ocean: {
          bill: '',
          reference: '',
          containerReference: ''
        }
      },
      agencyId: AgencyID ? AgencyID : 0,
      pickupNumber: pickupNumber ? pickupNumber : '',
      proNumber: proNumber ? proNumber : '',
      customerBOLNumber: BOLNumber ? BOLNumber : '',
      referenceNumber: selectedCarrierQuotaionID
        ? selectedCarrierQuotaionID
        : '',
      shipmentId: newShipId,
      locationId: props?.location?.state?.locationId,
      quotationCharges: totalNetChargeState,
      serviceType: serviceTypeApi ? serviceTypeApi : '',
      surcharges: surchargesArr
    };
    PickupShipment(ManageShipPickupObj)
      .then(res => handlePickupResponse(res))
      .catch(err => handlePickupResponse(err));
  };
  const CallPickupApi = () => {
    const PickUpDate = new Date(PreviousProps?.pickupDate).toLocaleDateString(
      'zh-Hans-CN'
    );

    const DekiveryDate = new Date(
      PreviousProps?.deliveryDate
    ).toLocaleDateString('zh-Hans-CN');

    const ModifiedPickUpDate = PickUpDate.replace(/\//g, '-');
    const ModifiedDeliveryDate = DekiveryDate.replace(/\//g, '-');
    // direct pickup through shipments
    let ManageShipPickupObj = {
      carrier: {
        id: selectedCarrierIDQuoteRates ? selectedCarrierIDQuoteRates : 0,
        name: selectedCarrierNameQuoteRates
          ? selectedCarrierNameQuoteRates
          : '',
        logo: ''
      },
      quotationId: selectedCarrierQuotaionID ? selectedCarrierQuotaionID : '',
      shipmentType: carrierShipType,
      paymentType: props?.location?.state?.paymentType.toString(),
      isHazmat: hazmatSwitch,
      isStablale: stackableSwitch,
      lineItems: [
        {
          freightClass: PreviousProps?.details?.[0]?.freightClass
            ? PreviousProps?.details?.[0]?.freightClass
            : '',
          packaging: PreviousProps?.details?.[0]?.packagingType
            ? PreviousProps?.details?.[0]?.packagingType
            : '',

          units: PreviousProps?.details?.[0]?.units
            ? PreviousProps?.details?.[0]?.units
            : 1,
          pieces: PreviousProps?.details?.[0]?.pieces
            ? PreviousProps?.details?.[0]?.pieces
            : 1,
          // pallets: PreviousProps?.details?.[0]?.pallets
          //   ? PreviousProps?.details?.[0]?.pallets
          //   : 1,

          weight: {
            units: 'lbs',
            value: PreviousProps?.details?.[0]?.grossWeight
              ? PreviousProps?.details?.[0]?.grossWeight
              : 1
          },
          nmfc: PreviousProps?.details?.[0]?.nmfc
            ? PreviousProps?.details?.[0]?.nmfc
            : '',
          dimensions: {
            length: 0,
            width: 0,
            height: 0,
            dimensionsUom: 'cm',
            isStackable: stackableSwitch
          }
        }
      ],
      shipper: {
        contact: {
          personName: PreviousProps?.destinationContactPerson
            ? PreviousProps?.destinationContactPerson
            : '',
          phoneNumber: PreviousProps?.destinationContactPhone
            ? PreviousProps?.destinationContactPhone
            : '',
          faxNumber: '',
          companyName: PreviousProps?.originCompany
            ? PreviousProps?.originCompany
            : originCompanyName,
          phoneExtension: '',
          email: PreviousProps?.destinationContactEmail
            ? PreviousProps?.destinationContactEmail
            : ''
        },
        address: {
          city: PreviousProps?.originCity
            ? PreviousProps?.originCity.trim()
            : PreviousProps?.shipFrom?.city,
          stateOrProvinceCode: PreviousProps?.originState
            ? PreviousProps?.originState
            : PreviousProps?.shipFrom?.stateOrProvinceCode,
          postalCode: PreviousProps?.originZipCode
            ? PreviousProps?.originZipCode
            : PreviousProps?.shipFrom?.postalCode,
          countryCode: 'USA',
          residential: true,
          streetLines: PreviousProps?.originAddress
            ? PreviousProps?.originAddress.trim()
            : PreviousProps?.shipFrom?.streetLines
        }
      },
      recipient: {
        contact: {
          personName: PreviousProps?.originContactPerson
            ? PreviousProps?.originContactPerson
            : '',
          phoneNumber: PreviousProps?.originContactPhone
            ? PreviousProps?.originContactPhone
            : '',
          faxNumber: '',
          companyName: PreviousProps?.destinationCompany
            ? PreviousProps?.destinationCompany
            : destinationCompanyName,
          phoneExtension: '',
          email: PreviousProps?.originContactEmail
            ? PreviousProps?.originContactEmail
            : ''
        },
        address: {
          city: PreviousProps?.destinationCity
            ? PreviousProps?.destinationCity.trim()
            : PreviousProps?.shipTo?.city,
          stateOrProvinceCode: PreviousProps?.destinationState
            ? PreviousProps?.destinationState
            : PreviousProps?.shipTo?.stateOrProvinceCode,
          postalCode: PreviousProps?.destinationZipCode
            ? PreviousProps?.destinationZipCode
            : PreviousProps?.shipTo?.postalCode,
          countryCode: 'USA',
          residential: true,
          streetLines: PreviousProps?.destinationAddress
            ? PreviousProps?.destinationAddress.trim()
            : PreviousProps?.shipTo?.streetLines
        }
      },
      billTo: {
        contact: {
          personName: PreviousProps?.billToContactPerson
            ? PreviousProps?.billToContactPerson
            : '',
          phoneNumber: PreviousProps?.billToContactPhone
            ? PreviousProps?.billToContactPhone
            : '',
          faxNumber: '',
          companyName: PreviousProps?.billToCompany
            ? PreviousProps?.billToCompany
            : '',
          phoneExtension: '',
          email: PreviousProps?.billToContactEmail
            ? PreviousProps?.billToContactEmail
            : ''
        },
        address: {
          city: PreviousProps?.billToCity ? PreviousProps?.billToCity : '',
          stateOrProvinceCode: PreviousProps?.billToState
            ? PreviousProps?.billToState
            : '',
          postalCode: PreviousProps?.billToZipCode
            ? PreviousProps?.billToZipCode
            : '',
          countryCode: PreviousProps?.billToCountry
            ? PreviousProps?.billToCountry
            : '',
          residential: true,
          streetLines: PreviousProps?.billToAddress
            ? PreviousProps?.billToAddress
            : ''
        }
      },
      originTerminal: {
        contact: {
          personName: originTerminalInfo?.contact?.personName
            ? originTerminalInfo?.contact?.personName
            : '',
          phoneNumber: originTerminalInfo?.contact?.phoneNumber
            ? originTerminalInfo?.contact?.phoneNumber
            : '',
          faxNumber: originTerminalInfo?.contact?.faxNumber
            ? originTerminalInfo?.contact?.faxNumber
            : '',
          companyName: originTerminalInfo?.contact?.companyName
            ? originTerminalInfo?.contact?.companyName
            : '',
          phoneExtension: originTerminalInfo?.contact?.phoneExtension
            ? originTerminalInfo?.contact?.phoneExtension
            : '',
          email: originTerminalInfo?.contact?.email
            ? originTerminalInfo?.contact?.email
            : ''
        },
        address: {
          city: originTerminalInfo?.address?.city
            ? originTerminalInfo?.address?.city
            : '',
          stateOrProvinceCode: originTerminalInfo?.address?.stateOrProvinceCode
            ? originTerminalInfo?.address?.stateOrProvinceCode
            : '',
          postalCode: originTerminalInfo?.address?.postalCode
            ? originTerminalInfo?.address?.postalCode
            : '',
          countryCode: originTerminalInfo?.address?.countryCode
            ? originTerminalInfo?.address?.countryCode
            : '',
          residential: true,
          streetLines: originTerminalInfo?.address?.streetLines
            ? originTerminalInfo?.address?.streetLines
            : ''
        }
      },
      destinationTerminal: {
        contact: {
          personName: destinationTerminalInfo?.contact?.personName
            ? destinationTerminalInfo?.contact?.personName
            : '',
          phoneNumber: destinationTerminalInfo?.contact?.phoneNumber
            ? destinationTerminalInfo?.contact?.phoneNumber
            : '',
          faxNumber: destinationTerminalInfo?.contact?.faxNumber
            ? destinationTerminalInfo?.contact?.faxNumber
            : '',
          companyName: destinationTerminalInfo?.contact?.companyName
            ? destinationTerminalInfo?.contact?.companyName
            : '',
          phoneExtension: destinationTerminalInfo?.contact?.phoneExtension
            ? destinationTerminalInfo?.contact?.phoneExtension
            : '',
          email: destinationTerminalInfo?.contact?.email
            ? destinationTerminalInfo?.contact?.email
            : ''
        },
        address: {
          city: destinationTerminalInfo?.address?.city
            ? destinationTerminalInfo?.address?.city
            : '',
          stateOrProvinceCode: destinationTerminalInfo?.address
            ?.stateOrProvinceCode
            ? destinationTerminalInfo?.address?.stateOrProvinceCode
            : '',
          postalCode: destinationTerminalInfo?.address?.postalCode
            ? destinationTerminalInfo?.address?.postalCode
            : '',
          countryCode: destinationTerminalInfo?.address?.countryCode
            ? destinationTerminalInfo?.address?.countryCode
            : '',
          residential: true,
          streetLines: destinationTerminalInfo?.address?.streetLines
            ? destinationTerminalInfo?.address?.streetLines
            : ''
        }
      },
      instructionForShipper: deliveryRemarks ? deliveryRemarks : '',
      instructionForConsignee: pickupRemarks ? pickupRemarks : '',
      pickupDate: PreviousProps?.pickupDate ? ModifiedPickUpDate : '',
      PickupTime: originOpenTimeValue ? originOpenTimeValue : '',
      requestedDeliveryDate: apiCalDate
        ? apiCalDate
        : new Date().toISOString().split('T')[0],
      requestedDeliveryTime: destinationOpenTimeValue
        ? destinationOpenTimeValue
        : '',
      pickupClosingTime: originCloseTimeValue ? originCloseTimeValue : '',
      deliveryStartTime: destinationOpenTimeValue
        ? destinationOpenTimeValue
        : '',
      deliveryEndTime: destinationCloseTimeValue
        ? destinationCloseTimeValue
        : '',
      pickupOptions: {
        inside: insidePickupSwitch,
        liftGateServices: liftgatePickupSwitch,
        residential: residentialPickupSwitch,
        secureAccess: secureAccesPickupSwitch,
        limitedAccess: limitedPickupAccessSwitch,
        freezeProtection: freezeProtectionPickupSwitch,
        overLength: overlengthPickupSwitch,
        overLengthValue: overLengthValue ? parseFloat(overLengthValue) : 0,
        callBeforeDelivery: false
      },
      estimatedTransitDays: transitDays ? transitDays : 0,

      deliveryOptions: {
        inside: insideDeliverySwitch,
        liftGateServices: liftgateDeliverySwitch,
        residential: residentialDeliverySwitch,
        secureAccess: secureAccesDeliverySwitch,
        limitedAccess: limitedDeliverySwitch,
        freezeProtection: false,
        overLength: false,
        overLengthValue: 0,
        callBeforeDelivery: callBeforeDeliveryServicesSwitch
      },
      importIndicator: {
        indicator: '',
        lastFreeday: '',
        paperwork: '',
        airway: {
          bill: '',
          reference: '',
          containerReference: ''
        },
        ocean: {
          bill: '',
          reference: '',
          containerReference: ''
        }
      },
      agencyId: AgencyID ? AgencyID : 0,
      pickupNumber: pickupNumber ? pickupNumber : '',
      proNumber: proNumber ? proNumber : '',
      customerBOLNumber: BOLNumber ? BOLNumber : '',
      referenceNumber: selectedCarrierQuotaionID
        ? selectedCarrierQuotaionID
        : '',
      shipmentId:
        props?.location?.state?.rateType === 'Quick Quote'
          ? shipmentId
          : PreviousProps?.shipmentId
            ? PreviousProps?.shipmentId
            : 0,
      locationId: props?.location?.state?.locationId,
      quotationCharges: totalNetChargeState,
      serviceType: serviceTypeApi ? serviceTypeApi : '',

      surcharges: surchargesArr
    };

    if (props?.location?.state?.rateType === 'Quick Quote') {
      const updatedItems = PreviousProps?.commodityInfo.map(
        ({
          freightClass,
          nmfc,
          packaging,
          pieces,
          units,
          weight,
          dimensions
        }) => ({
          ItemText: '',
          freightClass,
          NMFCClass: '',
          packagingType: packaging ? packaging : '',
          requestedQuantity: pieces,
          pallets: units,
          netWeight: weight.value,
          length: parseInt(dimensions.length),
          width: parseInt(dimensions.width),
          height: parseInt(dimensions.height)
        })
      );

      const saveShipmentObj = {
        orderDate: new Date().toISOString().split('T')[0],
        pickupDate: originDate,
        pickupNumber: '',
        expectedDeliveryDate: apiCalDate
          ? apiCalDate
          : new Date().toISOString().split('T')[0],
        // ? formatDate(new Date(selectedDeliveryDate))
        // : formatDate(new Date(calculateFutureDate(transitDays))),
        originCompany: originCompanyName,
        originAddress: originName,
        originCity: PreviousProps?.shipFrom?.city,
        originState: PreviousProps?.shipFrom?.stateOrProvinceCode,
        originZip: PreviousProps?.shipFrom?.postalCode,
        originCountry: PreviousProps?.shipFrom?.countryCode,
        originContactPerson: '',
        originContactEmail: originEmail,
        originContactPhone: phoneNumber,
        destinationCompany: destinationCompanyName,
        destinationAddress: destinationName,
        destinationCity: PreviousProps?.shipTo?.city,
        destinationState: PreviousProps?.shipTo?.stateOrProvinceCode,
        destinationZip: PreviousProps?.shipTo?.postalCode,
        destinationCountry: PreviousProps?.shipTo?.countryCode,
        destinationContactPerson: '',
        destinationContactEmail: destinationEmail,
        destinationContactPhone: destinationPhoneNumber,
        billToCompany: '',
        billToAddress: '',
        billToCity: '',
        billToState: '',
        billToZip: '',
        billToCountry: '',
        billToContactPerson: '',
        billToContactEmail: '',
        billToContactPhone: '',
        shipmentType: carrierShipType,
        equipmentType: '',
        freightTerms:
          PreviousProps?.paymentType === '0'
            ? 'OutboundPrepaid'
            : PreviousProps?.paymentType === '1'
              ? 'InboundCollect'
              : 'ThirdParty',
        customerId: '',
        salesOrderNumber: saleOrderNumber,
        purchaseOrderNumber: customerPoNmb,
        freightInvoiceNumber: '',
        carrierId: selectedCarrierIDQuoteRates,
        carrierName: selectedCarrierNameQuoteRates,
        paymentTerms: '',
        shipmentStatus: 'Saved',
        bolNumber: '',
        shippingNotes: pickupRemarks,
        proNumber: '',
        locationId: PreviousProps?.locationId,
        pickupStartTime: originOpenTimeValue,
        pickupEndTime: originCloseTimeValue,
        deliverStartTime: destinationOpenTimeValue,
        deliverEndTime: destinationCloseTimeValue,
        shipmentCharges: totalNetChargeState,
        actualPickupDate: originDate,
        actualDeliveryDate: apiCalDate
          ? apiCalDate
          : new Date().toISOString().split('T')[0],
        // ? formatDate(new Date(selectedDeliveryDate))
        // : formatDate(new Date(calculateFutureDate(transitDays))),
        quoteNumber: selectedCarrierQuotaionID ? selectedCarrierQuotaionID : '',
        serviceType: serviceTypeApi ? serviceTypeApi : '',
        location: PreviousProps?.locationFull,
        totalGrossWeight: PreviousProps?.commodityInfo?.reduce(
          (total, item) => {
            return total + parseFloat(item?.weight?.value || 0);
          },
          0
        ),
        totalNetWeight: PreviousProps?.commodityInfo?.reduce((total, item) => {
          return total + parseFloat(item?.weight?.value || 0);
        }, 0),
        originTerminalAddress: originTerminalInfo?.address?.streetLines
          ? originTerminalInfo?.address?.streetLines
          : '',
        originTerminalCity: originTerminalInfo?.address?.city
          ? originTerminalInfo?.address?.city
          : '',
        originTerminalState: originTerminalInfo?.address?.stateOrProvinceCode
          ? originTerminalInfo?.address?.stateOrProvinceCode
          : '',
        originTerminalPostalCode: originTerminalInfo?.address?.postalCode
          ? originTerminalInfo?.address?.postalCode
          : '',
        originTerminalCountry: originTerminalInfo?.address?.countryCode
          ? originTerminalInfo?.address?.countryCode
          : '',
        originTerminalContact: originTerminalInfo?.contact?.personName
          ? originTerminalInfo?.contact?.personName
          : '',
        originTerminalContantEmail: originTerminalInfo?.contact?.email
          ? originTerminalInfo?.contact?.email
          : '',
        originTerminalContactPhone: originTerminalInfo?.contact?.phoneNumber
          ? originTerminalInfo?.contact?.phoneNumber
          : '',
        destinationTerminalAddress: destinationTerminalInfo?.address
          ?.streetLines
          ? destinationTerminalInfo?.address?.streetLines
          : '',
        destinationTerminalCity: destinationTerminalInfo?.address?.city
          ? destinationTerminalInfo?.address?.city
          : '',
        destinationTerminalState: destinationTerminalInfo?.address
          ?.stateOrProvinceCode
          ? destinationTerminalInfo?.address?.stateOrProvinceCode
          : '',
        destinationTerminalPostalCode: destinationTerminalInfo?.address
          ?.postalCode
          ? destinationTerminalInfo?.address?.postalCode
          : '',
        destinationTerminalCountry: destinationTerminalInfo?.address
          ?.countryCode
          ? destinationTerminalInfo?.address?.countryCode
          : '',
        destinationTerminalContact: destinationTerminalInfo?.contact?.personName
          ? destinationTerminalInfo?.contact?.personName
          : '',
        destinationTerminalContantEmail: destinationTerminalInfo?.contact?.email
          ? destinationTerminalInfo?.contact?.email
          : '',
        destinationTerminalContactPhone: destinationTerminalInfo?.contact
          ?.phoneNumber
          ? destinationTerminalInfo?.contact?.phoneNumber
          : '',
        estimatedTransitDays: transitDays ? transitDays : 0,
        pickupOptions: {
          inside: insidePickupSwitch,
          liftGateServices: liftgatePickupSwitch,
          residential: residentialPickupSwitch,
          secureAccess: secureAccesPickupSwitch,
          limitedAccess: limitedPickupAccessSwitch,
          freezeProtection: freezeProtectionPickupSwitch,
          overLength: overlengthPickupSwitch,
          overLengthValue: overLengthValue ? parseFloat(overLengthValue) : 0,
          callBeforeDelivery: false
        },
        deliveryOptions: {
          inside: insideDeliverySwitch,
          liftGateServices: liftgateDeliverySwitch,
          residential: residentialDeliverySwitch,
          secureAccess: secureAccesDeliverySwitch,
          limitedAccess: limitedDeliverySwitch
        },
        details: updatedItems
      };
      setPickUpLoader(true);
      CreateShipment(saveShipmentObj)
        .then(res => {
          res.status === 200 && handlePickupShipmentReq(res.data.data);
        })
        .catch(err => {
          setPickUpLoader(false);
          // handleBack();
          ErrorToast(
            `Create Shipment API failed due to, ${err?.response?.data ? err?.response?.data : err?.message
            }`
          );
          console.log(err, 'Create SHipment Error_____________>>>>');
        });
    } else {
      setPickUpLoader(true);
      PickupShipment(ManageShipPickupObj)
        .then(res => handlePickupResponse(res))
        .catch(err => handlePickupResponse(err));
    }
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const PaymentTypeArr = ['Outbound Prepaid', 'Third Party'];
  const HazmatOptions = ['No', 'Yes'];
  const [hazmatType, setHazmatType] = useState(HazmatOptions[0]);
  const [paymentType, setPaymentType] = useState(PreviousProps?.paymentTerms);

  const handleClassInputChange = (inputName, index, event) => {
    let totalWeight = 0;
    let TotalWeightSum;
    const values = [...newAddedClassRow];
    if (inputName === 'WeightLBS') {
      // const updatedErrors = { ...validationErrors, weightLBS: null };
      // setValidationErrors(updatedErrors);

      values[index].WeightLBS = event.target.value;

      // alert('karam');
      for (let index = 0; index < newAddedClassRow.length; index++) {
        if (newAddedClassRow[index].WeightLBS !== '') {
          totalWeight += parseInt(newAddedClassRow[index].WeightLBS);
        }
      }
      setTotalLBSWeightClass(totalWeight);
      TotalWeightSum = totalLBSWeightDimensions
        ? parseInt(totalLBSWeightDimensions) + totalWeight
        : totalWeight;
      setTotalWeightSum(TotalWeightSum);
      handlePushAddedCommodityInfo(TotalWeightSum);
    } else if (inputName === 'NMFCnumber') {
      values[index].NMFCnumber = event.target.value;
    } else if (inputName === 'Class') {
      values[index].Class = event === null ? ClassArray[0] : event;
    }

    setNewAddedClassRow(values);
  };

  //   if (name === 'OriginOpenTime') {
  //     setOriginOpenTimeValue(event.target.value);
  //     const AMPM = event.target.value.split(':');
  //     setOriginOpenTimeFormate(parseInt(AMPM[0]) < 12 ? 'AM' : 'PM');
  //   } else if (name === 'OriginCloseTime') {
  //     setOriginCloseTimeValue(event.target.value);
  //     const AMPM = event.target.value.split(':');
  //     setOriginCloseTimeFormate(parseInt(AMPM[0]) < 12 ? 'AM' : 'PM');
  //   } else if (name === 'DestiOpenTime') {
  //     setDestinationOpenTimeValue(event.target.value);
  //     const AMPM = event.target.value.split(':');
  //     setDestinationOpenTimeFormate(parseInt(AMPM[0]) < 12 ? 'AM' : 'PM');
  //   } else {
  //     setDestinationCloseTimeValue(event.target.value);
  //     const AMPM = event.target.value.split(':');
  //     setDestinationCloseTimeFormate(parseInt(AMPM[0]) < 12 ? 'AM' : 'PM');
  //   }
  // };
  const handleTimeChange = (newValue, name) => {
    console.log(newValue, 'newValue');
    if (name === 'OriginOpenTime') {
      setOriginOpenTimeValue(newValue.format('HH:mm'));
    } else if (name === 'OriginCloseTime') {
      setOriginCloseTimeValue(newValue.format('HH:mm'));
    } else if (name === 'DestiOpenTime') {
      setDestinationOpenTimeValue(newValue.format('HH:mm'));
    } else {
      setDestinationCloseTimeValue(newValue.format('HH:mm'));
    }
  };
  // const [value, setValue] = useState(null);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const handlePhoneNumberPaste = event => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text from clipboard
    const pastedText = event.clipboardData.getData('text/plain');

    // Remove non-numeric characters from pasted text
    const cleanedText = pastedText.replace(/\D/g, '');

    // Apply formatting logic
    const formattedValue = cleanedText.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      '($1) $2-$3'
    );
    setDriverNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, phoneNumberError: null });
    } else {
      setPhoneNumberValidError(true);
      errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };

  const handleCarrierPhoneNumberPaste = (event, type) => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text from clipboard
    const pastedText = event.clipboardData.getData('text/plain');

    // Remove non-numeric characters from pasted text
    const cleanedText = pastedText.replace(/\D/g, '');

    // Apply formatting logic
    const formattedValue = cleanedText.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      '($1) $2-$3'
    );
    type === 'originPhone'
      ? setPhoneNumber(formattedValue)
      : setDestinationPhoneNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, phoneNumberError: null });
    } else {
      setPhoneNumberValidError(true);
      errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };
  const handleCarrierPhoneNumberChange = (event, type) => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and retain formatting

    if (type === 'origin') {
      const formattedValue = inputValue
        .replace(/\D/g, '')
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
      setPhoneNumber(formattedValue);

      // Perform validation on the formatted value
      const errors = {};
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      const isValidPhoneNumber = phoneRegex.test(formattedValue);
      if (isValidPhoneNumber) {
        setPhoneNumberValidError(false);
        setValidationErrors({ ...validationErrors, phoneNumberError: null });
      } else {
        setPhoneNumberValidError(true);
        errors['phoneNumberError'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
        setValidationErrors(errors);
      }
    } else {
      const formattedValue = inputValue
        .replace(/\D/g, '')
        .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
      setDestinationPhoneNumber(formattedValue);

      // Perform validation on the formatted value
      const errors = {};
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      const isValidPhoneNumber = phoneRegex.test(formattedValue);
      if (isValidPhoneNumber) {
        setDestinationphoneNumberValidError(false);
        setValidationErrors({
          ...validationErrors,
          destinationPhoneNumberError: null
        });
      } else {
        setDestinationphoneNumberValidError(true);
        errors['destinationPhoneNumberError'] =
          'Enter Valid Phone Number (XXX) XXX-XXXX';
        setValidationErrors(errors);
      }
    }
  };

  const handleDateTimeShow = (timeStamp, type) => {
    console.log(timeStamp, type, 'timeStamp, type');
    if (timeStamp !== null) {
      const dateTimeString = timeStamp + 'Z';
      const dateTime = new Date(dateTimeString);
      const date = new Date(dateTimeString);
      // const timeOptions = {
      //   hour: 'numeric',
      //   minute: '2-digit',
      //   // second: '2-digit',
      //   hour12: true,
      //   weekday: 'short',
      //   // year: 'numeric',
      //   month: 'short',
      //   day: 'numeric'
      // };
      const dateOptions = {
        // weekday: 'short',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      };

      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const timeOptions = new Intl.DateTimeFormat('default', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZone: userTimeZone
      }).format(dateTime);

      const formattedTime = dateTime.toLocaleTimeString(undefined, timeOptions);
      const formattedDate = date.toLocaleDateString(undefined, dateOptions);

      if (type === 'time') {
        return timeOptions;
      }
      if (type === 'date') {
        return formattedDate;
      }
    }
  };

  const handleStepperContent = (index, label) => {
    if (index === 0) {
      if (props?.location?.state?.disptachOpen === 'Get Quote') {
        const RatesObj = JSON.parse(props?.location?.state?.pickupRowData);
        console.log(RatesObj, 'RatesObj');
        let obj =
          props?.location?.state?.rateType === 'Quick Quote'
            ? {
              ...RatesObj, // Keep all existing values
              pickupOptions: {
                ...RatesObj.pickupOptions, // Keep existing pickupOptions values
                inside: insidePickupSwitch,
                liftGateServices: liftgatePickupSwitch,
                residential: residentialPickupSwitch,
                secureAccess: secureAccesPickupSwitch,
                limitedAccess: limitedPickupAccessSwitch,
                freezeProtection: freezeProtectionPickupSwitch,
                overLength: overlengthPickupSwitch,
                overLengthValue: overLengthValue
                  ? parseFloat(overLengthValue)
                  : 0,
                callBeforeDelivery: false
              },
              deliveryOptions: {
                ...RatesObj.deliveryOptions, // Keep existing deliveryOptions values
                inside: insideDeliverySwitch,
                liftGateServices: liftgateDeliverySwitch,
                residential: residentialDeliverySwitch,
                secureAccess: secureAccesDeliverySwitch,
                limitedAccess: limitedDeliverySwitch,
                freezeProtection: false,
                overLength: false,
                overLengthValue: 0,
                callBeforeDelivery: callBeforeDeliveryServicesSwitch
              },
              codAmount: CODAmount ? parseInt(CODAmount) : RatesObj.codAmount, // Keep original if not provided
              declaredValue: DeclaredValue
                ? parseInt(DeclaredValue)
                : RatesObj.declaredValue, // Keep original if not provided
              isHazmat:
                hazmatSwitch !== undefined ? hazmatSwitch : RatesObj.isHazmat // Keep original if not provided
            }
            : {
              shipmentType: carrierShipType,
              location: RatesObj?.locationName ? RatesObj?.locationName : '',
              paymentType: props?.location?.state.paymentType.toString(),
              shipFrom: {
                city: RatesObj?.originCity?.trim(),
                stateOrProvinceCode: RatesObj?.originState?.trim(),
                postalCode: RatesObj?.originZipCode,
                countryCode: 'USA',
                residential: true,
                streetLines: RatesObj?.originAddress?.trim()
              },
              shipTo: {
                city: RatesObj?.destinationCity?.trim(),
                stateOrProvinceCode: RatesObj?.destinationState?.trim(),
                postalCode: RatesObj?.destinationZipCode,
                countryCode: 'USA',
                residential: true,
                streetLines: RatesObj?.destinationAddress?.trim()
              },
              shipmentDate: new Date().toISOString().split('T')[0],
              isHazmat: hazmatSwitch,
              commodityInfo: [
                {
                  freightClass: '50',
                  packaging: RatesObj?.details[0]?.packagingType,
                  pieces: RatesObj?.details[0]?.pallets,
                  weight: {
                    units: 'lbs',
                    value: RatesObj?.details[0]?.grossWeight
                  },
                  nmfc: RatesObj?.details[0]?.itemNumber,
                  dimensions: {
                    length: 0,
                    width: 0,
                    height: 0,
                    dimensionsUom: 'inches',
                    isStackable: false
                  }
                }
              ],
              estimatedTransitDays: transitDays ? transitDays : 0,

              pickupOptions: {
                inside: insidePickupSwitch,
                liftGateServices: liftgatePickupSwitch,
                residential: residentialPickupSwitch,
                secureAccess: secureAccesPickupSwitch,
                limitedAccess: limitedPickupAccessSwitch,
                freezeProtection: freezeProtectionPickupSwitch,
                overLength: overlengthPickupSwitch,
                overLengthValue: overLengthValue
                  ? parseFloat(overLengthValue)
                  : 0,
                callBeforeDelivery: false
              },
              deliveryOptions: {
                inside: insideDeliverySwitch,
                liftGateServices: liftgateDeliverySwitch,
                residential: residentialDeliverySwitch,
                secureAccess: secureAccesDeliverySwitch,
                limitedAccess: limitedDeliverySwitch,
                freezeProtection: false,
                overLength: false,
                overLengthValue: 0,
                callBeforeDelivery: callBeforeDeliveryServicesSwitch
              },
              codAmount: CODAmount ? parseInt(CODAmount) : 0,
              declaredValue: DeclaredValue ? parseInt(DeclaredValue) : 0,
              agencyId: AgencyID ? AgencyID : 0,
              locationId: props?.location?.state?.locationId,
              isGuaranteed: false
            };
        let deepClone = JSON.parse(JSON.stringify(obj));

        if (
          RatesObj?.packagingType === 'Pallet' ||
          RatesObj?.packagingType === 'Drum' ||
          RatesObj?.packagingType === 'Box'
        ) {
          deepClone.commodityInfo[0].dimensions.length = 48;
          deepClone.commodityInfo[0].dimensions.width = 40;
          deepClone.commodityInfo[0].dimensions.height = 72;
        }
        if (RatesObj?.packagingType === 'Tote') {
          deepClone.commodityInfo[0].dimensions.length = 48;
          deepClone.commodityInfo[0].dimensions.width = 48;
          deepClone.commodityInfo[0].dimensions.height = 52;
        }

        const handleChangeServicesTab = (event, newValue) => {
          console.log('TabChange');
          setValue(newValue);
        };
        function a11yProps(index) {
          return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
          };
        }
        function TabPanel(props) {
          const { children, value, index, ...other } = props;

          return (
            <div
              role='tabpanel'
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box sx={{ p: 1 }}>
                  <Typography>{children}</Typography>
                </Box>
              )}
            </div>
          );
        }

        TabPanel.propTypes = {
          children: PropTypes.node,
          index: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired
        };

        return (
          <div className='Rates_Tab '>
            <Card className='' style={{ width: '13vw', height: '69vh' }}>
              <CardTitle>
                <Typography
                  sx={{ padding: 1, m: 0, paddingBottom: 0 }}
                  variant='h5'
                >
                  Services
                </Typography>
                {/* <hr /> */}
                {/* <AppBar sx={{ backgroundColor: 'white' }} position='static'> */}
                <Tabs
                  variant='fullWidth'
                  aria-label='full width tabs example'
                  value={value}
                  onChange={handleChangeServicesTab}
                >
                  <Tab
                    style={{
                      textTransform: 'capitalize',
                      alignItems: 'self-start'
                    }}
                    label='Pickup'
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{
                      textTransform: 'capitalize',
                      alignItems: 'self-start'
                    }}
                    label='Delivery'
                    {...a11yProps(1)}
                  />
                </Tabs>
                {/* </AppBar> */}
              </CardTitle>

              <CardContent
                className='p-0'
                sx={{
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  height: '50vh'
                }}
              >
                <TabPanel value={value} index={0}>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <Typography
                        className='ServicesTitle m-0'
                        onClick={e => handlePickupServices(e)}
                        variant='subtitle2'
                      >
                        <div>
                          <FormControl sx={{ m: 0 }} size='large'>
                            <div
                              // style={{ margin: '10 90 0 0' }}
                              className={classes.switchRoot}
                            >
                              <Typography
                                className='ServicesSwitcheLabels my-1'
                                onClick={() =>
                                  handlePickUpServicesChange('Inside Pickup')
                                }
                              >
                                Inside Pickup
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Switch
                                    onChange={() =>
                                      handlePickUpServicesChange(
                                        'Inside Pickup'
                                      )
                                    }
                                    size='small'
                                    checked={insidePickupSwitch}
                                    color='primary'
                                    name='Pickup Services'
                                  />
                                }
                              />
                            </div>
                            <div className={classes.switchRoot}>
                              <Typography
                                className='ServicesSwitcheLabels my-1'
                                onClick={() =>
                                  handlePickUpServicesChange(
                                    'Inside Lift Gate Services'
                                  )
                                }
                              >
                                Lift Gate Services
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Switch
                                    onChange={() =>
                                      handlePickUpServicesChange(
                                        'Inside Lift Gate Services'
                                      )
                                    }
                                    size='small'
                                    checked={liftgatePickupSwitch}
                                    color='primary'
                                    name='Inside Lift Gate Services'
                                  />
                                }
                              />
                            </div>
                            <div className={classes.switchRoot}>
                              <Typography
                                className='ServicesSwitcheLabels my-1'
                                onClick={() =>
                                  handlePickUpServicesChange(
                                    'Residential Pickup'
                                  )
                                }
                              >
                                Residential Pickup
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Switch
                                    onChange={() =>
                                      handlePickUpServicesChange(
                                        'Residential Pickup'
                                      )
                                    }
                                    size='small'
                                    checked={residentialPickupSwitch}
                                    color='primary'
                                    name='Residential Pickup'
                                  />
                                }
                              />
                            </div>
                            <div
                              // style={{ margin: '1 90 0 0' }}
                              className={classes.switchRoot}
                            >
                              <Typography
                                className='ServicesSwitcheLabels my-1'
                                onClick={() =>
                                  handlePickUpServicesChange('Secure Access')
                                }
                              >
                                Secure Access
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Switch
                                    onChange={() =>
                                      handlePickUpServicesChange(
                                        'Secure Access'
                                      )
                                    }
                                    size='small'
                                    checked={secureAccesPickupSwitch}
                                    color='primary'
                                    name='Secure Access'
                                  />
                                }
                              />
                            </div>
                            <div className={classes.switchRoot}>
                              <Typography
                                className='ServicesSwitcheLabels my-1'
                                onClick={() =>
                                  handlePickUpServicesChange('Limited Access')
                                }
                              >
                                Limited Access
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Switch
                                    onChange={() =>
                                      handlePickUpServicesChange(
                                        'Limited Access'
                                      )
                                    }
                                    size='small'
                                    checked={limitedPickupAccessSwitch}
                                    color='primary'
                                    name='Limited Access'
                                  />
                                }
                              />
                            </div>
                            <div
                              // style={{ margin: '1 90 0 0' }}
                              className={classes.switchRoot}
                            >
                              <Typography
                                className='ServicesSwitcheLabels my-1'
                                onClick={() =>
                                  handlePickUpServicesChange(
                                    'Freeze Protection'
                                  )
                                }
                              >
                                Freeze Protection
                              </Typography>
                              <FormControlLabel
                                control={
                                  <Switch
                                    onChange={() =>
                                      handlePickUpServicesChange(
                                        'Freeze Protection'
                                      )
                                    }
                                    size='small'
                                    checked={freezeProtectionPickupSwitch}
                                    color='primary'
                                    name='Freeze Protection'
                                  />
                                }
                              />
                            </div>
                            <div className={classes.switchRoot}>
                              <div>
                                <Typography
                                  className='ServicesSwitcheLabels my-1'
                                  onClick={() =>
                                    handlePickUpServicesChange('Overlength')
                                  }
                                >
                                  Overlength
                                </Typography>
                              </div>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      onChange={() =>
                                        handlePickUpServicesChange('Overlength')
                                      }
                                      size='small'
                                      checked={overlengthPickupSwitch}
                                      color='primary'
                                      name='Overlength'
                                    />
                                  }
                                />
                              </div>
                            </div>
                          </FormControl>
                        </div>
                      </Typography>
                    </div>
                  </div>
                  {/* {isOverLength && (
                    <TextField
                      variant='standard'
                      inputProps={{
                        disableUnderline: true
                      }}
                      value={overLengthValue}
                      onChange={e => setOverLengthValue(e.target.value)}
                      size='small'
                      label='Overlength Inches'
                      type='number'
                      fullWidth
                    />
                  )} */}
                </TabPanel>
                {isOverLength && value === 0 && (
                  <TextField
                    variant='standard'
                    // inputProps={{
                    //   disableUnderline: true
                    // }}
                    fullWidth
                    value={overLengthValue}
                    onChange={e => setOverLengthValue(e.target.value)}
                    size='small'
                    label='Overlength Inches'
                    type='number'
                    sx={{ width: '90%', margin: '-5 0 0 5' }}
                  />
                )}
                <TabPanel value={value} index={1}>
                  <div>
                    <Typography
                      className='ServicesTitleDelivery'
                      variant='subtitle2'
                      onClick={() => handleDeliveryServices()}
                    >
                      <div>
                        <FormControl size='large'>
                          <div className={classes.switchRoot}>
                            <Typography
                              className='ServicesSwitcheLabels my-1'
                              onClick={() =>
                                handleDeliveryServicesChange('Inside Delivery')
                              }
                            >
                              Inside Delivery
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    handleDeliveryServicesChange(
                                      'Inside Delivery'
                                    )
                                  }
                                  size='small'
                                  checked={insideDeliverySwitch}
                                  color='primary'
                                  name='Delivery Services'
                                />
                              }
                            />
                          </div>
                          <div className={classes.switchRoot}>
                            <Typography
                              className='ServicesSwitcheLabels my-1'
                              onClick={() =>
                                handleDeliveryServicesChange(
                                  'Liftgate Delivery'
                                )
                              }
                            >
                              Lift Gate Services
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    handleDeliveryServicesChange(
                                      'Liftgate Delivery'
                                    )
                                  }
                                  size='small'
                                  checked={liftgateDeliverySwitch}
                                  color='primary'
                                  name='Liftgate Delivery'
                                />
                              }
                            />
                          </div>
                          <div className={classes.switchRoot}>
                            <Typography
                              className='ServicesSwitcheLabels my-1'
                              onClick={() =>
                                handleDeliveryServicesChange(
                                  'Residential Delivery'
                                )
                              }
                            >
                              Residential Delivery
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    handleDeliveryServicesChange(
                                      'Residential Delivery'
                                    )
                                  }
                                  size='small'
                                  checked={residentialDeliverySwitch}
                                  color='primary'
                                  name='Residential Delivery'
                                />
                              }
                            />
                          </div>

                          <div className={classes.switchRoot}>
                            <Typography
                              className='ServicesSwitcheLabels my-1'
                              onClick={() =>
                                handleDeliveryServicesChange('Secure Access')
                              }
                            >
                              Secure Access
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    handleDeliveryServicesChange(
                                      'Secure Access'
                                    )
                                  }
                                  size='small'
                                  checked={secureAccesDeliverySwitch}
                                  color='primary'
                                  name='Secure Access'
                                />
                              }
                            />
                          </div>
                          <div className={classes.switchRoot}>
                            <Typography
                              className='ServicesSwitcheLabels my-1'
                              onClick={() =>
                                handleDeliveryServicesChange('Limited Access')
                              }
                            >
                              Limited Access
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    handleDeliveryServicesChange(
                                      'Limited Access'
                                    )
                                  }
                                  size='small'
                                  checked={limitedDeliverySwitch}
                                  color='primary'
                                  name='Limited Access'
                                />
                              }
                            />
                          </div>

                          <div className={classes.switchRoot}>
                            <Typography
                              className='ServicesSwitcheLabels my-1'
                              onClick={() =>
                                handleDeliveryServicesChange(
                                  'Call Before Delivery'
                                )
                              }
                            >
                              Call Before Delivery
                            </Typography>
                            <FormControlLabel
                              control={
                                <Switch
                                  onChange={() =>
                                    handleDeliveryServicesChange(
                                      'Call Before Delivery'
                                    )
                                  }
                                  size='small'
                                  checked={callBeforeDeliveryServicesSwitch}
                                  color='primary'
                                  name='Call Before Delivery'
                                />
                              }
                            />
                          </div>
                        </FormControl>
                      </div>
                    </Typography>
                  </div>
                </TabPanel>
                <Divider />
                <>
                  <div
                    style={{ marginLeft: 10 }}
                    className={classes.switchRoot}
                  >
                    <div>
                      <Typography
                        sx={{ color: 'red' }}
                        className='ServicesSwitcheLabels my-1'
                        onClick={() => handlePickUpServicesChange('Overlength')}
                      >
                        Hazmat
                      </Typography>
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            sx={{ color: 'red' }}
                            onChange={() =>
                              handlePickUpServicesChange('hazmat')
                            }
                            size='small'
                            checked={hazmatSwitch}
                            color='primary'
                            name='hazmat'
                          />
                        }
                      />
                    </div>
                  </div>
                  <div style={{ display: '' }}>
                    <div>
                      <TextField
                        fullWidth
                        variant='standard'
                        inputProps={{ disableUnderline: true }}
                        value={CODAmount === 0 ? '' : CODAmount}
                        onChange={e => setCODAmount(e.target.value)}
                        type='number'
                        size='small'
                        label='C.O.D Amounts($)'
                        sx={{ width: '90%', mx: 1 }}
                      />
                    </div>
                    <div>
                      <TextField
                        fullWidth
                        variant='standard'
                        inputProps={{ disableUnderline: true }}
                        value={DeclaredValue === 0 ? '' : DeclaredValue}
                        onChange={e => setDeclaredValue(e.target.value)}
                        type='number'
                        size='small'
                        label=' Declared Value($)'
                        sx={{ width: '90%', mx: 1 }}
                      />
                    </div>
                  </div>
                  <div className='AdditionalFeeTitle'>
                    {/* <Typography className='ServicesSwitcheLabels'>Fee May Apply</Typography> */}
                    <Button
                      size='small'
                      disabled={DisableReloadButton() ? true : false}
                      onClick={() => CallCarrierRatesApi()}
                      sx={{ mx: 1, fontSize: '13px', alignSelf: 'center' }}
                      variant='contained'
                    >
                      Reload Price Quote
                    </Button>
                  </div>
                </>
              </CardContent>
            </Card>
            <GetShipmentRates
              state={obj}
              deepClone={deepClone}
              totalLBSWeight={
                props?.location?.state?.rateType === 'Quick Quote'
                  ? RatesObj?.commodityInfo?.reduce((total, item) => {
                    return total + parseFloat(item?.weight?.value || 0);
                  }, 0)
                  : RatesObj?.details?.[0]?.grossWeight
              }
              quote={
                props?.location?.state?.rateType === 'Quick Quote'
                  ? 'Quick Quote'
                  : 'quote'
              }
              DataToShow={PreviousProps}
              ReloadQuote={reloadQuote}
              handleListItemClick={handleListItemClick}
              handleNext={handleNext}
              selectedIndex={selectedIndex}
            />
          </div>
        );
      } else
        return (
          <Box className='ParentFlexDiv' sx={{ mt: 5 }}>
            <div
              className='LocationsClass'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Autocomplete
                fullWidth
                forcePopupIcon={
                  PreviousProps?.shipmentStatus === 'Booked' ? false : true
                }
                sx={{ mt: 2 }}
                disabled={
                  PreviousProps?.shipmentStatus === 'Booked' ? true : false
                }
                size='small'
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    <li {...PreviousProps}>
                      {' '}
                      {
                        <div className='carriers_logo_Parent'>
                          {option.title === 'Others' ? (
                            <LocalShippingIcon className='others_carrier_logo' />
                          ) : (
                            <img
                              style={{ objectFit: 'fill' }}
                              className='carriers_logo'
                              alt={option?.carrierLogo}
                              src={option?.carrierLogo}
                            />
                          )}
                        </div>
                      }{' '}
                      {option.title}
                    </li>
                  </Box>
                )}
                value={
                  PreviousProps?.shipmentStatus === 'Booked'
                    ? PreviousProps?.carrierName
                    : otherCarrierName
                      ? otherCarrierName
                      : carrierName
                }
                onChange={(event, newValue) => {
                  newValue?.title === 'Others' && setOpenOtherCarrier(true);
                  setCarrierName({
                    title: newValue?.title,
                    logo: newValue?.carrierLogo?.logo
                  });
                  setCarrierIdForApi(newValue?.carrierID);
                  newValue?.title !== 'Others' && setOtherCarrierName('');
                  setCarrierName(newValue);
                  setValidationErrors({
                    ...validationErrors,
                    carrierName: null
                  });
                }}
                options={props?.location?.state?.carrierApiNames}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title;
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    {...params}
                    // sx={{ width: '38vw' }}
                    variant='standard'
                    error={Boolean(validationErrors['carrierName'])}
                    helperText={validationErrors['carrierName']}
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    label='Carrier Names'
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline:
                        PreviousProps?.shipmentStatus === 'Booked'
                          ? true
                          : false,
                      startAdornment: (carrierName !== null ||
                        otherCarrierName) && (
                          <div
                            style={{ margin: '0 10 0 0' }}
                            className='carriers_logo_Parent'
                          >
                            {otherCarrierName ||
                              carrierName?.title === 'Others' ? (
                              <LocalShippingIcon className='others_carrier_logo' />
                            ) : (
                              <img
                                style={{ objectFit: 'fill' }}
                                className='carriers_logo'
                                alt={carrierName?.carrierLogo}
                                src={carrierName?.carrierLogo}
                              />
                            )}
                          </div>
                        )
                    }}
                  />
                )}
              />
              <TextField
                fullWidth
                // className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                variant='standard'
                disabled
                sx={{ m: 2 }}
                value={PreviousProps?.details?.[0]?.grossWeight}
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                // InputLabelProps={{ style: { fontSize: 13 } }}
                label='Weight(lbs)'
              />
            </div>
            <div
              className='LocationsClass'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Autocomplete
                fullWidth
                disabled
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    {option}
                  </Box>
                )}
                className='Margintop'
                // sx={{ width: '38vw' }}
                name='Class'
                size='small'
                options={ClassArray}
                // defaultValue='50'
                value={PreviousProps?.details?.[0]?.freightClass}
                onChange={(event, newValue) =>
                  handleClassInputChange('Class', index, newValue)
                }
                // sx={{ marginLeft: 1 }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    label='Freight Class'
                    variant='standard'
                    sx={{}}
                    // placeholder='Payment Type'

                    {...params}
                    InputProps={{
                      disableUnderline: true
                      // ...params.InputProps
                    }}
                  />
                )}
              />

              <TextField
                fullWidth
                className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                sx={{ m: 2 }}
                variant='standard'
                disabled={
                  PreviousProps?.carrierApiNames === 'carrierApiNames'
                    ? false
                    : true
                }
                label='NMFC'
                name='NMFCnumber'
                // defaultValue={
                //   PreviousProps?.carrierApiNames === 'carrierApiNames'
                //     ? ''
                //     : '5242141'
                // }
                value={PreviousProps?.details?.[0]?.nmfc}
                onChange={event =>
                  handleClassInputChange('NMFCnumber', index, event)
                }
                size='small'
                id='outlined-password-input'
                // type='number'
                autoComplete='current-password'
              // value={'149980-02'}
              />
            </div>
            <div
              // className='LocationsClass'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                fullWidth
                disabled
                label='SO#'
                variant='standard'
                sx={{ mt: 2 }}
                value={PreviousProps?.soNumber}
                // placeholder='Payment Type'

                InputProps={{
                  disableUnderline: true
                  // ...params.InputProps
                }}
              />
              <TextField
                fullWidth
                // className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                sx={{ mt: 2 }}
                variant='standard'
                disabled
                label='Customer PO#'
                value={PreviousProps?.clientPONumber}
                onChange={event =>
                  handleClassInputChange('NMFCnumber', index, event)
                }
                size='small'
                id='outlined-password-input'
                // type='number'
                autoComplete='current-password'
              // value={'149980-02'}
              />
            </div>
            {props?.location?.state?.disptachOpen === 'Dispatch' && (
              <div
                className='LocationsClass'
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <TextField
                  disabled={assignedProNmb ? true : false}
                  fullWidth
                  error={Boolean(validationErrors['proNumber'])}
                  helperText={validationErrors['proNumber']}
                  className='RightMarginItems'
                  value={proNumber}
                  // onChange={e => setProNumber(e.target.value)}
                  onChange={e => {
                    setProNumber(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      proNumber: null
                    });
                  }}
                  size='small'
                  sx={{ mt: 2 }}
                  variant='standard'
                  label='PRO#'
                  type='text'
                  inputProps={{ disableUnderline: true }}
                />
                <TextField
                  disabled={assignedBolNmb ? true : false}
                  fullWidth
                  className='RightMarginItems'
                  // disabled
                  value={BOLNumber}
                  // value='98642469'
                  label='BOL#'
                  onChange={e => setBOLNumber(e.target.value)}
                  // size='small'
                  // sx={!BOLNumber ? { display: 'none' } : { width: '20vw', mt: 2 }}
                  sx={{ m: 1.7 }}
                  variant='standard'
                  type='text'
                  inputProps={{ disableUnderline: true }}
                />
              </div>
            )}
            <div
              className='LocationsClass'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                fullWidth
                className='RightMarginItems'
                variant='standard'
                inputProps={{ disableUnderline: true }}
                // disabled
                value={pickupNumber}
                onChange={e => setPickupNumber(e.target.value)}
                type='text'
                size='small'
                sx={{ mt: 2 }}
                label='Pickup#'
              />
              <TextField
                fullWidth
                error={Boolean(validationErrors['refOrQuoteNmbErr'])}
                helperText={validationErrors['refOrQuoteNmbErr']}
                onChange={e => {
                  setRefOrQuoteNmb(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    refOrQuoteNmbErr: null
                  });
                }}
                className='RightMarginItems'
                value={refOrQuoteNmb}
                size='small'
                sx={{ m: 2 }}
                label='Ref / Quote# If any'
                type='text'
                variant='standard'
                inputProps={{ disableUnderline: true }}
              />
            </div>
          </Box>
        );
    } else if (index === 1) {
      if (props?.location?.state?.disptachOpen === 'Get Quote') {
        return (
          <Box
            className='ParentFlexDiv'
            sx={
              browser === 'Firefox'
                ? { marginTop: 1, marginLeft: '10%' }
                : { marginTop: 0, marginLeft: '10%' }
            }
          >
            <br />
            <div className='LocationsClass'>
              <div>
                <TextField
                  sx={{ width: '63vw' }}
                  disabled
                  label='Ship From'
                  variant='standard'
                  value={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? PreviousProps?.shipFrom?.streetLines
                      : PreviousProps?.originCity +
                      ' ' +
                      PreviousProps?.originState +
                      ' ' +
                      PreviousProps?.originZipCode
                  }
                />
              </div>
            </div>

            <div className='LocationsClass'>
              <TextField
                InputProps={{
                  disableUnderline: true
                }}
                disabled
                variant='standard'
                // error={Boolean(validationErrors['originDate'])}
                // helperText={validationErrors['originDate']}
                onChange={e => setOriginDate(new Date(e.target.value))}
                size='small'
                // defaultValue={materialDateInput}
                value={PreviousProps?.pickupDate}
                sx={{ width: '43vw', mt: 2 }}
                // type='date'
                label={
                  props?.location?.state?.rateType !== 'Quick Quote' && 'Date'
                }
              />
              <div>

                <div className='mt-3 d-flex flex-column' style={{ width: '20vw' }}>
                  <div
                    style={{
                      fontSize: 13,
                      color: 'rgba(0, 0, 0, 0.58)'
                    }}
                  >
                    Pickup Hours
                  </div>
                  <div className='mt-2 d-flex justify-content-between'>
                    <TextField
                      label='Open Time'
                      value={originOpenTimeValue}
                      onChange={e => {
                        setOriginOpenTimeValue(e.target.value)
                        setValidationErrors({
                          ...validationErrors,
                          originOpenTime: null
                        });
                      }}
                      type='time'
                      sx={{ width: '48%' }}
                    />

                    <TextField
                      label='Close Time'
                      value={originCloseTimeValue}
                      onChange={e => {
                        setOriginCloseTimeValue(e.target.value)
                        setValidationErrors({
                          ...validationErrors,
                          originOpenTime: null
                        });
                      }}
                      type='time'
                      sx={{ width: '48%' }}
                    />
                  </div>
                </div>

                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    sx={{ width: '20vw' }}
                    components={['TimePicker', 'TimePicker']}
                  >
                    <DemoItem>
                      <TimePicker
                        onChange={newValue => {
                          handleTimeChange(newValue, 'OriginOpenTime');
                          setValidationErrors({
                            ...validationErrors,
                            originOpenTime: null
                          });
                        }}
                        label='Open Time'
                        value={dayjs(originOpenTimeValue, 'HH:mm')}
                      // defaultValue={dayjs('2022-04-17T15:30')}
                      />
                    </DemoItem>
                    <DemoItem>
                      <TimePicker
                        onChange={newValue => {
                          handleTimeChange(newValue, 'OriginCloseTime');
                          setValidationErrors({
                            ...validationErrors,
                            originOpenTime: null
                          });
                        }}
                        value={dayjs(originCloseTimeValue, 'HH:mm')}
                        label='Close Time'
                      // defaultValue={dayjs('2022-04-17T15:30')}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider> */}
              </div>
            </div>
            <div
              style={
                browser === 'Firefox'
                  ? { marginBottom: 30 }
                  : { marginBottom: 3, marginTop: '-20px' }
              }
            >
              <h6>Pickup Instructions</h6>
              <ReactQuill
                style={
                  browser === 'Firefox'
                    ? {
                      width: '63vw',
                      height: '5vh',
                      marginBottom: 20,
                      marginTop: 0
                    }
                    : {
                      width: '63vw',
                      marginTop: 0
                    }
                }
                value={pickupRemarks}
                onChange={e => {
                  setPickupRemarks(e);
                  setValidationErrors({
                    ...validationErrors,
                    pickupRemarks: null
                  });
                }}
              />
              {/* <div>
              <h2>Preview:</h2>
              <div dangerouslySetInnerHTML={{ __html: pickupInstContent }} />
            </div> */}
            </div>

            <div className='LocationsClass'>
              <div>
                <TextField
                  sx={{ width: '63vw', mt: 2 }}
                  disabled
                  label='Ship To'
                  variant='standard'
                  value={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? PreviousProps?.shipTo?.streetLines
                      : PreviousProps?.destinationCity +
                      ' ' +
                      PreviousProps?.destinationState +
                      ' ' +
                      PreviousProps?.destinationZipCode
                  }
                />
              </div>
            </div>
            <div className='LocationsClass'>
              <TextField
                disabled
                InputProps={{
                  disableUnderline: true
                }}
                variant='standard'
                // error={Boolean(validationErrors['originDate'])}
                // helperText={validationErrors['originDate']}
                // onChange={e => setOriginDate(new Date(e.target.value))}
                size='small'
                // defaultValue={materialDateInput}
                value={PreviousProps?.deliveryDate}
                sx={{ width: '43vw', mt: 2 }}
                // type='date'
                label={
                  props?.location?.state?.rateType !== 'Quick Quote' &&
                  'Est Delivery Date'
                }
              />
              <div>

                <div className='mt-3 d-flex flex-column' style={{ width: '20vw' }}>
                  <div
                    style={{
                      fontSize: 13,
                      color: 'rgba(0, 0, 0, 0.58)'
                    }}
                  >
                    Delivery Hours
                  </div>

                  <div className='mt-2 d-flex justify-content-between'>
                    <TextField
                      label='Open Time'
                      value={destinationOpenTimeValue}
                      onChange={e => {
                        setDestinationOpenTimeValue(e.target.value)
                        setValidationErrors({
                          ...validationErrors,
                          originOpenTime: null
                        });
                      }}
                      type='time'
                      sx={{ width: '48%' }}
                    />

                    <TextField
                      label='Close Time'
                      value={destinationCloseTimeValue}
                      onChange={e => {
                        setDestinationCloseTimeValue(e.target.value)
                        setValidationErrors({
                          ...validationErrors,
                          originOpenTime: null
                        });
                      }}
                      type='time'
                      sx={{ width: '48%' }}
                    />
                  </div>
                </div>

                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    sx={{ width: '20vw' }}
                    components={['TimePicker', 'TimePicker']}
                  >
                    <DemoItem>
                      <TimePicker
                        onChange={newValue => {
                          handleTimeChange(newValue, 'DestiOpenTime');
                          setValidationErrors({
                            ...validationErrors,
                            originOpenTime: null
                          });
                        }}
                        label='Open Time'
                        value={dayjs(destinationOpenTimeValue, 'HH:mm')}

                      // defaultValue={dayjs('2022-04-17T15:30')}
                      />
                    </DemoItem>
                    <DemoItem>
                      <TimePicker
                        onChange={newValue => {
                          handleTimeChange(newValue, 'destinationCloseTime');
                          setValidationErrors({
                            ...validationErrors,
                            destinationCloseTime: null
                          });
                        }}
                        value={dayjs(destinationCloseTimeValue, 'HH:mm')}
                        label='Close Time'
                      // defaultValue={dayjs('2022-04-17T15:30')}
                      />
                    </DemoItem>
                  </DemoContainer>
                </LocalizationProvider> */}


              </div>
            </div>
            <div style={browser !== 'Firefox' ? { marginTop: -20 } : {}}>
              <h6>Delivery Instructions</h6>
              <ReactQuill
                style={
                  browser === 'Firefox'
                    ? {
                      width: '63vw',
                      height: '5vh',
                      marginTop: 0
                    }
                    : {
                      width: '63vw',
                      marginTop: 0
                    }
                }
                value={deliveryRemarks}
                onChange={e => {
                  setDeliveryRemarks(e);
                  setValidationErrors({
                    ...validationErrors,
                    deliveryRemarks: null
                  });
                }}
              />
            </div>
          </Box>
        );
      }
      return (
        <Box
          sx={{
            marginTop: 0,
            height: '60vh',
            overflowY: 'scroll',
            overflowX: 'hidden'
          }}
        >
          <Box sx={{ marginTop: 5 }}>
            <div className='OriginTitle'>Origin</div>
            <hr />

            <div className='LocationsClass'>
              <div>
                <TextField
                  sx={{ width: '20vw' }}
                  disabled
                  label='Name'
                  variant='standard'
                  value={PreviousProps?.originContactPerson}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
              </div>
              <div>
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  sx={{ width: '20vw' }}
                  disabled
                  label='Company'
                  variant='standard'
                  value={PreviousProps?.originCompany}
                />
              </div>
            </div>

            <div className='LocationsClass'>
              <div>
                <TextField
                  sx={{ width: '20vw', mt: 2 }}
                  disabled
                  label='Address'
                  variant='standard'
                  value={PreviousProps?.originAddress}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
              </div>
              <div>
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  variant='standard'
                  disabled
                  sx={{ width: '20vw', mt: 2 }}
                  label='City/State/Zip/Country'
                  value={
                    PreviousProps?.originCity +
                    ' ' +
                    PreviousProps?.originState +
                    ' ' +
                    PreviousProps?.originZipCode
                  }
                />
              </div>
            </div>
            <div className='LocationsClass'>
              <div>
                <TextField
                  sx={{ width: '20vw', mt: 2 }}
                  disabled
                  label='Phone'
                  variant='standard'
                  value={PreviousProps?.originContactPhone}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
              </div>
              <div>
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  sx={{ width: '20vw', mt: 2 }}
                  disabled
                  label='Email'
                  variant='standard'
                  value={PreviousProps?.originContactEmail}
                />
              </div>
            </div>

            <div style={{ display: 'LocationsClass' }}>
              <TextField
                InputProps={{
                  disableUnderline: true
                }}
                disabled
                variant='standard'
                error={Boolean(validationErrors['originDate'])}
                helperText={validationErrors['originDate']}
                onChange={e => setOriginDate(new Date(e.target.value))}
                size='small'
                value={PreviousProps?.pickupDate}
                // defaultValue={materialDateInput}
                sx={{ width: '19vw', mt: 2 }}
                // type='date'
                label='Pickup Date'
              />
            </div>

            <Typography variant='h5' className='DestinationTitle'>
              Destination
            </Typography>
            <hr />
            <div className='LocationsClass'>
              <div>
                <TextField
                  sx={{ width: '20vw' }}
                  disabled
                  label='Ship to Name'
                  variant='standard'
                  // defaultValue='Robert Thomas'
                  value={PreviousProps?.destinationContactPerson}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
              </div>
              <div>
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  sx={{ width: '20vw' }}
                  disabled
                  label='Company'
                  variant='standard'
                  value={PreviousProps?.destinationCompany}
                />
              </div>
            </div>

            <div className='LocationsClass'>
              <div>
                <TextField
                  sx={{ width: '20vw', mt: 2 }}
                  disabled
                  label='Address'
                  variant='standard'
                  value={PreviousProps?.destinationAddress}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
              </div>
              <div>
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  variant='standard'
                  disabled
                  sx={{ width: '20vw', mt: 2 }}
                  label='City/State/Zip/Country'
                  value={
                    PreviousProps?.destinationCity +
                    ' ' +
                    PreviousProps?.destinationState +
                    ' ' +
                    PreviousProps?.destinationZipCode
                  }
                />
              </div>
            </div>
            <div className='LocationsClass'>
              <div>
                <TextField
                  sx={{ width: '20vw', mt: 2 }}
                  disabled
                  label='Phone'
                  variant='standard'
                  value={PreviousProps?.destinationContactPhone}
                  InputProps={{
                    disableUnderline: true
                  }}
                />
              </div>
              <div>
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  sx={{ width: '20vw', mt: 2 }}
                  disabled
                  label='Email'
                  variant='standard'
                  value={PreviousProps?.destinationContactEmail}
                />
              </div>
            </div>

            <div style={{ display: 'LocationsClass' }}>
              <TextField
                disabled
                InputProps={{
                  disableUnderline: true
                }}
                variant='standard'
                error={Boolean(validationErrors['originDate'])}
                helperText={validationErrors['originDate']}
                onChange={e => setOriginDate(new Date(e.target.value))}
                size='small'
                value={PreviousProps?.deliveryDate}
                sx={{ width: '19vw', mt: 2 }}
                // type='date'
                label='Exp Delivery Date'
              />
            </div>
          </Box>
          {/* Dispatch Preview  */}
          <div className='OriginTitle'>Shipment Information</div>
          <hr />
          <Box sx={{ mt: 5 }}>
            {/* <Typography sx={{ mb: 1 }} variant='body2'> */}
            <div className='LocationsClass'>
              <Autocomplete
                disabled
                sx={{ width: '20vw' }}
                // disabled={
                //   PreviousProps?.carrierApiNames === 'carrierApiNames' ? true : false
                // }
                size='small'
                value={
                  carrierName
                    ? carrierName
                    : otherCarrierName
                      ? otherCarrierName
                      : PreviousProps?.carrierName
                }
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    <li {...PreviousProps}>{option.title}</li>
                  </Box>
                )}
                onChange={(event, newValue) => {
                  if (typeof newValue === 'string') {
                    setCarrierName({
                      title: newValue.title
                    });
                    setSelectedCarrierID({
                      ID: newValue.carrierID
                    });
                    setValidationErrors({
                      ...validationErrors,
                      carrierName: null
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setCarrierName({
                      title: newValue.inputValue
                    });
                    setValidationErrors({
                      ...validationErrors,
                      carrierName: null
                    });
                  } else {
                    setCarrierName(newValue);
                    setValidationErrors({
                      ...validationErrors,
                      carrierName: null
                    });
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    option => inputValue === option.title
                  );
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Add New Carrier "${inputValue}"`
                    });
                  }

                  return filtered;
                }}
                options={PreviousProps?.carrierApiNames}
                getOptionLabel={option => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.title;
                }}
                freeSolo
                renderInput={params => (
                  <TextField
                    disabled
                    sx={{ width: '20vw' }}
                    variant='standard'
                    error={Boolean(validationErrors['carrierName'])}
                    helperText={validationErrors['carrierName']}
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    {...params}
                    InputProps={{
                      disableUnderline: true,
                      ...params.InputProps
                    }}
                    label='Carrier Name'
                  />
                )}
              />

              <TextField
                // className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                variant='standard'
                disabled
                sx={{ width: '20vw', mt: 0 }}
                value={PreviousProps?.details?.[0]?.grossWeight}
                error={Boolean(validationErrors['carrierName'])}
                helperText={validationErrors['carrierName']}
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                // InputLabelProps={{ style: { fontSize: 13 } }}
                label='Weight(lbs)'
              />
            </div>
            <div className='LocationsClass'>
              <Autocomplete
                disabled
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    {option}
                  </Box>
                )}
                // className='Margintop'
                sx={{ width: '20vw', mt: 2 }}
                name='Class'
                size='small'
                options={ClassArray}
                // defaultValue='50'
                value={PreviousProps?.details?.[0]?.freightClass}
                onChange={(event, newValue) =>
                  handleClassInputChange('Class', index, newValue)
                }
                // sx={{ marginLeft: 1 }}
                renderInput={params => (
                  <TextField
                    label='Freight Class'
                    variant='standard'
                    sx={{ width: '20vw' }}
                    // placeholder='Payment Type'

                    {...params}
                    InputProps={{
                      disableUnderline: true
                      // ...params.InputProps
                    }}
                  />
                )}
              />

              <TextField
                // className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                sx={{ width: '20vw', mt: 2 }}
                variant='standard'
                disabled
                label='NMFC'
                name='NMFCnumber'
                // defaultValue='5242141'
                value={PreviousProps?.details?.[0]?.nmfc}
                onChange={event =>
                  handleClassInputChange('NMFCnumber', index, event)
                }
                size='small'
                id='outlined-password-input'
                // type='number'
                autoComplete='current-password'
              // value={'149980-02'}
              />
            </div>
            <div style={{ marginTop: 10 }} className='LocationsClass'>
              <TextField
                disabled
                label='SO#'
                value={PreviousProps?.soNumber}
                variant='standard'
                sx={{ width: '20vw', mt: 0 }}
                // placeholder='Payment Type'

                InputProps={{
                  disableUnderline: true
                  // ...params.InputProps
                }}
              />

              <TextField
                className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                sx={{ width: '20vw', ml: 0 }}
                variant='standard'
                disabled
                label='Customer PO#'
                value={PreviousProps?.clientPONumber}
                onChange={event =>
                  handleClassInputChange('NMFCnumber', index, event)
                }
                size='small'
                id='outlined-password-input'
                // type='number'
                autoComplete='current-password'
              // value={'149980-02'}
              />
            </div>
            <div style={{ marginTop: 10 }} className='LocationsClass'>
              <TextField
                InputProps={{
                  disableUnderline: true
                }}
                disabled
                className='Margintop'
                value={proNumber}
                // onChange={e => setProNumber(e.target.value)}
                size='small'
                sx={!proNumber ? { display: 'none' } : { width: '20vw', mt: 0 }}
                variant='standard'
                label='PRO#'
                type='text'
                inputProps={{ disableUnderline: true }}
              />
              {refOrQuoteNmb && (
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  className='RightMarginItems'
                  value={refOrQuoteNmb}
                  onChange={e => setRefOrQuoteNmb(e.target.value)}
                  size='small'
                  sx={
                    !proNumber
                      ? { width: '20vw', ml: 0 }
                      : { width: '20vw', ml: 0 }
                  }
                  label='Ref / Quote# If any'
                  type='text'
                  variant='standard'
                  inputProps={{ disableUnderline: true }}
                />
              )}
            </div>
            <div className='LocationsClass'>
              <TextField
                className='Margintop'
                disabled
                value={BOLNumber}
                // value='98642469'
                onChange={e => setBOLNumber(e.target.value)}
                type='text'
                size='small'
                sx={!BOLNumber ? { display: 'none' } : { width: '20vw', mt: 2 }}
                label='BOL#'
                variant='standard'
                InputProps={{
                  disableUnderline: true
                }}
              />
              {pickupNumber && (
                <TextField
                  className='RightMarginItems'
                  variant='standard'
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  value={pickupNumber}
                  onChange={e => setPoRefNumber(e.target.value)}
                  type='text'
                  size='small'
                  sx={
                    !BOLNumber
                      ? { width: '20vw', margin: '1 0 0 0' }
                      : { width: '20vw', mt: 2 }
                  }
                  label='Pickup#'
                />
              )}
            </div>
            <div className='LocationsClass'>
              <Autocomplete
                freeSolo
                disabled
                className='Margintop'
                sx={{ width: '20vw', margin: '15 0 0 0' }}
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    {option}
                  </Box>
                )}
                size='small'
                options={PaymentTypeArr}
                value={
                  props?.location?.state?.rateType === 'Quick Quote'
                    ? PreviousProps?.paymentType === '0'
                      ? 'Outbound'
                      : PreviousProps?.paymentType === '1'
                        ? 'Inbound'
                        : 'Third Party'
                    : PreviousProps?.freightTerms
                }
                onChange={(event, newValue) => {
                  setPaymentType(newValue);
                  setValidationErrors({
                    ...validationErrors,
                    paymentTerms: null
                  });
                }}
                // sx={{ marginLeft: 1 }}
                renderInput={params => (
                  <TextField
                    disabled
                    variant='standard'
                    FormHelperTextProps={{ style: { margin: '5 30 0 0' } }}
                    error={Boolean(validationErrors['paymentTerms'])}
                    helperText={validationErrors['paymentTerms']}
                    label='Freight Payment Type'
                    {...params}
                    InputProps={{
                      disableUnderline: true,
                      ...params.InputProps
                    }}
                  />
                )}
              />
              {/* <Autocomplete
                freeSolo
                disabled
                sx={{ width: '20vw' }}
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    {option}
                  </Box>
                )}
                size='small'
                // options={HazmatOptions}
                value={hazmatSwitch ? 'Yes' : 'No'}
                onChange={(event, newValue) => {
                  setHazmatType(newValue);
                  setValidationErrors({
                    ...validationErrors,
                    paymentTerms: null
                  });
                }}
                // sx={{ marginLeft: 1 }}
                renderInput={params => (
                  <TextField
                    className='RightMarginItems'
                    variant='standard'
                    inputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ style: { margin: '5 30 0 0' } }}
                    error={Boolean(validationErrors['paymentTerms'])}
                    helperText={validationErrors['paymentTerms']}
                    label='Hazmat'
                    {...params}
                    InputProps={{
                      disableUnderline: true,
                      ...params.InputProps
                    }}
                  />
                )}
              /> */}
            </div>
          </Box>
          <br />
          {(insidePickupSwitch ||
            liftgatePickupSwitch ||
            residentialPickupSwitch ||
            secureAccesPickupSwitch ||
            limitedPickupAccessSwitch ||
            freezeProtectionPickupSwitch ||
            insideDeliverySwitch ||
            liftgateDeliverySwitch ||
            residentialDeliverySwitch ||
            secureAccesDeliverySwitch ||
            limitedDeliverySwitch ||
            callBeforeDeliveryServicesSwitch) && (
              <>
                <div className='OriginTitle'>Services Optional</div>
                <hr />
                <Box sx={{}}>
                  <div style={{ display: 'flex' }}>
                    <div>
                      <Typography className='ServicesTitle' variant='subtitle2'>
                        Pickup
                      </Typography>
                    </div>

                    <div>
                      <Typography
                        className='ServicesTitleDelivery'
                        variant='subtitle2'
                      >
                        Delivery
                      </Typography>
                    </div>
                  </div>
                  <div className='PreviewServicesSwitches'>
                    <div>
                      <FormControl sx={{ m: 0 }} size='large'>
                        {insidePickupSwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='TopPreviewSwitcheLabels'>
                              Inside Pickup
                            </Typography>
                          </div>
                        )}
                        {liftgatePickupSwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Lift Gate Services
                            </Typography>
                          </div>
                        )}
                        {residentialPickupSwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Residential Pickup
                            </Typography>
                          </div>
                        )}
                        {secureAccesPickupSwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Secure Access
                            </Typography>
                          </div>
                        )}
                        {limitedPickupAccessSwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Limited Access
                            </Typography>
                          </div>
                        )}
                        {freezeProtectionPickupSwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Freeze Protection
                            </Typography>
                          </div>
                        )}
                      </FormControl>
                    </div>
                    <div>
                      <FormControl size='large'>
                        {insideDeliverySwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='TopPreviewSwitcheLabels'>
                              Inside Delivery
                            </Typography>
                          </div>
                        )}
                        {liftgateDeliverySwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Lift Gate Services
                            </Typography>
                          </div>
                        )}
                        {residentialDeliverySwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Residential Delivery
                            </Typography>
                          </div>
                        )}

                        {secureAccesDeliverySwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Secure Access
                            </Typography>
                          </div>
                        )}
                        {limitedDeliverySwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Limited Access
                            </Typography>
                          </div>
                        )}

                        {callBeforeDeliveryServicesSwitch && (
                          <div className={classes.switchRoot}>
                            <Typography className='PreviewSwitcheLabels'>
                              Call Before Delivery
                            </Typography>
                          </div>
                        )}
                      </FormControl>
                    </div>
                  </div>
                </Box>
              </>
            )}
          <br />
          {otherCarrierName && (
            <div className='OriginTitle'>Other Carrier Info</div>
          )}
          <hr />

          {otherCarrierName && (
            <Box className='RemarksParent'>
              <TextField
                variant='standard'
                InputProps={{ disableUnderline: true }}
                disabled
                error={Boolean(validationErrors['otherCarrierName'])}
                helperText={validationErrors['otherCarrierName']}
                value={otherCarrierName}
                onChange={e => {
                  setOtherCarrierName(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    otherCarrierName: null
                  });
                }}
                sx={{ width: '20vw' }}
                size='small'
                label='Carrier Name'
              />
              <TextField
                variant='standard'
                InputProps={{ disableUnderline: true }}
                disabled
                error={Boolean(validationErrors['truckNumber'])}
                helperText={validationErrors['truckNumber']}
                value={truckNumber}
                onChange={e => {
                  setTruckNumber(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    truckNumber: null
                  });
                }}
                sx={{ width: '20vw' }}
                size='small'
                label='Truck Number'
              />
              <TextField
                variant='standard'
                InputProps={{ disableUnderline: true }}
                disabled
                error={Boolean(validationErrors['driverName'])}
                helperText={validationErrors['driverName']}
                value={driverName}
                onChange={e => {
                  setDriverName(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    driverName: null
                  });
                }}
                sx={{ width: '20vw', mt: 0 }}
                size='small'
                label='Driver Name'
              />
              <TextField
                variant='standard'
                InputProps={{ disableUnderline: true }}
                disabled
                error={Boolean(validationErrors['driverNumber'])}
                helperText={validationErrors['driverNumber']}
                value={driverNumber}
                onPaste={handlePhoneNumberPaste}
                onChange={e => {
                  setDriverNumber(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    driverNumber: null
                  });
                }}
                sx={{ width: '20vw', mt: 0 }}
                size='small'
                label='Driver Number'
              />
            </Box>
          )}
        </Box>
      );
    } else if (index === 2) {
      if (props?.location?.state?.disptachOpen === 'Get Quote') {
        return (
          <Box
            sx={{
              marginTop: 0,
              height: '70vh',
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <Box sx={{ marginTop: 5 }}>
              <div className='OriginTitle'>Origin</div>
              <hr />

              <div className='LocationsClass'>
                {props?.location?.state?.rateType === 'Quick Quote' ? (
                  <TextField
                    sx={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? { width: '19vw' }
                        : { width: '20vw' }
                    }
                    disabled={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? false
                        : true
                    }
                    InputProps={{
                      disableUnderline:
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                    }}
                    label='Street Address'
                    variant='standard'
                    value={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? originName
                        : PreviousProps?.originContactPerson
                    }
                    onChange={e => setOriginName(e.target.value)}
                  />
                ) : (
                  <div>
                    <TextField
                      sx={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? { width: '19vw' }
                          : { width: '20vw' }
                      }
                      disabled={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                      }
                      InputProps={{
                        disableUnderline:
                          props?.location?.state?.rateType === 'Quick Quote'
                            ? false
                            : true
                      }}
                      label='Name'
                      variant='standard'
                      value={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? originName
                          : PreviousProps?.originContactPerson
                      }
                      onChange={e => setOriginName(e.target.value)}
                    />
                  </div>
                )}
                <div>
                  <TextField
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    error={Boolean(validationErrors['originCompanyNameError'])}
                    helperText={validationErrors['originCompanyNameError']}
                    InputProps={{
                      disableUnderline:
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                    }}
                    sx={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? { width: '19vw', ml: 2 }
                        : { width: '20vw' }
                    }
                    disabled={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? false
                        : true
                    }
                    label='Company'
                    variant='standard'
                    value={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? originCompanyName
                        : PreviousProps?.originCompany
                    }
                    onChange={e => {
                      setValidationErrors({
                        ...validationErrors,
                        originCompanyNameError: null
                      });

                      setOriginCompanyName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className='LocationsClass'>
                {props?.location?.state?.rateType !== 'Quick Quote' && (
                  <div>
                    <TextField
                      sx={{ width: '20vw', mt: 2 }}
                      disabled
                      label='Address'
                      variant='standard'
                      value={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? PreviousProps?.shipFrom?.streetLines
                          : PreviousProps?.originAddress
                      }
                      InputProps={{
                        disableUnderline: true
                      }}
                    />
                  </div>
                )}
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    variant='standard'
                    disabled
                    sx={{ width: '20vw', mt: 2 }}
                    label='City/State/Zip/Country'
                    value={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? PreviousProps?.shipFrom?.streetLines
                        : PreviousProps?.originCity +
                        ' ' +
                        PreviousProps?.originState +
                        ' ' +
                        PreviousProps?.originZipCode
                    }
                  />
                  {props?.location?.state?.rateType === 'Quick Quote' && (
                    <TextField
                      InputProps={{
                        disableUnderline:
                          props?.location?.state?.rateType === 'Quick Quote'
                            ? false
                            : true
                      }}
                      sx={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? { width: '19vw', mt: 2 }
                          : { width: '20vw' }
                      }
                      disabled={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                      }
                      label='Phone'
                      variant='standard'
                      FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                      error={Boolean(validationErrors['phoneNumberError'])}
                      helperText={validationErrors['phoneNumberError']}
                      onChange={event =>
                        handleCarrierPhoneNumberChange(event, 'origin')
                      }
                      value={phoneNumber}
                      onPaste={event =>
                        handleCarrierPhoneNumberPaste(event, 'originPhone')
                      }
                      required
                      // InputLabelProps={{ style: { fontSize: 13 } }}
                      size='small'
                      type='text'
                    // inputProps={{
                    //   maxLength: 10,
                    //   style: { fontSize: 13 } // Set the maximum length to 10 characters
                    // }}
                    />
                  )}
                </div>
              </div>
              {props?.location?.state?.rateType === 'Quick Quote' && (
                <div className='LocationsClass'>
                  <TextField
                    error={Boolean(validationErrors['originEmailError'])}
                    helperText={validationErrors['originEmailError']}
                    InputProps={{
                      disableUnderline:
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                    }}
                    sx={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? { width: '19vw', mt: 1 }
                        : { width: '20vw' }
                    }
                    disabled={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? false
                        : true
                    }
                    label='Email'
                    type='email'
                    variant='standard'
                    value={originEmail}
                    onChange={e => {
                      setValidationErrors({
                        ...validationErrors,
                        originEmailError: null
                      });
                      setOriginEmail(e.target.value);
                    }}
                  />
                </div>
              )}
              {props?.location?.state?.rateType !== 'Quick Quote' && (
                <div className='LocationsClass'>
                  <div>
                    <TextField
                      sx={{ width: '20vw', mt: 2 }}
                      disabled
                      label='Phone'
                      variant='standard'
                      value={PreviousProps?.originContactPhone}
                      InputProps={{
                        disableUnderline: true
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      InputProps={{
                        disableUnderline: true
                      }}
                      sx={{ width: '20vw', mt: 2 }}
                      disabled
                      label='Email'
                      variant='standard'
                      value={PreviousProps?.originContactEmail}
                    />
                  </div>
                </div>
              )}

              <div style={{ display: 'LocationsClass' }}>
                <TextField
                  InputProps={{
                    disableUnderline:
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? false
                        : true
                  }}
                  disabled={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? false
                      : true
                  }
                  variant='standard'
                  error={Boolean(validationErrors['originDate'])}
                  helperText={validationErrors['originDate']}
                  onChange={e => handleDateChange(e)}
                  size='small'
                  value={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? originDate
                      : PreviousProps?.pickupDate
                  }
                  defaultValue={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? materialDateInput
                      : PreviousProps?.pickupDate
                  }
                  sx={{ width: '19vw', mt: 2 }}
                  type={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? 'date'
                      : 'text'
                  }
                  label='Pickup Date'
                  onClick={e =>
                    setValidationErrors({ ...validationErrors, originDate: '' })
                  }
                />

                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  FormHelperTextProps={{
                    style: { whiteSpace: 'nowrap' }
                  }}
                  error={Boolean(validationErrors['originOpenTime'])}
                  helperText={validationErrors['originOpenTime']}
                  id='time'
                  onChange={e => {
                    handleTimeChange(e, 'OriginOpenTime');
                    setValidationErrors({
                      ...validationErrors,
                      originOpenTime: null
                    });
                  }}
                  value={originOpenTimeValue}
                  size='small'
                  sx={{ width: '10vw', m: 2 }}
                  type='time'
                  label='Open Time'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  FormHelperTextProps={{
                    style: { whiteSpace: 'nowrap' }
                  }}
                  error={Boolean(validationErrors['originCloseTime'])}
                  helperText={validationErrors['originCloseTime']}
                  id='time'
                  value={originCloseTimeValue}
                  onChange={e => {
                    handleTimeChange(e, 'OriginCloseTime');
                    setValidationErrors({
                      ...validationErrors,
                      originCloseTime: null
                    });
                  }}
                  size='small'
                  sx={{ width: '10vw', margin: '15 0 0 0' }}
                  type='time'
                  label='Close Time'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

              <Typography variant='h5' className='DestinationTitle'>
                Destination
              </Typography>
              <hr />
              <div className='LocationsClass'>
                {props?.location?.state?.rateType === 'Quick Quote' ? (
                  <div>
                    <TextField
                      sx={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? { width: '19vw' }
                          : { width: '20vw' }
                      }
                      disabled={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                      }
                      label='Street Address'
                      variant='standard'
                      value={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? destinationName
                          : PreviousProps?.destinationContactPerson
                      }
                      onChange={e => setDestinationName(e.target.value)}
                    />
                  </div>
                ) : (
                  <div>
                    <TextField
                      sx={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? { width: '19vw' }
                          : { width: '20vw' }
                      }
                      disabled={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                      }
                      InputProps={{
                        disableUnderline:
                          props?.location?.state?.rateType === 'Quick Quote'
                            ? false
                            : true
                      }}
                      label='Ship to Name'
                      variant='standard'
                      // defaultValue='Robert Thomas'
                      value={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? destinationName
                          : PreviousProps?.destinationContactPerson
                      }
                      onChange={e => setDestinationName(e.target.value)}
                    />
                  </div>
                )}
                <div>
                  <TextField
                    error={Boolean(
                      validationErrors['destinationCompanyNameError']
                    )}
                    helperText={validationErrors['destinationCompanyNameError']}
                    sx={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? { width: '19vw', ml: 2 }
                        : { width: '20vw' }
                    }
                    disabled={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? false
                        : true
                    }
                    InputProps={{
                      disableUnderline:
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                    }}
                    label='Company'
                    variant='standard'
                    value={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? destinationCompanyName
                        : PreviousProps?.destinationCompany
                    }
                    onChange={e => {
                      setValidationErrors({
                        ...validationErrors,
                        destinationCompanyNameError: null
                      });
                      setDestinationCompanyName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className='LocationsClass'>
                {props?.location?.state?.rateType !== 'Quick Quote' && (
                  <div>
                    <TextField
                      sx={{ width: '20vw', mt: 2 }}
                      disabled
                      label='Address'
                      variant='standard'
                      value={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? PreviousProps?.shipTo?.streetLines
                          : PreviousProps?.destinationAddress
                      }
                      InputProps={{
                        disableUnderline: true
                      }}
                    />
                  </div>
                )}
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    variant='standard'
                    disabled
                    sx={{ width: '20vw', mt: 2 }}
                    label='City/State/Zip/Country'
                    value={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? PreviousProps?.shipTo?.streetLines
                        : PreviousProps?.destinationCity +
                        ' ' +
                        PreviousProps?.destinationState +
                        ' ' +
                        PreviousProps?.destinationZipCode
                    }
                  />
                  {props?.location?.state?.rateType === 'Quick Quote' && (
                    <TextField
                      InputProps={{
                        disableUnderline:
                          props?.location?.state?.rateType === 'Quick Quote'
                            ? false
                            : true
                      }}
                      sx={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? { width: '19vw', mt: 2 }
                          : { width: '20vw' }
                      }
                      disabled={
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                      }
                      label='Phone'
                      variant='standard'
                      FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                      error={Boolean(
                        validationErrors['destinationPhoneNumberError']
                      )}
                      helperText={
                        validationErrors['destinationPhoneNumberError']
                      }
                      onChange={event =>
                        handleCarrierPhoneNumberChange(event, 'destination')
                      }
                      value={destinationPhoneNumber}
                      onPaste={event =>
                        handleCarrierPhoneNumberPaste(event, 'destinationPhone')
                      }
                      required
                      // InputLabelProps={{ style: { fontSize: 13 } }}
                      size='small'
                      type='text'
                    // inputProps={{
                    //   maxLength: 10,
                    //   style: { fontSize: 13 } // Set the maximum length to 10 characters
                    // }}
                    />
                  )}
                </div>
              </div>
              {props?.location?.state?.rateType === 'Quick Quote' && (
                <div className='LocationsClass'>
                  <TextField
                    error={Boolean(validationErrors['destinationEmailError'])}
                    helperText={validationErrors['destinationEmailError']}
                    InputProps={{
                      disableUnderline:
                        props?.location?.state?.rateType === 'Quick Quote'
                          ? false
                          : true
                    }}
                    sx={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? { width: '19vw', mt: 1 }
                        : { width: '20vw' }
                    }
                    disabled={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? false
                        : true
                    }
                    label='Email'
                    type='email'
                    variant='standard'
                    value={destinationEmail}
                    onChange={e => {
                      setValidationErrors({
                        ...validationErrors,
                        destinationEmailError: null
                      });
                      setDestinationEmail(e.target.value);
                    }}
                  />
                </div>
              )}
              {props?.location?.state?.rateType !== 'Quick Quote' && (
                <div className='LocationsClass'>
                  <div>
                    <TextField
                      sx={{ width: '20vw', mt: 2 }}
                      disabled
                      label='Phone'
                      variant='standard'
                      value={PreviousProps?.destinationContactPhone}
                      InputProps={{
                        disableUnderline: true
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      InputProps={{
                        disableUnderline: true
                      }}
                      sx={{ width: '20vw', mt: 2 }}
                      disabled
                      label='Email'
                      variant='standard'
                      value={PreviousProps?.destinationContactEmail}
                    />
                  </div>
                </div>
              )}

              <div style={{ display: 'LocationsClass' }}>
                <TextField
                  disabled
                  InputProps={{
                    disableUnderline: true
                  }}
                  variant='standard'
                  // error={Boolean(validationErrors['originDate'])}
                  // helperText={validationErrors['originDate']}
                  onChange={e => setOriginDate(new Date(e.target.value))}
                  size='small'
                  value={
                    // props?.location?.state?.rateType === 'Quick Quote'
                    // ?
                    apiDisplayDate ? apiDisplayDate : 'N/A'
                    // : PreviousProps?.deliveryDate
                  }
                  sx={{ width: '19vw', mt: 2 }}
                  // type='date'
                  label='Est Delivery Date'
                />

                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  FormHelperTextProps={{
                    style: { whiteSpace: 'nowrap' }
                  }}
                  error={Boolean(validationErrors['originOpenTime'])}
                  helperText={validationErrors['originOpenTime']}
                  id='time'
                  onChange={e => {
                    handleTimeChange(e, 'OriginOpenTime');
                    setValidationErrors({
                      ...validationErrors,
                      originOpenTime: null
                    });
                  }}
                  value={destinationOpenTimeValue}
                  size='small'
                  sx={{ width: '10vw', m: 2 }}
                  type='time'
                  label='Open Time'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  FormHelperTextProps={{
                    style: { whiteSpace: 'nowrap' }
                  }}
                  error={Boolean(validationErrors['originCloseTime'])}
                  helperText={validationErrors['originCloseTime']}
                  id='time'
                  value={destinationCloseTimeValue}
                  onChange={e => {
                    handleTimeChange(e, 'OriginCloseTime');
                    setValidationErrors({
                      ...validationErrors,
                      originCloseTime: null
                    });
                  }}
                  size='small'
                  sx={{ width: '10vw', margin: '15 0 0 0' }}
                  type='time'
                  label='Close Time'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </Box>
            {/* get Rates Preview */}
            <div className='OriginTitle'>Shipment Information</div>
            <hr />
            <Box sx={{ mt: 5 }}>
              {/* <Typography sx={{ mb: 1 }} variant='body2'> */}
              <div className='LocationsClass'>
                <Autocomplete
                  disabled
                  sx={{ width: '20vw' }}
                  size='small'
                  value={
                    props?.location?.state?.disptachOpen === 'Get Quote'
                      ? selectedCarrierNameQuoteRates
                      : PreviousProps?.selectedCarrier
                        ? PreviousProps?.selectedCarrier?.carrierName
                        : carrierName
                  }
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      <li {...PreviousProps}>{option.title}</li>
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setCarrierName({
                        title: newValue.title
                      });
                      setSelectedCarrierID({
                        ID: newValue.carrierID
                      });
                      setValidationErrors({
                        ...validationErrors,
                        carrierName: null
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setCarrierName({
                        title: newValue.inputValue
                      });
                      setValidationErrors({
                        ...validationErrors,
                        carrierName: null
                      });
                    } else {
                      setCarrierName(newValue);
                      setValidationErrors({
                        ...validationErrors,
                        carrierName: null
                      });
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some(
                      option => inputValue === option.title
                    );
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        title: `Add New Carrier "${inputValue}"`
                      });
                    }

                    return filtered;
                  }}
                  options={PreviousProps?.carrierApiNames}
                  getOptionLabel={option => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  // renderOption={(PreviousProps, option) => <li {...PreviousProps}>{option.title}</li>}

                  freeSolo
                  renderInput={params => (
                    <TextField
                      disabled
                      sx={{ width: '20vw' }}
                      variant='standard'
                      error={Boolean(validationErrors['carrierName'])}
                      helperText={validationErrors['carrierName']}
                      FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                      {...params}
                      InputProps={{
                        disableUnderline: true,
                        ...params.InputProps
                      }}
                      label='Carrier Name'
                    />
                  )}
                />

                <TextField
                  // className='RightMarginItems'
                  InputProps={{
                    disableUnderline: true
                  }}
                  variant='standard'
                  disabled
                  sx={{ width: '20vw' }}
                  value={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? PreviousProps?.commodityInfo?.reduce((total, item) => {
                        return total + parseFloat(item?.weight?.value || 0);
                      }, 0)
                      : PreviousProps?.details?.[0]?.grossWeight
                  }
                  error={Boolean(validationErrors['carrierName'])}
                  helperText={validationErrors['carrierName']}
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  label='Weight(lbs)'
                />
              </div>
              {carrierShipType === 'LTL' && (
                <div className='LocationsClass'>
                  <Autocomplete
                    disabled
                    renderOption={(PreviousProps, option) => (
                      <Box style={{ fontSize: 13 }} {...PreviousProps}>
                        {option}
                      </Box>
                    )}
                    className='Margintop'
                    sx={{ width: '20vw', mt: 2 }}
                    name='Class'
                    size='small'
                    options={ClassArray}
                    // defaultValue='50'
                    value={
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? PreviousProps?.commodityInfo?.[0]?.freightClass
                        : PreviousProps?.details?.[0]?.freightClass
                    }
                    onChange={(event, newValue) =>
                      handleClassInputChange('Class', index, newValue)
                    }
                    // sx={{ marginLeft: 1 }}
                    renderInput={params => (
                      <TextField
                        label='Freight Class'
                        variant='standard'
                        sx={{ width: '20vw' }}
                        // placeholder='Payment Type'

                        {...params}
                        InputProps={{
                          disableUnderline: true
                          // ...params.InputProps
                        }}
                      />
                    )}
                  />

                  {props?.location?.state?.rateType !== 'Quick Quote' && (
                    <TextField
                      className='RightMarginItems'
                      InputProps={{
                        disableUnderline: true
                      }}
                      sx={{ width: '20vw', mt: 2 }}
                      variant='standard'
                      disabled
                      label='NMFC'
                      name='NMFCnumber'
                      // defaultValue='5242141'
                      value={PreviousProps?.details?.[0]?.nmfc}
                      onChange={event =>
                        handleClassInputChange('NMFCnumber', index, event)
                      }
                      size='small'
                      id='outlined-password-input'
                      // type='number'
                      autoComplete='current-password'
                    // value={'149980-02'}
                    />
                  )}
                </div>
              )}

              <div className='LocationsClass'>
                <TextField
                  required
                  error={Boolean(validationErrors['saleOrderNumberError'])}
                  helperText={validationErrors['saleOrderNumberError']}
                  className='Margintop'
                  disabled={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? false
                      : true
                  }
                  InputProps={{
                    disableUnderline:
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? false
                        : true
                  }}
                  label='SO#'
                  value={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? saleOrderNumber
                      : PreviousProps?.soNumber
                  }
                  onChange={e => {
                    setValidationErrors({
                      ...validationErrors,
                      saleOrderNumberError: null
                    });
                    setSaleOrderNumber(e.target.value);
                  }}
                  // type='number'
                  variant='standard'
                  sx={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? { width: '19vw', mr: 2, mt: 2 }
                      : { width: '20vw', mt: 2 }
                  }
                // placeholder='Payment Type'
                />

                <TextField
                  className='RightMarginItems'
                  disabled={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? false
                      : true
                  }
                  InputProps={{
                    disableUnderline:
                      props?.location?.state?.rateType === 'Quick Quote'
                        ? false
                        : true
                  }}
                  sx={{ width: '20vw', mt: 2.4 }}
                  variant='standard'
                  label='Customer PO#'
                  value={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? customerPoNmb
                      : PreviousProps?.clientPONumber
                  }
                  onChange={e => setCustomerPoNmb(e.target.value)}
                  size='small'
                  id='outlined-password-input'
                  // type='number'
                  autoComplete='current-password'
                // value={'149980-02'}
                />
              </div>

              <div style={{ marginTop: 15 }} className='LocationsClass'>
                {PreviousProps?.proNumber && (
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    disabled
                    className='Margintop'
                    value={PreviousProps?.proNumber}
                    onChange={e => setProNumber(e.target.value)}
                    size='small'
                    sx={{ width: '20vw' }}
                    variant='standard'
                    label='PRO#'
                    type='text'
                    inputProps={{ disableUnderline: true }}
                  />
                )}
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  className='RightMarginItems'
                  value={selectedCarrierQuotaionID}
                  onChange={e => setRefOrQuoteNmb(e.target.value)}
                  size='small'
                  sx={{ width: '20vw' }}
                  label='Ref / Quote# If any'
                  type='text'
                  variant='standard'
                  inputProps={{ disableUnderline: true }}
                />
                {props?.location?.state?.rateType === 'Quick Quote' && (
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    disabled
                    className='RightMarginItems'
                    value={totalNetChargeState}
                    size='small'
                    sx={{ width: '20vw' }}
                    label='Total Net Charge'
                    type='text'
                    variant='standard'
                    inputProps={{ disableUnderline: true }}
                  />
                )}
              </div>
              <div className='LocationsClass'>
                {PreviousProps?.proNumber && (
                  <TextField
                    className='Margintop'
                    disabled
                    value={PreviousProps?.proNumber}
                    // value='98642469'
                    onChange={e => setBOLNumber(e.target.value)}
                    type='text'
                    size='small'
                    sx={{ width: '20vw', mt: 2 }}
                    label='BOL#'
                    variant='standard'
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                )}
                {PreviousProps?.pickupNumber && (
                  <TextField
                    className='RightMarginItems'
                    variant='standard'
                    InputProps={{
                      disableUnderline: true
                    }}
                    disabled
                    value={PreviousProps?.pickupNumber}
                    onChange={e => setPoRefNumber(e.target.value)}
                    type='text'
                    size='small'
                    sx={{ width: '20vw' }}
                    label='Pickup#'
                  />
                )}
              </div>
              <div className='LocationsClass'>
                <Autocomplete
                  freeSolo
                  disabled
                  className='Margintop'
                  sx={{ width: '20vw', margin: '15 0 0 0' }}
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      {option}
                    </Box>
                  )}
                  size='small'
                  options={PaymentTypeArr}
                  value={
                    props?.location?.state?.rateType === 'Quick Quote'
                      ? PreviousProps?.paymentType === '0'
                        ? 'Outbound Prepaid'
                        : PreviousProps?.paymentType === '1'
                          ? 'Inbound Collect'
                          : '3rd Party'
                      : PreviousProps?.freightTerms
                  }
                  onChange={(event, newValue) => {
                    setPaymentType(newValue);
                    setValidationErrors({
                      ...validationErrors,
                      paymentTerms: null
                    });
                  }}
                  // sx={{ marginLeft: 1 }}
                  renderInput={params => (
                    <TextField
                      disabled
                      variant='standard'
                      FormHelperTextProps={{ style: { margin: '5 30 0 0' } }}
                      error={Boolean(validationErrors['paymentTerms'])}
                      helperText={validationErrors['paymentTerms']}
                      label='Freight Payment Type'
                      {...params}
                      InputProps={{
                        disableUnderline: true,
                        ...params.InputProps
                      }}
                    />
                  )}
                />
                <Autocomplete
                  freeSolo
                  disabled
                  sx={{ width: '20vw' }}
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      {option}
                    </Box>
                  )}
                  size='small'
                  // options={HazmatOptions}
                  value={hazmatSwitch ? 'Yes' : 'No'}
                  onChange={(event, newValue) => {
                    setHazmatType(newValue);
                    setValidationErrors({
                      ...validationErrors,
                      paymentTerms: null
                    });
                  }}
                  // sx={{ marginLeft: 1 }}
                  renderInput={params => (
                    <TextField
                      sx={{ mt: 2 }}
                      className='RightMarginItems'
                      variant='standard'
                      inputProps={{ disableUnderline: true }}
                      FormHelperTextProps={{ style: { margin: '5 30 0 0' } }}
                      error={Boolean(validationErrors['paymentTerms'])}
                      helperText={validationErrors['paymentTerms']}
                      label='Hazmat'
                      {...params}
                      InputProps={{
                        disableUnderline: true,
                        ...params.InputProps
                      }}
                    />
                  )}
                />
              </div>
            </Box>
            <br />
            {(insidePickupSwitch ||
              liftgatePickupSwitch ||
              residentialPickupSwitch ||
              secureAccesPickupSwitch ||
              limitedPickupAccessSwitch ||
              freezeProtectionPickupSwitch ||
              insideDeliverySwitch ||
              liftgateDeliverySwitch ||
              residentialDeliverySwitch ||
              secureAccesDeliverySwitch ||
              limitedDeliverySwitch ||
              callBeforeDeliveryServicesSwitch) && (
                <>
                  <div className='OriginTitle'>Services Optional</div>
                  <hr />
                  <Box sx={{ color: 'rgba(0, 0, 0, 0.38)' }}>
                    <div className='PreviewServicesSwitches'>
                      <Typography
                        sx={{ width: '20vw' }}
                        fontSize={13}
                        className='TopPreviewSwitcheLabels'
                      >
                        PickUp Services
                      </Typography>
                      <Typography
                        fontSize={13}
                        className='TopPreviewSwitcheLabels'
                      >
                        Delivery Services
                      </Typography>
                    </div>
                    <div className='PreviewServicesSwitches'>
                      <div style={{ width: '20vw' }}>
                        <FormControl sx={{ m: 0 }} size='large'>
                          {insidePickupSwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='TopPreviewSwitcheLabels'>
                                Inside Pickup
                              </Typography>
                            </div>
                          )}
                          {liftgatePickupSwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Lift Gate Services
                              </Typography>
                            </div>
                          )}
                          {residentialPickupSwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Residential Pickup
                              </Typography>
                            </div>
                          )}
                          {secureAccesPickupSwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Secure Access
                              </Typography>
                            </div>
                          )}
                          {limitedPickupAccessSwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Limited Access
                              </Typography>
                            </div>
                          )}
                          {freezeProtectionPickupSwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Freeze Protection
                              </Typography>
                            </div>
                          )}
                        </FormControl>
                      </div>

                      <div>
                        <FormControl size='large'>
                          {insideDeliverySwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='TopPreviewSwitcheLabels'>
                                Inside Delivery
                              </Typography>
                            </div>
                          )}
                          {liftgateDeliverySwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Lift Gate Services
                              </Typography>
                            </div>
                          )}
                          {residentialDeliverySwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Residential Delivery
                              </Typography>
                            </div>
                          )}

                          {secureAccesDeliverySwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Secure Access
                              </Typography>
                            </div>
                          )}
                          {limitedDeliverySwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Limited Access
                              </Typography>
                            </div>
                          )}

                          {callBeforeDeliveryServicesSwitch && (
                            <div className={classes.switchRoot}>
                              <Typography className='PreviewSwitcheLabels'>
                                Call Before Delivery
                              </Typography>
                            </div>
                          )}
                        </FormControl>
                      </div>
                    </div>
                    <br />
                    {CODAmount && (
                      <div className={classes.switchRoot}>
                        <Typography className='PreviewSwitcheLabels'>
                          COD Amount ($): {CODAmount}
                        </Typography>
                      </div>
                    )}
                    {DeclaredValue && (
                      <div className={classes.switchRoot}>
                        <Typography className='PreviewSwitcheLabels'>
                          Declared Value($): {DeclaredValue}
                        </Typography>
                      </div>
                    )}
                    {overlengthPickupSwitch && (
                      <div className={classes.switchRoot}>
                        <Typography className='PreviewSwitcheLabels'>
                          Overlength: {overLengthValue}
                        </Typography>
                      </div>
                    )}
                  </Box>
                </>
              )}
            <br />
            {(pickupRemarks || deliveryRemarks) && (
              <>
                <div className='OriginTitle'>Remarks</div>
                <hr />
                <Box
                  sx={{ color: 'rgba(0, 0, 0, 0.38)' }}
                  className='RemarksParent'
                >
                  {pickupRemarks && (
                    <div>
                      <Typography variant='caption' color='GrayText'>
                        Pickup Instructions:
                      </Typography>
                      <div
                        dangerouslySetInnerHTML={{ __html: pickupRemarks }}
                      />
                    </div>
                  )}
                  {deliveryRemarks && (
                    <div>
                      <Typography variant='caption' color='GrayText'>
                        Delivery Instructions:
                      </Typography>
                      <div
                        dangerouslySetInnerHTML={{ __html: deliveryRemarks }}
                      />
                    </div>
                  )}
                </Box>
              </>
            )}
          </Box>
        );
      }
    }
  };

  function handleCopy() {
    // Get the text field
    var copyText = document.getElementById('myInput');
    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices
    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText.value);
  }
  const handleDoneShipment = DoneStatus => {
    if (DoneStatus === 'booked') {
      history.push({
        pathname: '/manage-shipments',
        state: { selectedTab: 'Booked' }
      });
    }
    if (DoneStatus === 'InTransit') {
      history.push({ pathname: '/', state: { selectedTab: 'InTransit' } });
    }
    if (DoneStatus === 'Delivered') {
      history.push({ pathname: '/', state: { selectedTab: 'Delivered' } });
    }

    // history.push('/');
    // if (props?.location?.state?.disptachOpen !== 'Get Quote') {
    handleReset();
    setShipmentBooked(false);
    setShipmentDispatchMsg('');
    handleResetAllData();
    // }

    // if (props?.location?.state?.disptachOpen === 'Dispatch') {
    //   setOpenOtherCarrier(false);
    //   handleReset();
    //   handlePickupClose();
    //   setShipmentDispatchMsg('');
    //   handleResetAllData();
    // }
  };

  const handleCancelShipment = () => {
    // handlePickupClose();
    handleReset();
    setShipmentFailed(false);
    history.push('/');
  };

  const handleBackShipment = () => {
    setShipmentFailed(false);
    setShipmentDispatchFailed(false);
    handleReset();
  };
  const handleStep = (step, label) => () => {
    setActiveStep(step);
    setActiveTabLabel(label);
  };
  const stepsArray = () => {
    if (props?.location?.state?.disptachOpen === 'Get Quote') {
      const steps = ['Carrier Rates', 'Pickup Info', 'Preview'];
      return steps;
    } else {
      const steps = ['Shipment Info', 'Preview'];
      return steps;
    }
  };

  const handleNextButton = () => {
    if (stepsArray().length === 2) {
      console.log(
        props?.location?.state?.disptachOpen,
        'props?.location?.state?.disptachOpen'
      );
      if (
        props?.location?.state?.disptachOpen === 'Dispatch' &&
        !shipmentDispatchFailed
      ) {
        return 'Dispatch';
      } else if (
        props?.location?.state?.disptachOpen === 'PickUp' &&
        !shipmentDispatchFailed
      ) {
        return 'Confirm PickUp';
      } else {
        return 'Cancel';
      }
    }
    if (
      props?.location?.state?.disptachOpen === 'Get Quote' &&
      !shipmentFailed &&
      stepsArray().length === 3
    ) {
      return 'Confirm Pickup';
    }
    if (
      props?.location?.state?.disptachOpen === 'Get Quote' &&
      shipmentFailed &&
      stepsArray().length === 3
    ) {
      return 'Cancel';
    }
  };

  useEffect(() => { }, []);

  const calculateWidth = () => {
    // You can adjust any additional padding or margin as needed
    const padding = 10;
    const minWidth = 100; // Set a minimum width if needed

    const valueLength = pickupReferenceNumber.length;
    const calculatedWidth = valueLength * 10 + padding;

    // Ensure the width is not below the minimum
    return Math.max(calculatedWidth, minWidth);
  };

  const handlePhoneNumberChange = event => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and retain formatting
    const formattedValue = inputValue
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    setDriverNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, driverNumber: null });
    } else {
      setPhoneNumberValidError(true);
      errors['driverNumber'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };
  const ShipmentContent = () => {
    if (pickUpLoader) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <CircularProgress />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Box>
      );
    }
    if (shipmentFailed || shipmentDispatchFailed) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <WarningAmberIcon style={{ fontSize: 150, color: '#D32F2F' }} />{' '}
          <br />
          <br />
          <Typography variant='h4'>Error</Typography>
          <br />
          <div>{shipmentFailedMsg}</div>
          <br />
          <br />
        </Box>
      );
    }
    if (shipmentDispatchMsg && !shipmentDispatchFailed) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <CheckCircleIcon style={{ fontSize: 150, color: '#2E7D32' }} />{' '}
          <Typography variant='h4'>
            {shipmentDispatchMsg === 'InTransit'
              ? 'Your shipment has been picked-up and saved in-transit'
              : 'Your shipment has been picked-up and saved in delivered'}
          </Typography>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* <br /> */}
          <Button
            sx={{
              width: '25%',
              height: 40
            }}
            variant='contained'
            onClick={() => handleDoneShipment(shipmentDispatchMsg)}
          >
            Done
          </Button>
        </Box>
      );
    }
    if (shipmentBooked) {
      return (
        <>
          <Box sx={{ textAlign: 'center' }}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <CheckCircleIcon style={{ fontSize: 150, color: '#2E7D32' }} />{' '}
            <Typography variant='h4'>{pickupSuccessRemarks}</Typography>
            <br />
            <div className='ParentCopyNmbBook'>
              <div className='OuterCopyNmbBook'>
                Reference Number:
                <input
                  style={{ width: `${calculateWidth()}px` }}
                  className='CopyNmbBook'
                  id='myInput'
                  value={pickupReferenceNumber}
                  readOnly
                />
                <ContentCopyIcon
                  onClick={() => handleCopy()}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button
              sx={{
                width: '25%',
                height: 40
              }}
              variant='contained'
              onClick={() => handleDoneShipment('booked')}
            >
              Done
            </Button>
          </Box>
        </>
      );
    } else {
      return (
        <Box
          sx={
            props?.location?.state?.disptachOpen === 'Get Quote' &&
              activeStep === 0
              ? { height: '81vh', width: '100%' }
              : props?.location?.state?.disptachOpen === 'Dispatch'
                ? { height: '61vh', width: '100%' }
                : { height: '75vh', width: '100%' }
          }
        >
          <Stepper
            // className='PickupStepperParent'
            activeStep={activeStep}
          // alternativeLabel
          // nonLinear
          >
            {stepsArray().map((label, index) => {
              const stepProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    color='inherit'
                    onClick={handleStep(index, label)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {handleStepperContent(activeStep, activeTabLabel)}
        </Box>
      );
    }
  };

  const isWeekend = date => {
    const [year, month, day] = date.split('-'); // Assuming the date format is 'YYYY-MM-DD'
    const selectedDate = new Date(year, month - 1, day); // Construct date object without time
    const dayOfWeek = selectedDate.getDay();
    return dayOfWeek === 6 || dayOfWeek === 0; // Saturday = 6, Sunday = 0
  };
  const isPastDate = date => {
    const [year, month, day] = date.split('-'); // Assuming the date format is 'YYYY-MM-DD'
    const selectedDate = new Date(Date.UTC(year, month - 1, day)); // Create the date object in UTC

    const today = new Date(); // Get the current date
    const todayUTC = new Date(
      Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())
    ); // Normalize to midnight UTC

    return selectedDate < todayUTC; // Compare the dates
  };

  const handleDateChange = e => {
    const newDate = e.target.value;

    // If it's a weekend, don't update the date
    if (isPastDate(newDate)) {
      setValidationErrors({
        originDate: 'Past dates are not valid as pickup date'
      });
    } else if (isWeekend(newDate)) {
      setValidationErrors({
        originDate: 'Weekends are not valid as pickup date'
      });
    } else {
      setValidationErrors({});
      setOriginDate(newDate); // Update the date if it's not a weekend
    }
  };

  return (
    <Grid item xs={8} sm={12} lg={12} xl={12}>
      <Box sx={{ minHeight: '90vh', width: '90vw', overflow: 'hidden' }}>
        <ToastContainer
          style={
            // isMobile
            //   ? { width: '100%', display: 'flex', justifyContent: 'center' }
            //   :
            { width: 'auto' }
          }
          position='top-center'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Paper className='Grid_Item_Get_Quote'>
          <Dialog
            className='InnerDialog'
            open={openOtherCarrier}
            onClose={handleOtherCarrierClose}
          >
            <DialogContent>
              <DialogContentText>
                <TextField
                  variant='standard'
                  error={Boolean(validationErrors['otherCarrierName'])}
                  helperText={validationErrors['otherCarrierName']}
                  value={otherCarrierName}
                  onChange={e => {
                    setOtherCarrierName(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      otherCarrierName: null
                    });
                  }}
                  sx={{ width: '100%', mt: 5 }}
                  size='small'
                  label='Carrier Name'
                />
                <TextField
                  variant='standard'
                  error={Boolean(validationErrors['truckNumber'])}
                  helperText={validationErrors['truckNumber']}
                  value={truckNumber}
                  onChange={e => {
                    setTruckNumber(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      truckNumber: null
                    });
                  }}
                  sx={{ width: '100%', mt: 5 }}
                  size='small'
                  label='Truck Number'
                />
                <TextField
                  variant='standard'
                  error={Boolean(validationErrors['driverName'])}
                  helperText={validationErrors['driverName']}
                  value={driverName}
                  onChange={e => {
                    setDriverName(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      driverName: null
                    });
                  }}
                  sx={{ width: '49%', mt: 5 }}
                  size='small'
                  label='Driver Name'
                />
                <TextField
                  variant='standard'
                  error={Boolean(validationErrors['driverNumber'])}
                  helperText={validationErrors['driverNumber']}
                  value={driverNumber}
                  onChange={handlePhoneNumberChange}
                  sx={{ width: '49%', margin: '40 0 0 8' }}
                  size='small'
                  label='Driver Number'
                  type='text'
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                sx={{ width: '49%' }}
                variant='outlined'
                onClick={handleOtherCarrierClose}
              >
                Cancel
              </Button>
              <Button
                sx={{ width: '49%' }}
                variant='contained'
                onClick={handleSaveOtherCarrier}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>
          <div>
            {ShipmentContent()}
            {!shipmentDispatchMsg && !shipmentBooked && (
              <div
                className={
                  props?.location?.state?.disptachOpen === 'Get Quote'
                    ? activeStep === 0
                      ? 'NextBtnRates_BookShipmet'
                      : 'NextBtnRates'
                    : 'NextBtnDiv'
                }
              >
                {activeStep !== 0 && (
                  <Button
                    sx={{ mr: 2 }}
                    onClick={
                      shipmentFailed || shipmentDispatchFailed
                        ? handleBackShipment
                        : handleBack
                    }
                    // className='BtnColor'
                    variant='outlined'
                  >
                    <ChevronLeftIcon sx={{ m: 0 }} className='PrevBtnIcon' />{' '}
                    Previous
                  </Button>
                )}

                <Button
                  // sx={{ mt: 4 }}
                  disabled={
                    props?.location?.state?.disptachOpen === 'Get Quote' &&
                    (selectedIndex === null || !DisableReloadButton())
                  }
                  onClick={
                    shipmentFailed || shipmentDispatchFailed
                      ? () => handleCancelShipment()
                      : () => handleNext(activeStep)
                  }
                  // className={
                  //   activeStep === 0 &&
                  //   props?.location?.state?.disptachOpen === 'Get Quote'
                  //     ? 'BtnColor btn-schedule-pickup'
                  //     : 'BtnColor'
                  // }
                  variant='contained'
                  startIcon={activeStep === 0 ? <CheckCircleIcon /> : ''}
                >
                  {' '}
                  {activeStep === stepsArray().length - 1
                    ? handleNextButton()
                    : shipmentFailed || shipmentDispatchFailed
                      ? 'Cancel'
                      : props?.location?.state?.disptachOpen === 'Get Quote' &&
                        activeStep === 0
                        ? 'Book Shipment'
                        : 'Next'}
                  {!shipmentFailed ||
                    (shipmentDispatchFailed && (
                      <ChevronRightIcon className='NextBtnIcon' />
                    ))}
                </Button>
              </div>
            )}
          </div>
        </Paper>
      </Box>
    </Grid>
  );
}
