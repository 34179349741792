import { Box, Avatar, AvatarGroup, CircularProgress } from '@mui/material';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import {
  GetAgencyLocations,
  GetCarriers,
  UpdateShipmentLocation
} from '../../apis/Agency';
import { useSelector } from 'react-redux';
import {
  useHistory,
  useLocation
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton
} from 'material-react-table';
import { Button, IconButton } from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import './CreateUser.css';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { ErrorToast, successToast } from '../../components/Toasts';
import { ToastContainer } from 'react-toastify';
import { base_logo_url, base_url } from '../../apis/constants';
import { MetaTags } from 'react-meta-tags';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';

function Locations(props) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const orderNumber = searchParams.get('shipmentId');

  const [shipmentsLoader, setShipmentsLoader] = useState(true);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const history = useHistory();
  const [agencyLocationsArray, setAgencyLocationsArray] = useState([]);
  const [shipCity, setShipCity] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [userPermissionsArr, setUserPermissionsArr] = useState(null);
  const [assignLocLoader, setAssignLocLoader] = useState(false);
  const [assignLocIndex, setAssignLocIndex] = useState(null);
  const [filteredLocation, setFilteredLocation] = useState('');
  const UserIsAdmin = useSelector(state => state.userDetails.isAdmin);
  const userRolePermissions = useSelector(
    state => state.userDetails.userRolePermissionsArr
  );
  const params = new URLSearchParams(location.search);
  const decodedLocation = decodeURIComponent(params.get('location'));
  const locationTab = params.get('tab');
  // Get the value of shipmentId parameter

  useEffect(() => {
    if (decodedLocation && decodedLocation !== 'null') {
      const splitedLocName = decodedLocation.split(': ');
      const splitedTabName = decodedLocation.split('?');

      const filteredTableLoc = agencyLocationsArray.filter(
        loc => loc.name === splitedLocName[0]
      );
      console.log(splitedTabName[1], 'splitedTabName');
      if (filteredTableLoc.length > 0) {
        handleUpdateLocation(
          filteredTableLoc[0],
          'quickQoute',
          splitedTabName[1]
        );
      }
    }
  }, [decodedLocation, agencyLocationsArray]);
  const isMobile = useIsMobile();

  useEffect(() => {
    const filteredCarrierPermissions = userRolePermissions?.filter(
      items => items.permissionName === 'Locations'
    );
    setUserPermissionsArr(filteredCarrierPermissions);
  }, [userRolePermissions]);

  const handleSuccesGetCarriers = ApiRes => {
    const CarrierArray = ApiRes?.data?.data.filter(items => items.active);
    // setCarriersLoader(false);
    const Temp = [];
    for (let index = 0; index < CarrierArray.length; index++) {
      const CarrierName = CarrierArray[index].name;
      Temp.push(CarrierName);
    }
    setCarriers(CarrierArray);
  };

  // useEffect(() => {
  //   GetCarriers(AgencyID)
  //     .then(res => res.status === 200 && handleSuccesGetCarriers(res))
  //     .catch(err => console.log(err, 'err'));
  // }, []);

  const handleSuccessAgencyLocations = res => {
    setShipmentsLoader(false);
    setAgencyLocationsArray(res?.data?.data.reverse());
  };

  const handleErrorAgencyLocations = err => {
    setShipmentsLoader(false);
  };
  useEffect(() => {
    GetAgencyLocations(AgencyID, 0)
      .then(res => res.status === 200 && handleSuccessAgencyLocations(res))
      .catch(err => handleErrorAgencyLocations(err));
  }, []);

  const handleUpdateLocation = (data, type, tab) => {
    // history.push('/add-locations', data, type);
    history.push({
      pathname: '/add-locations',
      state: { data, type, tab }
    });
  };

  const imageStyle = {
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#333'
  };
  const handleOpenAddUpdateLocReq = (rowData, row) => {
    setAssignLocIndex(row.index);
    setAssignLocLoader(true);
    const locatioObj = {
      shipmentId: parseInt(props?.location?.state?.shipmentId),
      locationId: rowData?.id
    };

    UpdateShipmentLocation(locatioObj)
      .then(res => {
        res.status === 200 && setAssignLocLoader(false);
        successToast('Location Updated Successfully!');
        setTimeout(() => {
          history.push({
            pathname: '/manage-shipments'
            // search: `?shipmentId=${orderNumber}`
          });
        }, 1000);
      })
      .catch(err => {
        setAssignLocLoader(false);
        ErrorToast(err?.message);
      });
  };
  const mncolumns = [
    {
      accessorKey: 'name',
      // accessorFn: row => `${row.firstName} ${row.lastName}`,
      id: 'name',
      header: 'Location Name',
      size: 70,
      Cell: ({ cell, row }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
          {!isMobile &&
            ((userPermissionsArr &&
              userPermissionsArr.filter(permissions => permissions.level4)
                .length) ||
              userPermissionsArr.filter(permissions => permissions.level2)
                .length ||
              UserIsAdmin) && (
              <div
                className={isMobile ? '' : 'table-row-buttons-users py-2'}
                style={{ marginLeft: -60 }}
              >
                <button
                  onClick={event => handleUpdateLocation(row.original)}
                  class=' bg-white border-0 p-2 fw-bold'
                  style={{
                    color: '#3361FF',
                    borderRadius: '4px',
                    marginTop: 5
                  }}
                >
                  <Tooltip title='View / Update'>
                    <svg
                      className='MX-1'
                      width='17'
                      height='17'
                      viewBox='0 0 13 13'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M1.33333 11.1693H2.26667L8.01667 5.41927L7.08333 4.48594L1.33333 10.2359V11.1693ZM10.8667 4.4526L8.03333 1.6526L8.96667 0.719271C9.22222 0.463715 9.53611 0.335938 9.90833 0.335938C10.2806 0.335938 10.5944 0.463715 10.85 0.719271L11.7833 1.6526C12.0389 1.90816 12.1722 2.21649 12.1833 2.5776C12.1944 2.93872 12.0722 3.24705 11.8167 3.5026L10.8667 4.4526ZM9.9 5.43594L2.83333 12.5026H0V9.66927L7.06667 2.6026L9.9 5.43594Z'
                        fill='#3361FF'
                      />
                    </svg>
                  </Tooltip>
                </button>
                {props?.location?.state?.shipmentId &&
                  (assignLocLoader && assignLocIndex === row.index ? (
                    <CircularProgress size={20} sx={{ mx: 1, mb: -0.6 }} />
                  ) : (
                    <button
                      onClick={event =>
                        handleOpenAddUpdateLocReq(row.original, row)
                      }
                      class=' bg-white border-0 p-1 fw-bold mx-2'
                      style={{ color: '#3361FF', borderRadius: '4px' }}
                    >
                      <Tooltip title='Assign Location'>
                        <AddLocationIcon />
                      </Tooltip>
                    </button>
                  ))}
              </div>
            )}
        </>
      )
    },
    {
      accessorKey: 'streetLines',
      id: 'streetLines',
      header: 'Location Address',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'locationCarriers',
      id: 'locationCarriers',
      header: 'Assigned Carrier',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <span class='mx-1'>{cell.getValue()}</span> */}
            <div style={{ display: 'flex' }}>
              <AvatarGroup sx={{}} spacing='small' max={5}>
                {cell.getValue().map((items, index) => (
                  <Avatar
                    key={index}
                    sx={{
                      bgcolor: 'ButtonFace',
                      objectFit: 'contain'
                    }}
                    imgProps={{
                      style: {
                        height: 15
                      }
                    }}
                    src={base_logo_url + items.logo}
                  />
                ))}
              </AvatarGroup>
            </div>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'locationName',
      id: 'locationName',
      header: 'Assigned User',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span class='mx-1'>{cell.getValue()}</span>
          </Box>
        </>
      )
    },
    {
      accessorKey: 'active',
      id: 'active',
      header: 'Location Status',
      size: 70,
      Cell: ({ cell }) => (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {cell.getValue() ? (
              <span class='mx-1 text-success'>
                ACTIVE{' '}
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.99719 4.3725L11.3747 6.75L12.4322 5.6925L8.99719 2.25L5.55469 5.6925L6.61969 6.75L8.99719 4.3725ZM8.99719 13.6275L6.61969 11.25L5.56219 12.3075L8.99719 15.75L12.4397 12.3075L11.3747 11.25L8.99719 13.6275Z'
                    fill='#2E7D32'
                  />
                </svg>
              </span>
            ) : (
              <span class='mx-1 text-muted'>
                INACTIVE{' '}
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M8.99719 4.3725L11.3747 6.75L12.4322 5.6925L8.99719 2.25L5.55469 5.6925L6.61969 6.75L8.99719 4.3725ZM8.99719 13.6275L6.61969 11.25L5.56219 12.3075L8.99719 15.75L12.4397 12.3075L11.3747 11.25L8.99719 13.6275Z'
                    fill='black'
                    fill-opacity='0.6'
                  />
                </svg>
              </span>
            )}
          </Box>
        </>
      )
    }
  ];

  return (
    <div class='mt-2 mx-3' style={{ border: '' }}>
      <MetaTags>
        <title>
          {' '}
          {base_url === 'https://beta.api.shiptechpro.com/'
            ? 'Locations | Shiptechpro'
            : 'Locations | TMS Shipping'}
        </title>
      </MetaTags>
      <ToastContainer
        style={
          isMobile
            ? { width: '100%', display: 'flex', justifyContent: 'center' }
            : { width: 'auto' }
        }
        position='top-center'
        autoClose={6800}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <MaterialReactTable
        enableRowActions={isMobile ? true : false}
        renderRowActions={({ table, row }) =>
          ((userPermissionsArr &&
            userPermissionsArr.filter(permissions => permissions.level4)
              .length) ||
            userPermissionsArr.filter(permissions => permissions.level2)
              .length ||
            UserIsAdmin) && (
            <div>
              <button
                onClick={event => handleUpdateLocation(row.original)}
                class=' border-0 p-2 fw-bold'
                style={{
                  color: '#3361FF',
                  borderRadius: '4px',
                  marginTop: 5
                }}
              >
                <Tooltip title='View / Update'>
                  <svg
                    className='MX-1'
                    width='17'
                    height='17'
                    viewBox='0 0 13 13'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1.33333 11.1693H2.26667L8.01667 5.41927L7.08333 4.48594L1.33333 10.2359V11.1693ZM10.8667 4.4526L8.03333 1.6526L8.96667 0.719271C9.22222 0.463715 9.53611 0.335938 9.90833 0.335938C10.2806 0.335938 10.5944 0.463715 10.85 0.719271L11.7833 1.6526C12.0389 1.90816 12.1722 2.21649 12.1833 2.5776C12.1944 2.93872 12.0722 3.24705 11.8167 3.5026L10.8667 4.4526ZM9.9 5.43594L2.83333 12.5026H0V9.66927L7.06667 2.6026L9.9 5.43594Z'
                      fill='#3361FF'
                    />
                  </svg>
                </Tooltip>
              </button>
              {props?.location?.state?.shipmentId &&
                (assignLocLoader && assignLocIndex === row.index ? (
                  <CircularProgress size={20} sx={{ mx: 1, mb: -0.6 }} />
                ) : (
                  <button
                    onClick={event =>
                      handleOpenAddUpdateLocReq(row.original, row)
                    }
                    class=' bg-white border-0 p-1 fw-bold mx-2'
                    style={{ color: '#3361FF', borderRadius: '4px' }}
                  >
                    <Tooltip title='Assign Location'>
                      <AddLocationIcon />
                    </Tooltip>
                  </button>
                ))}
            </div>
          )
        }
        state={{ isLoading: shipmentsLoader }}
        columns={mncolumns}
        data={agencyLocationsArray}
        enableRowSelection
        muiTableBodyRowProps={({ row }) => ({})}
        // enableMultiSort

        muiTableHeadCellFilterTextFieldProps={{ placeholder: '' }}
        muiTableContainerProps={{
          sx: { maxHeight: '70vh', minHeight: '70vh', mb: 0 }
        }}
        initialState={{
          // showColumnFilters: true,
          density: 'compact'
        }}
        positionToolbarAlertBanner='bottom'
        renderToolbarInternalActions={({ table }) => (
          <div>
            <MRT_ToggleGlobalFilterButton table={table} title='Search' />

            <IconButton
              title='Print'
              // onClick={() => {
              //   handlePrint();
              // }}
            >
              <LocalPrintshopOutlinedIcon />
            </IconButton>

            <MRT_ToggleFiltersButton table={table} title='Filters' />

            <MRT_ToggleDensePaddingButton table={table} title='Spacing' />
            <MRT_ShowHideColumnsButton
              table={table}
              title='Show/Hide Columns'
            />
            <MRT_FullScreenToggleButton table={table} title='Fullscreen' />
            <Tooltip title='Clear All Filters' arrow>
              <IconButton
                onClick={() => {
                  table.resetColumnFilters(true);
                  table.setShowColumnFilters(false);
                }}
              >
                <FilterAltOffIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
        renderTopToolbarCustomActions={({ table }) => {
          if (
            (userPermissionsArr &&
              userPermissionsArr.filter(permissions => permissions.level3)
                .length) ||
            UserIsAdmin
          ) {
            return (
              <div style={{ display: 'flex', gap: '0.5rem' }}>
                <Button
                  onClick={() =>
                    props?.location?.state?.shipmentId
                      ? history.push({
                          pathname: '/add-locations',
                          search: `?shipmentId=${orderNumber}`
                        })
                      : history.push('/add-locations')
                  }
                  className='m-2'
                  style={
                    isMobile
                      ? {
                          fontSize: 10,
                          color: '#3361FF',
                          backgroundColor: '#E4F3FD'
                        }
                      : { color: '#3361FF', backgroundColor: '#E4F3FD' }
                  }
                  variant='contained'
                >
                  ADD{' '}
                  <svg
                    className='ms-2'
                    width='11'
                    height='10'
                    viewBox='0 0 11 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M4.94531 9.72396V5.72396H0.945312V4.39062H4.94531V0.390625H6.27865V4.39062H10.2786V5.72396H6.27865V9.72396H4.94531Z'
                      fill='#3361FF'
                    />
                  </svg>
                </Button>
              </div>
            );
          }
        }}
      />
    </div>
  );
}

export default Locations;
