import React, { useEffect, useRef, useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import axios from 'axios';
import { Alert, Box, CircularProgress } from '@mui/material';
import { Grid } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Height } from '@material-ui/icons';

const Reports = () => {
  const [embedTokenState, setEmbedTokenState] = useState('');
  const [groupIdState, setGroupIdState] = useState('');
  const [reportIDState, setReportID] = useState('');
  const [getReportsReqErr, setGetReportsReqErr] = useState(false);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);

  const GetAuthToken = () => {
    return localStorage.getItem('UserAuthToken');
  };

  const accessTokenRequest = () => {
    const authToken = GetAuthToken();
    if (AgencyID) {
      axios
        .get(`https://a.maxease.net/get-tms-report/?agency_Id=${AgencyID}`)
        .then(res => {
          res.status === 200 && setEmbedTokenState(res.data.data.embedToken);
          setReportID(res.data.data.reportID);
          setGetReportsReqErr(false);
          setGroupIdState(res.data.data.groupID);
        })
        .catch(err => {
          setGetReportsReqErr(true);
          console.log(err, 'poer err -------->>');
        });
    }
  };

  useEffect(() => {
    accessTokenRequest();
  }, []);
  // Create a ref to store the embedded report component
  const reportRef = useRef(null);

  return embedTokenState && groupIdState && reportIDState ? (
    <div
      style={{
        marginTop: -63,
        maxHeight: '100vh',
        minHeight: '100vh',
        overflow: 'scroll'
      }}
    >
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report, and create
          id: reportIDState,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportIDState}&groupId=${groupIdState}`,
          accessToken: embedTokenState,
          tokenType: models.TokenType.Embed, // Use models.TokenType.Embed
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            background: models.BackgroundType.Transparent // Use models.BackgroundType.Transparent
          }
        }}
        eventHandlers={
          new Map([
            ['loaded', () => console.log('Report loaded')],
            ['rendered', () => console.log('Report rendered')],
            ['error', event => console.log(event.detail)],
            ['visualClicked', () => console.log('visual clicked')],
            ['pageChanged', event => console.log(event)]
          ])
        }
        cssClassName='embed-container'
        getEmbeddedComponent={embeddedReport => {
          reportRef.current = embeddedReport; // Set the report ref
          console.log('Report embedded:', embeddedReport);
        }}
        style={{
          height: '600px', // Set height of the report
          width: '100%' // Set width to 100% of the parent container
        }}
      />
    </div>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginY: '20%'
      }}
    >
      {getReportsReqErr ? (
        <Alert sx={{ fontSize: 16 }} severity='error'>
          Report not found from server side!
        </Alert>
      ) : (
        <CircularProgress size={40} />
      )}
    </Box>
  );
};

export default Reports;
