// tms staging URL
// export const base_url = 'https://api.tms.dynamicwerx.com/';
// export const base_logo_url = 'https://tms.dynamicwerx.com/';

// beta staging URL
// export const base_url = 'https://beta.api.dynamicwerx.com/';
// export const base_logo_url = 'https://beta.tms.dynamicwerx.com/';

//shiptechpro URL
export const base_url = 'https://api.tms.shiptechpro.com/';
export const base_logo_url = 'https://tms.shiptechpro.com/';
