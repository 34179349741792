import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Toast,
  ToastBody,
  ToastHeader
} from 'reactstrap';
import MetaTags from 'react-meta-tags';

//Import Image

import { Form } from 'react-bootstrap';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import { Confirmation } from "../../apis/User";
import { TheTailSpinner } from '../../components/Spinners';
import { successToast } from '../../components/Toasts';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ConfirmationCode = () => {
  const [confirmation, setConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  let history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
  };

  return (
    <React.Fragment>
      <ToastContainer
        style={{ width: 'auto' }}
        position='top-center'
        autoClose={700}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div>
        <div className='main-content'>
          <div className='page-content'>
            <MetaTags>
              <title>Enter Confirmation Code</title>
            </MetaTags>
            <section className='bg-auth'>
              <Container>
                <Row className='justify-content-center'>
                  <Col xl={10} lg={12}>
                    <Card className='auth-box'>
                      <Row className='g-0'>
                        <Col lg={6} className='text-center'>
                          <CardBody className='p-4'>
                            <Link to='/'>
                              {/* <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                              />
                              <img
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                              /> */}
                            </Link>
                            <div className='mt-5'>
                              {/* <img
                                src={signInImage}
                                alt=""
                                className="img-fluid"
                              /> */}
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className='auth-content p-5 h-100 text-white'>
                            <div className='w-100'>
                              <div className='text-center mb-4'>
                                <h5>Enter Confirmation Code !</h5>
                                <p className='text-white-70'>
                                  Sign in to continue to Jobcy.
                                </p>
                              </div>
                              <Form className='auth-form'>
                                <div className='mb-3'>
                                  <label
                                    htmlFor='confirmationInput'
                                    className='form-label'
                                  >
                                    ConfirmationCode
                                  </label>
                                  <Input
                                    type='text'
                                    className='form-control'
                                    id='confirmationInput'
                                    placeholder='Confirmation Code'
                                    value={confirmation}
                                    onChange={e =>
                                      setConfirmation(e.target.value)
                                    }
                                    required
                                  />
                                </div>

                                <div className='text-center'>
                                  <button
                                    // type="submit"
                                    className='btn btn-white btn-hover w-100'
                                    onClick={e => {
                                      handleSubmit(e);
                                    }}
                                  >
                                    <div>
                                      <span style={{ display: 'inline-block' }}>
                                        Submit
                                      </span>
                                      <span
                                        style={{
                                          display: 'inline-block',
                                          marginLeft: '50px'
                                        }}
                                      ></span>
                                    </div>
                                  </button>
                                </div>
                              </Form>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConfirmationCode;
