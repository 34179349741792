import { useState, React, useRef, useEffect } from 'react';
import {
  AppBar,
  Card,
  CircularProgress,
  Grid,
  StepButton,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import {
//   BookTLShipment,
//   DispatchShipment,
//   GetQuote,
//   StartTLQuote
// } from '../../apis/AgencyWithoutAuthHeaders';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import './PickupShipment.css';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Autocomplete, Button, TextField } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useHistory } from 'react-router-dom';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SwipeableViews from 'react-swipeable-views';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Col, Row } from 'react-bootstrap';
import { ErrorToast } from '../../components/Toasts';
import NoLogo from '../../assets/carrier logos/No Logo.png';
import ShipmentTabPanel from '../GetRate/TabPanel';
import {
  BookTLShipment,
  DispatchShipment,
  GetActiveCarriers,
  GetQuote,
  StartTLQuote
} from '../../apis/Agency';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { base_logo_url } from '../../apis/constants';
import { useIsMobile } from '../../components/IsMobileHook/IsMobileHook';
import { Padding } from '@mui/icons-material';

export default function BookShipmentReviewTL(props) {
  let UpdatedStoredServices = useRef(null);
  const ApiData = props?.location?.state?.apiData;
  const previousQuoteId = JSON.parse(props?.location?.state?.pickupRowData);

  const history = useHistory();
  const PreviousProps =
    props?.location?.state?.pickupRowData !== undefined &&
    JSON.parse(props?.location?.state?.pickupRowData);
  const AgencyID = useSelector(state => state.userDetails.agencyLoginID);
  const CarrierRateData = useSelector(state => state.getCarrierRates);
  const [pickupNumber, setPickupNumber] = useState(
    PreviousProps?.pickupNumber ? PreviousProps?.pickupNumber : ''
  );
  const [proNumber, setProNumber] = useState('');
  const [refOrQuoteNmb, setRefOrQuoteNmb] = useState(
    PreviousProps?.quoteNumber ? PreviousProps?.quoteNumber : ''
  );
  const [open, setOpen] = useState(false);
  const [openOtherCarrier, setOpenOtherCarrier] = useState(false);
  const [otherCarrierName, setOtherCarrierName] = useState('');
  const [truckNumber, setTruckNumber] = useState('');
  const [driverName, setDriverName] = useState('');
  const [carrierName, setCarrierName] = useState(null);
  const [driverNumber, setDriverNumber] = useState('');
  const [phoneNumberValidError, setPhoneNumberValidError] = useState(false);

  const [originDate, setOriginDate] = useState(new Date());
  const [destinationDate, setDestinationDate] = useState(new Date());
  const [originOpenTimeValue, setOriginOpenTimeValue] = useState(
    dayjs('2024-02-17T08:00').format('HH:mm') // Extract time part
  );
  const [originOpenTimeFormate, setOriginOpenTimeFormate] = useState('');
  const [originCloseTimeValue, setOriginCloseTimeValue] = useState(
    dayjs('2024-02-17T16:00').format('HH:mm') // Extract time part
  );
  const [originCloseTimeFormate, setOriginCloseTimeFormate] = useState('');
  const [destinationOpenTimeValue, setDestinationOpenTimeValue] = useState(
    dayjs('2024-02-17T09:00').format('HH:mm') // Extract time part
  );
  const [destinationOpenTimeFormate, setDestinationOpenTimeFormate] =
    useState('');
  const [destinationCloseTimeValue, setDestinationCloseTimeValue] = useState(
    dayjs('2024-02-17T05:00').format('HH:mm') // Extract time part
  );
  const [destinationCloseTimeFormate, setDestinationCloseTimeFormate] =
    useState('');
  const [shipmentBooked, setShipmentBooked] = useState(false);
  const [pickUpLoader, setPickUpLoader] = useState(false);
  const [shipmentDispatchMsg, setShipmentDispatchMsg] = useState('');
  const [shipmentDispatchFailed, setShipmentDispatchFailed] = useState(false);
  const [pickupSuccessRemarks, setPickupSuccessRemarks] = useState('');
  const [shipmentFailed, setShipmentFailed] = useState(false);
  const [shipmentFailedMsg, setShipmentFailedMsg] = useState('');
  const ClassArray = [
    '50',
    '55',
    '60',
    '65',
    '70',
    '77.5',
    '85',
    '92.5',
    '100',
    '110',
    '125',
    '150',
    '175',
    '200',
    '250',
    '300',
    '400',
    '500'
  ];
  const [value, setValue] = useState(0);
  const [tabConfirmValue, setTabConfirmValue] = useState(0);
  const [tabChanged, setTabChanged] = useState(false);
  const theme = useTheme();
  const [finalCommodityInfoApi, setFinalCommodityInfoApi] = useState([]);
  const [totalWeighSum, setTotalWeightSum] = useState('');
  const UnitTypeArr = ['Pallet', 'Tote', 'Box', 'Drum'];

  const [newAddedClassRow, setNewAddedClassRow] = useState([
    PreviousProps?.carrierApiNames === 'carrierApiNames'
      ? {
          WeightLBS: CarrierRateData?.commodityInfo?.[0]?.weight?.value,
          Class: CarrierRateData?.commodityInfo?.[0]?.freightClass,
          NMFCnumber: CarrierRateData?.commodityInfo?.[0]?.nmfc
        }
      : {
          WeightLBS: PreviousProps?.pickupRowData?.details?.[0]?.netWeight,
          Class: CarrierRateData?.commodityInfo?.[0]?.freightClass,
          NMFCnumber: CarrierRateData?.commodityInfo?.[0]?.nmfc
        }
  ]);

  const [totalLBSWeightDimensions, setTotalLBSWeightDimensions] = useState('');
  const [totalLBSWeightClass, setTotalLBSWeightClass] = useState('');
  const handlePushAddedCommodityInfo = TotalWeightSum => {
    let CommodityInfoApi = [
      {
        freightClass: newAddedClassRow[0].Class,
        packaging: 'Pallet',
        pieces: 1,
        weight: {
          units: 'lbs',
          value: TotalWeightSum ? TotalWeightSum : 0
        },
        nmfc: newAddedClassRow[0].NMFCnumber,
        dimensions: {
          length: 0,
          width: 0,
          height: 0,
          dimensionsUom: 'cm',
          isStackable: false
        }
      }
    ];

    setFinalCommodityInfoApi(CommodityInfoApi);
  };
  const [BOLNumber, setBOLNumber] = useState('');
  const [PoRefNumber, setPoRefNumber] = useState('');
  const [deliveryRemarks, setDeliveryRemarks] = useState(
    'Test Delivery Instructions'
  );
  const [pickupRemarks, setPickupRemarks] = useState(
    'Test Pickup Instructions'
  );
  const [overLengthValue, setOverLengthValue] = useState('');
  const [CODAmount, setCODAmount] = useState('');
  const [DeclaredValue, setDeclaredValue] = useState('');
  const [hazmatSwitch, setHazmatSwitch] = useState(false);
  const [insidePickupSwitch, setInsidePickupSwitch] = useState(false);
  const [liftgatePickupSwitch, setLiftgatePickupSwitch] = useState(false);
  const [residentialPickupSwitch, setResidentialPickupSwitch] = useState(false);
  const [secureAccesPickupSwitch, setSecureAccesPickupSwitch] = useState(false);
  const [limitedPickupAccessSwitch, setLimitedPickupAccessSwitch] =
    useState(false);
  const [freezeProtectionPickupSwitch, setFreezeProtectionPickupSwitch] =
    useState(false);
  const [overlengthPickupSwitch, setOverlengthPickupSwitch] = useState(false);
  //delivery switch states
  const [insideDeliverySwitch, setInsideDeliverySwitch] = useState(false);
  const [liftgateDeliverySwitch, setLiftgateDeliverySwitch] = useState(false);
  const [residentialDeliverySwitch, setResidentialDeliverySwitch] =
    useState(false);
  const [limitedDeliverySwitch, setLimitedDeliverySwitch] = useState(false);
  const [secureAccesDeliverySwitch, setSecureAccesDeliverySwitch] =
    useState(false);
  const [
    callBeforeDeliveryServicesSwitch,
    setCallBeforeDeliveryServicesSwitch
  ] = useState(false);

  const [reloadQuote, setReloadQuote] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCarrierIDQuoteRates, setSelectedCarrierIDQuoteRates] =
    useState('');
  const [selectedPickupdate, setSelectedPickupdate] = useState('');
  const [selectedDeliveryDate, setSelectedDeliveryDate] = useState('');
  const [selectedCarrierQuotaionID, setSelectedCarrierQuotaionID] =
    useState('');
  const [TLdate, setTLDate] = useState('');
  const [selectedCarrierNameQuoteRates, setSelectedCarrierNameQuoteRates] =
    useState('');

  const [carriers, setCarriers] = useState([]);
  const [selectedCarriersIdArray, setSelectedCarriersIdArray] = useState([]);
  const [carriersFinalArray, setCarriersFinalArray] = useState([]);
  const [enableNext, setEnableNext] = useState(false);
  const [TLcarriers, setTLcarriers] = useState([]);
  const [TankerCarriers, setTankerCarriers] = useState([]);
  const [dryVanCarriers, setDryVanCarriers] = useState([]);
  const [reeferCarriers, setReeferCarriers] = useState([]);
  const [flatBedCarriers, setflatBedCarriers] = useState([]);
  const [sprinterCarriers, setSprinterCarriers] = useState([]);
  const [boxTruckCarriers, setBoxTruckCarriers] = useState([]);
  const [railIntermodalCarriers, setRailIntermodalCarriers] = useState([]);
  const [pickupInstContent, setPickupInstContent] = useState('');
  const [allCarriersChecked, setAllCarriersChecked] = useState(false);
  const [TLQuoteId, setTLQuoteId] = useState(
    previousQuoteId?.quoteId ? previousQuoteId?.quoteId : null
  );
  const handlePickupInstEditorChange = value => {
    setPickupInstContent(value);
  };
  const [dilveryInstContent, setDilveryInstContent] = useState('');
  const handleDeliveryInstEditorChange = value => {
    setDilveryInstContent(value);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [ApiFailed, setApiFailed] = useState(false);
  const [ApiErrorMessage, setApiErrorMessage] = useState('');
  const [hasEffectRun, setHasEffectRun] = useState(false);
  const [tabChangeConfrim, setTabChangeConfrim] = useState(false);
  const [tabChangeDialog, setTabChangeDialog] = useState(false);

  const handleDateTimeShow = (timeStamp, type) => {
    const dateTimeString = timeStamp;
    const dateTime = new Date(dateTimeString);
    const date = new Date(dateTimeString);
    const timeOptions = {
      hour: 'numeric',
      minute: '2-digit',
      // second: '2-digit',
      hour12: true,
      weekday: 'short',
      // year: 'numeric',
      month: 'short',
      day: 'numeric'
    };
    const dateOptions = {
      // weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };

    const formattedTime = dateTime.toLocaleTimeString(undefined, timeOptions);
    const formattedDate = date.toLocaleDateString(undefined, dateOptions);

    if (type === 'time') {
      return formattedTime;
    }
    if (type === 'date') {
      return formattedDate;
    }
  };

  useEffect(() => {
    const inActiveCarrier = carriers.find(items => items.active === false);
    if (!inActiveCarrier && tabChanged && carriers.length !== 0) {
      setAllCarriersChecked(true);
    } else {
      setAllCarriersChecked(false);
    }
  }, [value]);

  const handleSynchRatesRequest = latestApiRes => {
    setIsLoading(true);

    handleViewTLRates(latestApiRes);
    GetActiveCarriers(AgencyID, previousQuoteId.locationId)
      .then(res => res.status === 200 && handleSuccessCarriers(res))
      .catch(err => {
        ErrorToast(err?.message);
        // setCarriersLoader(false);
      });
    // }
  };

  const handleViewTLRates = ApiData => {
    setApiFailed(false);
    setPickupInstContent(ApiData?.pickupInstructions);
    setDilveryInstContent(ApiData?.deliveryInstructions);
    setOriginOpenTimeValue(ApiData?.pickupOpenTime);
    setOriginCloseTimeValue(ApiData?.pickupCloseTime);
    setDestinationOpenTimeValue(ApiData?.deliveryOpenTime);
    setDestinationCloseTimeValue(ApiData?.deliveryCloseTime);
    console.log(ApiData.quoteCarriers, carriers, 'updatedCarriers');

    setCarriers(ApiData.quoteCarriers);
    const tempIDs = [];
    const carriersFinalArray2 = [];
    for (let i = 0; i < ApiData.quoteCarriers.length; i++) {
      const currentStatus = ApiData.quoteCarriers[i].active;
      const item = ApiData.quoteCarriers[i];
      const carrierID = ApiData.quoteCarriers[i].carrierId;

      if (currentStatus) {
        tempIDs.push(carrierID);
        carriersFinalArray2.push(item);
      }
    }

    setCarriersFinalArray(carriersFinalArray2);
    // Mark the effect as run
    setHasEffectRun(true);
    setIsLoading(false);
  };

  useEffect(() => {
    if (ApiData) {
      handleViewTLRates(ApiData);
    }
  }, [ApiData, carriers]);

  const handleListItemClick = (event, index, items) => {
    // event.preventDefault();
    setCarrierName(items.carrierName);
    setSelectedCarrierNameQuoteRates(items.quoteValue);
    setSelectedCarrierIDQuoteRates(items.sessionId);
    setSelectedIndex(index);
    setSelectedCarrierQuotaionID(items.referenceNumber);
    setTLDate(items.deliveryDate);
    setSelectedPickupdate(items.pickupDate);
    setSelectedDeliveryDate(items.deliveryDate);
    // setComments()
    return false;
  };

  const rando = max => Math.floor(Math.random() * max);
  const words = ['Paper', 'Rock', 'Scissors'];
  const rawData = [];

  for (let i = 0; i < 100; i++)
    rawData.push({ id: rando(300), name: words[i % words.length] });

  const handleResetAllData = () => {
    handleReset();
    setCarrierName(null);
    setPickupNumber('');
    setProNumber('');
    setOriginOpenTimeValue('');
    setOriginCloseTimeValue('');
    setDestinationDate(new Date());
    setDestinationOpenTimeValue('');
    setDestinationCloseTimeValue('');
    setPaymentType('');
    setBOLNumber('');
    setHazmatSwitch(!hazmatSwitch);
    setDeliveryRemarks('');
    setPickupRemarks('');
    setInsidePickupSwitch(false);
    setLiftgatePickupSwitch(false);
    setResidentialPickupSwitch(false);
    setSecureAccesPickupSwitch(false);
    setLimitedPickupAccessSwitch(false);
    setFreezeProtectionPickupSwitch(false);
    setOverlengthPickupSwitch(false);
    setOverLengthValue('');
    setInsideDeliverySwitch(false);
    setLiftgateDeliverySwitch(false);
    setResidentialDeliverySwitch(false);
    setSecureAccesDeliverySwitch(false);
    setLimitedDeliverySwitch(false);
    setCallBeforeDeliveryServicesSwitch(false);
    setCODAmount('');
    setDeclaredValue('');
  };

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    GetActiveCarriers(AgencyID, previousQuoteId.locationId)
      .then(res => res.status === 200 && handleSuccessCarriers(res))
      .catch(err => {
        ErrorToast(err?.message);
        // setCarriersLoader(false);
      });
    PreviousProps.quoteId !== 0 &&
      props?.location?.state?.disptachOpen === 'Get Quote' &&
      handleNext(activeStep);
  }, []);

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  // Update the runtime state directly
  const updateRuntimeState = () => {
    UpdatedStoredServices.current = {
      insideDeliverySwitch: insideDeliverySwitch,
      liftgateDeliverySwitch: liftgateDeliverySwitch,
      residentialDeliverySwitch: residentialDeliverySwitch,
      secureAccesDeliverySwitch: secureAccesDeliverySwitch,
      limitedDeliverySwitch: limitedDeliverySwitch,
      callBeforeDeliveryServicesSwitch: callBeforeDeliveryServicesSwitch,
      insidePickupSwitch: insidePickupSwitch,
      liftgatePickupSwitch: liftgatePickupSwitch,
      residentialPickupSwitch: residentialPickupSwitch,
      secureAccesPickupSwitch: secureAccesPickupSwitch,
      limitedPickupAccessSwitch: limitedPickupAccessSwitch,
      freezeProtectionPickupSwitch: freezeProtectionPickupSwitch,
      overlengthPickupSwitch: overlengthPickupSwitch,
      hazmatSwitch: hazmatSwitch,
      CODAmount: CODAmount,
      DeclaredValue: DeclaredValue,
      OverLengthValue: overLengthValue
    };
    // No re-render will be triggered
  };

  useEffect(() => {
    updateRuntimeState();
  }, []);

  useEffect(() => {
    let status = carriers.find(items => items.active);
    if (status) {
      setEnableNext(false);
    } else setEnableNext(true);
  }, [carriers]);

  const [validationErrors, setValidationErrors] = useState({});
  const [activeStep, setActiveStep] = useState(
    // PreviousProps.quoteId !== 0 ? 1 :
    PreviousProps.quoteId !== 0 &&
      props?.location?.state?.disptachOpen === 'Get Quote'
      ? 1
      : 0
  );
  const [activeTabLabel, setActiveTabLabel] = useState('origin');

  const DispatchApiResHandler = ApiRes => {
    setPickUpLoader(false);
    if (ApiRes?.status === 200) {
      setShipmentDispatchMsg(ApiRes?.data?.data?.status);
      setShipmentDispatchFailed(false);
    } else {
      setShipmentDispatchMsg('');
      setShipmentDispatchFailed(true);
    }
  };
  const CallDispatchShipmentApi = () => {
    // alert('called');
    setPickUpLoader(true);

    const DispatchObj = {
      shipmentId: JSON.parse(props?.location?.state?.pickupRowData).shipmentId
        ? JSON.parse(props?.location?.state?.pickupRowData).shipmentId
        : 0,
      agencyId: AgencyID ? AgencyID : 0,
      type: 0,
      proNumber: proNumber ? proNumber : '',
      bolNumber: BOLNumber ? BOLNumber : '',
      refNumber: refOrQuoteNmb ? refOrQuoteNmb : '',
      pickupNumber: pickupNumber ? pickupNumber : '',
      carrierId: JSON.parse(props?.location?.state?.pickupRowData).carrierId
        ? JSON.parse(props?.location?.state?.pickupRowData).carrierId
        : 0,
      carrierName: JSON.parse(props?.location?.state?.pickupRowData).carrierName
        ? JSON.parse(props?.location?.state?.pickupRowData).carrierName
        : otherCarrierName
        ? otherCarrierName
        : '',
      driverName: driverName ? driverName : '',
      vehicleNumber: truckNumber ? truckNumber : '',
      phoneNumber: ''
    };
    DispatchShipment(DispatchObj)
      .then(res => DispatchApiResHandler(res))
      .catch(err => DispatchApiResHandler(err));
  };

  const handleQuoteDetailReq = apiRes => {
    setIsLoading(true);
    setTLQuoteId(apiRes);
    GetQuote(apiRes)
      .then(res => res.status === 200 && handleViewTLRates(res.data.data))
      .catch(err => {
        setApiFailed(true);
        setCarriers([]);
        setCarriersFinalArray([]);
        setIsLoading(false);
        console.log(err, 'TL quote Err----->>>>');
        setApiErrorMessage(err?.message);
      });
  };

  const handleStartTLRequest = () => {
    const startTLQuoteObj = {
      shipmentId: PreviousProps?.shipmentId ? PreviousProps?.shipmentId : 0,
      pickupDate: PreviousProps?.pickupDate ? PreviousProps?.pickupDate : '',
      pickupInstructions: pickupInstContent ? pickupInstContent : '',
      pickupOpenTime: originOpenTimeValue,
      pickupCloseTime: originCloseTimeValue,
      estimatedDeliveryDate: PreviousProps?.deliveryDate
        ? PreviousProps?.deliveryDate
        : '',
      deliveryInstructions: dilveryInstContent,
      deliveryOpenTime: destinationOpenTimeValue,
      deliveryCloseTime: destinationCloseTimeValue,
      carriers: selectedCarriersIdArray,
      agencyId: AgencyID,
      locationId: PreviousProps?.locationId ? PreviousProps?.locationId : 0,
      weight: PreviousProps.details[0].netWeight
        ? PreviousProps.details[0].netWeight
        : 0,
      pallets: PreviousProps.details[0].pallets
        ? PreviousProps.details[0].pallets
        : 0,
      cargoValue: PreviousProps?.cargoValue ? PreviousProps?.cargoValue : 0
    };
    console.log(startTLQuoteObj, 'startTLQuoteObj');
    PreviousProps?.quoteId === 0 &&
      TLQuoteId === null &&
      StartTLQuote(startTLQuoteObj)
        .then(res => res.status === 200 && handleQuoteDetailReq(res.data.data))
        .catch(err => {
          setApiFailed(true);
          setCarriers([]);
          setCarriersFinalArray([]);
          setIsLoading(false);
          console.log(err, 'TLErr');
          setApiErrorMessage(err?.message);
        });
  };
  const handleNext = index => {
    setNegotiateRate(false);
    let errors = {};
    let ManageShipPickupObj = {};

    if (index === 0 && props?.location?.state?.disptachOpen !== 'Get Quote') {
      if (
        carrierName == null &&
        !otherCarrierName &&
        PreviousProps?.shipmentStatus !== 'Booked'
      ) {
        errors['carrierName'] = 'Select carrier Name';
      } else if (!refOrQuoteNmb) {
        errors['refOrQuoteNmbErr'] = 'Enter Quote Number';
      } else setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else if (index === 1) {
      let tempIDs = [];
      let carriersFinalArray = [];
      if (props?.location?.state?.disptachOpen === 'Get Quote') {
        for (let i = 0; i < carriers.length; i++) {
          const currentStatus = carriers[i].active;
          const item = carriers[i];
          const carrierID = carriers[i].carrierId;
          const cSubType = carriers[i].cSubType;

          currentStatus &&
            tempIDs.push({ carrierId: carrierID, cSubType: cSubType });
          currentStatus && carriersFinalArray.push(item);
        }
        setSelectedCarriersIdArray(tempIDs);
        setCarriersFinalArray(carriersFinalArray);
        setTimeout(() => {
          handleStartTLRequest();
        }, 20);
        if (!originOpenTimeValue) {
          errors['originOpenTime'] = 'Select pickup open time';
        } else if (!originCloseTimeValue) {
          errors['originCloseTime'] = 'Select pickup close time';
        }
        // else if (!pickupRemarks) {
        //   errors['pickupRemarks'] = 'Enter pickup remarks';
        // }
        else if (!destinationOpenTimeValue) {
          errors['destinationOpenTime'] = 'Select delivery open time';
        } else if (!destinationCloseTimeValue) {
          errors['destinationCloseTime'] = 'Select delivery close time';
        }
        // else if (!deliveryRemarks) {
        //   errors['deliveryRemarks'] = 'Enter delivery remarks';
        // }
        else setActiveStep(prevActiveStep => prevActiveStep + 1);
      } else if (!destinationOpenTimeValue) {
        errors['destinationOpenTime'] = 'Select delivery open time';
      } else if (!destinationCloseTimeValue) {
        errors['destinationCloseTime'] = 'Select delivery close time';
      }

      // else if (!deliveryRemarks) {
      //   errors['deliveryRemarks'] = 'Enter delivery remarks';
      // }
      else setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
    //  else if (index === 2) {
    //   CallDispatchShipmentApi();
    // }
    else if (index === 3) {
      const bookTLobj = {
        sessionId: selectedCarrierIDQuoteRates,
        charges: selectedCarrierNameQuoteRates,
        pickupNumber: selectedCarrierQuotaionID,
        estimatedDeliveryDate: TLdate,
        pickupDate: selectedPickupdate,
        comments: 'comments'
      };
      BookTLShipment(bookTLobj)
        .then(res => {
          res.status === 200 && setShipmentBooked(true);
          setShipmentDispatchFailed(false);
        })
        .catch(err => {
          setShipmentBooked(false);
          setShipmentDispatchFailed(true);
        });
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } else setActiveStep(prevActiveStep => prevActiveStep + 1);
    // setSelectedIndex(null);
    // sessionStorage.setItem('updatedStoredServices', null);

    setValidationErrors(errors);
    // if (
    //     props?.location?.state?.disptachOpen === 'Get Quote' &&
    //     activeStep === 2
    // ) {
    //     CallPickupApi();
    // }
    if (
      props?.location?.state?.disptachOpen !== 'Get Quote' &&
      stepsArray().length === 2 &&
      index === 1
    ) {
      CallDispatchShipmentApi();
    }
  };

  const handleBack = () => {
    setNegotiateRate(false);

    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const PaymentTypeArr = ['Outbound Prepaid', 'Third Party'];
  const [paymentType, setPaymentType] = useState(PreviousProps?.paymentTerms);

  const handleClassInputChange = (inputName, index, event) => {
    let totalWeight = 0;
    let TotalWeightSum;
    const values = [...newAddedClassRow];
    if (inputName === 'WeightLBS') {
      // const updatedErrors = { ...validationErrors, weightLBS: null };
      // setValidationErrors(updatedErrors);

      values[index].WeightLBS = event.target.value;

      for (let index = 0; index < newAddedClassRow.length; index++) {
        if (newAddedClassRow[index].WeightLBS !== '') {
          totalWeight += parseInt(newAddedClassRow[index].WeightLBS);
        }
      }
      setTotalLBSWeightClass(totalWeight);
      TotalWeightSum = totalLBSWeightDimensions
        ? parseInt(totalLBSWeightDimensions) + totalWeight
        : totalWeight;
      setTotalWeightSum(TotalWeightSum);
      handlePushAddedCommodityInfo(TotalWeightSum);
    } else if (inputName === 'NMFCnumber') {
      values[index].NMFCnumber = event.target.value;
    } else if (inputName === 'Class') {
      values[index].Class = event === null ? ClassArray[0] : event;
    }

    setNewAddedClassRow(values);
  };

  const handleTimeChange = (newValue, name) => {
    console.log(newValue, 'newValue');
    if (name === 'OriginOpenTime') {
      setOriginOpenTimeValue(newValue.format('HH:mm'));
      // const AMPM = newValue.format('HH:mm').split(':');
      // setOriginOpenTimeFormate(parseInt(AMPM[0]) < 12 ? 'AM' : 'PM');
    } else if (name === 'OriginCloseTime') {
      setOriginCloseTimeValue(newValue.format('HH:mm'));
      // const AMPM = newValue.format('HH:mm').split(':');
      // setOriginCloseTimeFormate(parseInt(AMPM[0]) < 12 ? 'AM' : 'PM');
    } else if (name === 'DestiOpenTime') {
      setDestinationOpenTimeValue(newValue.format('HH:mm'));
      // const AMPM = newValue.format('HH:mm').split(':');
      // setDestinationOpenTimeFormate(parseInt(AMPM[0]) < 12 ? 'AM' : 'PM');
    } else {
      setDestinationCloseTimeValue(newValue.format('HH:mm'));
      // const AMPM = newValue.split(':');
      // setDestinationCloseTimeFormate(parseInt(AMPM[0]) < 12 ? 'AM' : 'PM');
    }
  };

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleSuccessCarriers = ApiRes => {
    // setCarriersLoader(false);
    const CarriersArray = ApiRes?.data?.data;
    const TL = CarriersArray.filter(items => items.cType === 'TL');
    const tanker = CarriersArray.filter(items =>
      items.cSubType.includes('Bulk Tanker')
    );
    const dryVan = CarriersArray.filter(items =>
      items.cSubType.includes('Dry Van')
    );
    const reefer = CarriersArray.filter(items =>
      items.cSubType.includes('Reefer')
    );
    const flatBed = CarriersArray.filter(items =>
      items.cSubType.includes('Flat Bed')
    );
    const sprinter = CarriersArray.filter(items =>
      items.cSubType.includes('Sprinter')
    );
    const boxTruck = CarriersArray.filter(items =>
      items.cSubType.includes('Box Truck')
    );
    const railIntermodal = CarriersArray.filter(items =>
      items.cSubType.includes('Intermodal')
    );

    const TempCarrierIDs = [];
    for (let i = 0; i < CarriersArray.length; i++) {
      CarriersArray[i].active = false;
      TempCarrierIDs.push({ carrierId: CarriersArray[i].id, assigned: false });
    }
    setCarriers(tanker);
    setTLcarriers(TL);
    setTankerCarriers(tanker);
    setDryVanCarriers(dryVan);
    setReeferCarriers(reefer);
    setSprinterCarriers(sprinter);
    setflatBedCarriers(flatBed);
    setBoxTruckCarriers(boxTruck);
    setRailIntermodalCarriers(railIntermodal);
    // setSelectedCarriersIdArray(TempCarrierIDs);
    // EditedData && CheckSelectedCarriers(CarriersArray, TempCarrierIDs);
  };
  const [negotitateRate, setNegotiateRate] = useState(false);

  const handleNegotiateRate = e => {
    setNegotiateRate(true);
  };
  const detectBrowser = () => {
    const userAgent = navigator.userAgent;
    let browser = '';

    if (userAgent.indexOf('Firefox') > -1) {
      browser = 'Firefox';
    } else if (userAgent.indexOf('Chrome') > -1) {
      browser = 'Chrome';
    } else {
      browser = 'Unknown';
    }

    return browser;
  };
  const checkAllSelected = () => {
    let tempIDs = [];

    const allActive = carriers.every(items => items.active);
    for (let i = 0; i < carriers.length; i++) {
      const currentStatus = carriers[i].active;
      const item = carriers[i];
      const carrierID = carriers[i].carrierId;
      const cSubType = carriers[i].cSubType;

      currentStatus &&
        tempIDs.push({ carrierId: carrierID, cSubType: cSubType });
      currentStatus && carriersFinalArray.push(item);
    }
    setSelectedCarriersIdArray(tempIDs);

    if (allActive) {
      setAllCarriersChecked(true);
    } else {
      setAllCarriersChecked(false);
    }
  };
  const browser = detectBrowser();
  const handleCheckActiveCarriers = (index, event, items) => {
    const selectedTankerCarrier = TankerCarriers.find(
      carriersArr => carriersArr.name === items.name
    );
    const selectedDryVanCarrier = dryVanCarriers.find(
      carriersArr => carriersArr.name === items.name
    );
    const selectedReeferCarrier = reeferCarriers.find(
      carriersArr => carriersArr.name === items.name
    );
    const selectedBoxTrucCarrier = boxTruckCarriers.find(
      carriersArr => carriersArr.name === items.name
    );
    const selectedSprinterCarrier = sprinterCarriers.find(
      carriersArr => carriersArr.name === items.name
    );
    const selectedIntermodalCarrier = railIntermodalCarriers.find(
      carriersArr => carriersArr.name === items.name
    );
    if (selectedTankerCarrier?.active) {
      setTankerCarriers(prevArray => {
        const newArray = [...prevArray];
        newArray[index].active = event.target.checked;
        return newArray;
      });
      const updatedItems = TankerCarriers.map(arr =>
        arr.name === items.name ? { ...arr, active: false } : arr
      );
      console.log(updatedItems, 'TankerCarriers1');
      setTankerCarriers(updatedItems);
    } else if (selectedDryVanCarrier?.active) {
      const updatedItems = dryVanCarriers.map(arr =>
        arr.name === items.name ? { ...arr, active: false } : arr
      );
      setDryVanCarriers(updatedItems);
    } else if (selectedReeferCarrier?.active) {
      const updatedItems = reeferCarriers.map(arr =>
        arr.name === items.name ? { ...arr, active: false } : arr
      );
      setReeferCarriers(updatedItems);
    } else if (selectedBoxTrucCarrier?.active) {
      const updatedItems = boxTruckCarriers.map(arr =>
        arr.name === items.name ? { ...arr, active: false } : arr
      );
      setBoxTruckCarriers(updatedItems);
    } else if (selectedSprinterCarrier?.active) {
      const updatedItems = sprinterCarriers.map(arr =>
        arr.name === items.name ? { ...arr, active: false } : arr
      );
      setSprinterCarriers(updatedItems);
    } else if (selectedIntermodalCarrier?.active) {
      const updatedItems = railIntermodalCarriers.map(arr =>
        arr.name === items.name ? { ...arr, active: false } : arr
      );
      setRailIntermodalCarriers(updatedItems);
    }
  };
  const handleCarrierStatus = (index, event, items) => {
    // console.log(index, items, carriers, 'carrier status:');
    // handleCheckActiveCarriers(index, event, items);

    setCarriers(prevArray => {
      const newArray = [...prevArray];
      newArray[index].active = event.target.checked;
      return newArray;
    });
    // localStorage.setItem('currentCarriers', JSON.stringify(carriers));
    setTimeout(() => {
      checkAllSelected();
    }, 10);
  };

  const handleAllActiveCarriers = event => {
    setAllCarriersChecked(event.target.checked);
    for (let i = 0; i < carriers.length; i++) {
      // handleCheckActiveCarriers(i, event, carriers[i]);
      setCarriers(prevArray => {
        const newArray = [...prevArray];
        newArray[i].active = event.target.checked;
        return newArray;
      });
      setTimeout(() => {
        checkAllSelected();
      }, 10);

      // handleCarrierStatus(i, event, carriers[i]);
    }
    // setTimeout(() => {
    //   localStorage.setItem('currentCarriers', JSON.stringify(carriers));
    // }, 10);
  };
  const handleStepperContent = (index, label) => {
    if (index === 0) {
      if (props?.location?.state?.disptachOpen === 'Get Quote') {
        return (
          <Box
            className='ParentFlexDiv'
            // sx={
            // browser === 'Firefox'
            //   ? { marginTop: 3, marginLeft: '10%' }
            //   : { marginTop: 0, marginLeft: '10%' }
            // }
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  fullWidth
                  sx={{ mt: 2 }}
                  disabled
                  label='Ship From'
                  variant='standard'
                  value={
                    PreviousProps?.originCity +
                    ' ' +
                    PreviousProps?.originState +
                    ' ' +
                    PreviousProps?.originZipCode
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={isMobile ? '' : 'LocationsClass'}>
                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true
                    }}
                    disabled
                    variant='standard'
                    error={Boolean(validationErrors['originDate'])}
                    helperText={validationErrors['originDate']}
                    onChange={e => setOriginDate(new Date(e.target.value))}
                    size='small'
                    // defaultValue={materialDateInput}
                    value={PreviousProps?.pickupDate}
                    // sx={{ mt: 3 }}
                    // type='date'
                    label='Date'
                  />
                  <div>
                    <div
                      style={{
                        fontSize: 13,
                        // margin: '10 0 0 15',
                        color: 'rgba(0, 0, 0, 0.58)'
                      }}
                    >
                      <b>Pickup Hours</b>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        // sx={isMobile ? { width: '100%' } : { width: '20vw' }}
                        components={['TimePicker', 'TimePicker']}
                      >
                        <DemoItem>
                          <TimePicker
                            onChange={newValue => {
                              handleTimeChange(newValue, 'OriginOpenTime');
                              setValidationErrors({
                                ...validationErrors,
                                originOpenTime: null
                              });
                            }}
                            label='Open Time'
                            value={dayjs(originOpenTimeValue, 'HH:mm')}
                            // defaultValue={dayjs('2022-04-17T15:30')}
                          />
                        </DemoItem>
                        <DemoItem>
                          <TimePicker
                            onChange={newValue => {
                              handleTimeChange(newValue, 'OriginCloseTime');
                              setValidationErrors({
                                ...validationErrors,
                                originOpenTime: null
                              });
                            }}
                            value={dayjs(originCloseTimeValue, 'HH:mm')}
                            label='Close Time'
                            // defaultValue={dayjs('2022-04-17T15:30')}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </Grid>

              <Grid
                sx={isMobile ? { mt: 1 } : { mt: -2 }}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <div
                  style={
                    browser === 'Firefox'
                      ? { marginBottom: 30 }
                      : { marginBottom: 3 }
                  }
                >
                  <h6>Pickup Instructions</h6>
                  <ReactQuill
                    style={
                      browser === 'Firefox'
                        ? {
                            height: '8vh',
                            marginBottom: 20,
                            marginTop: 0
                          }
                        : {
                            height: '8vh',
                            marginTop: 0
                          }
                    }
                    value={pickupInstContent}
                    onChange={handlePickupInstEditorChange}
                  />
                  {/* <div>
                <h2>Preview:</h2>
                <div dangerouslySetInnerHTML={{ __html: pickupInstContent }} />
              </div> */}
                </div>
              </Grid>

              <Grid
                sx={isMobile ? { mt: 8 } : { mt: 5 }}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <TextField
                  fullWidth
                  // sx={{ width: '63vw', mt: 7 }}
                  disabled
                  label='Ship To'
                  variant='standard'
                  value={
                    PreviousProps?.destinationCity +
                    ' ' +
                    PreviousProps?.destinationState +
                    ' ' +
                    PreviousProps?.destinationZipCode
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className={isMobile ? '' : 'LocationsClass'}>
                  {/* direct dispatch case */}
                  <TextField
                    disabled
                    InputProps={{
                      disableUnderline: true
                    }}
                    fullWidth
                    variant='standard'
                    error={Boolean(validationErrors['originDate'])}
                    helperText={validationErrors['originDate']}
                    onChange={e => setOriginDate(new Date(e.target.value))}
                    size='small'
                    // defaultValue={materialDateInput}
                    value={PreviousProps?.deliveryDate}
                    // sx={isMobile ? { mt: 2 } }
                    // type='date'
                    label='Exp Delivery Date'
                  />
                  <div>
                    <div
                      style={{
                        fontSize: 13,
                        // margin: '10 0 0 15',
                        color: 'rgba(0, 0, 0, 0.58)'
                      }}
                    >
                      <b>Delivery Hours</b>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer
                        // sx={isMobile ? { width: '100%' } : { width: '20vw' }}
                        components={['TimePicker', 'TimePicker']}
                      >
                        <DemoItem>
                          <TimePicker
                            onChange={newValue => {
                              handleTimeChange(newValue, 'DestiOpenTime');
                              setValidationErrors({
                                ...validationErrors,
                                originOpenTime: null
                              });
                            }}
                            label='Open Time'
                            value={dayjs(destinationOpenTimeValue, 'HH:mm')}

                            // defaultValue={dayjs('2022-04-17T15:30')}
                          />
                        </DemoItem>
                        <DemoItem>
                          <TimePicker
                            onChange={newValue => {
                              handleTimeChange(
                                newValue,
                                'destinationCloseTime'
                              );
                              setValidationErrors({
                                ...validationErrors,
                                destinationCloseTime: null
                              });
                            }}
                            value={dayjs(destinationCloseTimeValue, 'HH:mm')}
                            label='Close Time'
                            // defaultValue={dayjs('2022-04-17T15:30')}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </Grid>

              <Grid
                sx={isMobile ? { mt: 2 } : { mt: -2 }}
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <div>
                  <h6>Delivery Instructions</h6>
                  <ReactQuill
                    style={
                      browser === 'Firefox'
                        ? {
                            height: '8vh',
                            marginTop: 0
                          }
                        : {
                            height: '8vh',
                            marginTop: 0
                          }
                    }
                    value={dilveryInstContent}
                    onChange={handleDeliveryInstEditorChange}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        );
      } else
        return (
          <Box className='ParentFlexDiv' sx={{ mt: 5 }}>
            <div
              className='LocationsClass'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Autocomplete
                forcePopupIcon={
                  PreviousProps?.shipmentStatus === 'Booked' ? false : true
                }
                fullWidth
                sx={{ mt: 2 }}
                disabled={
                  PreviousProps?.shipmentStatus === 'Booked' ? true : false
                }
                size='small'
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    <li {...PreviousProps}>
                      {' '}
                      {
                        <div className='carriers_logo_Parent'>
                          {option.title === 'Others' ? (
                            <LocalShippingIcon className='others_carrier_logo' />
                          ) : (
                            <img
                              style={{ objectFit: 'fill' }}
                              className='carriers_logo'
                              alt={option?.carrierLogo}
                              src={option?.carrierLogo}
                            />
                          )}
                        </div>
                      }{' '}
                      {option.title}
                    </li>
                  </Box>
                )}
                value={
                  PreviousProps?.shipmentStatus === 'Booked'
                    ? PreviousProps?.carrierName
                    : otherCarrierName
                    ? otherCarrierName
                    : carrierName
                }
                onChange={(event, newValue) => {
                  newValue?.title === 'Others' && setOpenOtherCarrier(true);
                  setCarrierName({
                    title: newValue?.title,
                    logo: newValue?.carrierLogo?.logo
                  });
                  // setCarrierIdForApi(newValue?.carrierID);
                  newValue?.title !== 'Others' && setOtherCarrierName('');
                  setCarrierName(newValue);
                  setValidationErrors({
                    ...validationErrors,
                    carrierName: null
                  });
                }}
                options={props?.location?.state?.carrierApiNames}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title;
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    {...params}
                    // sx={{ width: '38vw' }}
                    variant='standard'
                    error={Boolean(validationErrors['carrierName'])}
                    helperText={validationErrors['carrierName']}
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    label='Carrier Names'
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline:
                        PreviousProps?.shipmentStatus === 'Booked'
                          ? true
                          : false,
                      startAdornment: (carrierName !== null ||
                        otherCarrierName) && (
                        <div
                          style={{ margin: '0 10 0 0' }}
                          className='carriers_logo_Parent'
                        >
                          {otherCarrierName ||
                          carrierName?.title === 'Others' ? (
                            <LocalShippingIcon className='others_carrier_logo' />
                          ) : (
                            <img
                              style={{ objectFit: 'fill' }}
                              className='carriers_logo'
                              alt={carrierName?.carrierLogo}
                              src={carrierName?.carrierLogo}
                            />
                          )}
                        </div>
                      )
                    }}
                  />
                )}
              />
              <TextField
                fullWidth
                // className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                variant='standard'
                disabled
                sx={{ m: 2 }}
                value={PreviousProps?.details?.[0]?.netWeight}
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                // InputLabelProps={{ style: { fontSize: 13 } }}
                label='Weight(lbs)'
              />
            </div>
            {/* <div
              className='LocationsClass'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Autocomplete
                fullWidth
                disabled
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    {option}
                  </Box>
                )}
                className='Margintop'
                // sx={{ width: '38vw' }}
                name='Class'
                size='small'
                options={ClassArray}
                // defaultValue='50'
                value={PreviousProps?.details?.[0]?.freightClass}
                onChange={(event, newValue) =>
                  handleClassInputChange('Class', index, newValue)
                }
                // sx={{ marginLeft: 1 }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    label='Freight Class'
                    variant='standard'
                    sx={{}}
                    // placeholder='Payment Type'

                    {...params}
                    InputProps={{
                      disableUnderline: true
                      // ...params.InputProps
                    }}
                  />
                )}
              />

              <TextField
                fullWidth
                className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                sx={{ m: 2 }}
                variant='standard'
                disabled={
                  PreviousProps?.carrierApiNames === 'carrierApiNames'
                    ? false
                    : true
                }
                label='NMFC'
                name='NMFCnumber'
                // defaultValue={
                //   PreviousProps?.carrierApiNames === 'carrierApiNames'
                //     ? ''
                //     : '5242141'
                // }
                value={PreviousProps?.details?.[0]?.nmfc}
                onChange={event =>
                  handleClassInputChange('NMFCnumber', index, event)
                }
                size='small'
                id='outlined-password-input'
                // type='number'
                autoComplete='current-password'
                // value={'149980-02'}
              />
            </div> */}
            <div
              // className='LocationsClass'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                fullWidth
                disabled
                label='SO#'
                variant='standard'
                sx={{ mt: 2 }}
                value={PreviousProps?.soNumber}
                // placeholder='Payment Type'

                InputProps={{
                  disableUnderline: true
                  // ...params.InputProps
                }}
              />
              <TextField
                fullWidth
                // className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                sx={{ mt: 2 }}
                variant='standard'
                disabled
                label='Customer PO#`'
                value={PreviousProps?.clientPONumber}
                onChange={event =>
                  handleClassInputChange('NMFCnumber', index, event)
                }
                size='small'
                id='outlined-password-input'
                // type='number'v
                autoComplete='current-password'
                // value={'149980-02'}
              />{' '}
            </div>
            <div
              className='LocationsClass'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <TextField
                // disabled={
                //   props?.location?.state?.disptachOpen === 'PickUp'
                //     ? false
                //     : true
                // }
                fullWidth
                className='RightMarginItems'
                value={refOrQuoteNmb}
                error={Boolean(validationErrors['refOrQuoteNmbErr'])}
                helperText={validationErrors['refOrQuoteNmbErr']}
                onChange={e => {
                  setRefOrQuoteNmb(e.target.value);
                  setValidationErrors({
                    ...validationErrors,
                    refOrQuoteNmbErr: null
                  });
                }}
                size='small'
                sx={{ mt: 2 }}
                label='Ref / Quote# If any'
                type='text'
                variant='standard'
                inputProps={{ disableUnderline: true }}
              />
              <TextField
                disabled
                fullWidth
                className='RightMarginItems'
                variant='standard'
                inputProps={{ disableUnderline: true }}
                // disabled
                value={pickupNumber}
                onChange={e => setPickupNumber(e.target.value)}
                type='text'
                size='small'
                sx={{ m: 2 }}
                label='Service Type'
              />
            </div>
          </Box>
        );
    } else if (index === 1) {
      if (props?.location?.state?.disptachOpen === 'Get Quote') {
        const RatesObj = JSON.parse(props?.location?.state?.pickupRowData);
        let obj = {
          shipmentType: RatesObj?.shipmentType,
          location: 'location',
          paymentType: 'Third Party',
          shipFrom: {
            city: RatesObj?.originCity.trim(),
            stateOrProvinceCode: RatesObj?.originState.trim(),
            postalCode: RatesObj?.originZipCode,
            countryCode: 'USA',
            residential: true,
            streetLines: RatesObj?.originAddress.trim()
          },
          shipTo: {
            city: RatesObj?.destinationCity.trim(),
            stateOrProvinceCode: RatesObj?.destinationState.trim(),
            postalCode: RatesObj?.destinationZipCode,
            countryCode: 'USA',
            residential: true,
            streetLines: RatesObj?.destinationAddress.trim()
          },
          shipmentDate: new Date(),
          isHazmat: hazmatSwitch,
          commodityInfo: [
            {
              freightClass: '50',
              packaging: RatesObj?.packagingType,
              pieces: RatesObj?.details[0]?.pieces,
              weight: {
                units: 'lbs',
                value: RatesObj?.details[0]?.netWeight
              },
              nmfc: RatesObj?.details[0]?.itemNumber,
              dimensions: {
                length: 0,
                width: 0,
                height: 0,
                dimensionsUom: 'cm',
                isStackable: false
              }
            }
          ],

          pickupOptions: {
            inside: insidePickupSwitch,
            liftGateServices: liftgatePickupSwitch,
            residential: residentialPickupSwitch,
            secureAccess: secureAccesPickupSwitch,
            limitedAccess: limitedPickupAccessSwitch,
            freezeProtection: freezeProtectionPickupSwitch,
            overLength: overlengthPickupSwitch,
            overLengthValue: overLengthValue ? parseFloat(overLengthValue) : 0,
            callBeforeDelivery: false
          },
          deliveryOptions: {
            inside: insideDeliverySwitch,
            liftGateServices: liftgateDeliverySwitch,
            residential: residentialDeliverySwitch,
            secureAccess: secureAccesDeliverySwitch,
            limitedAccess: limitedDeliverySwitch,
            freezeProtection: false,
            overLength: false,
            overLengthValue: 0,
            callBeforeDelivery: callBeforeDeliveryServicesSwitch
          },
          codAmount: CODAmount ? parseInt(CODAmount) : 0,
          declaredValue: DeclaredValue ? parseInt(DeclaredValue) : 0,
          agencyId: AgencyID ? AgencyID : 0
        };
        let deepClone = JSON.parse(JSON.stringify(obj));
        deepClone.shipFrom.countryCode = 'USA';
        deepClone.shipTo.countryCode = 'USA';

        function TabPanel(props) {
          const { children, value, index, ...other } = props;

          return (
            <div
              role='tabpanel'
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box sx={{ p: 3 }}>
                  <Typography>{children}</Typography>
                </Box>
              )}
            </div>
          );
        }

        TabPanel.propTypes = {
          children: PropTypes.node,
          index: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired
        };

        const handleClose = () => {
          setTabChangeDialog(false);
          setTabChangeConfrim(false);
        };
        const handleConfimrTabChange = newValue => {
          // setTabChangeDialog(false);

          if (newValue === 0) {
            const tankerInactiveCarriers = TankerCarriers.map(tanker => ({
              ...tanker,
              active: false
            }));

            setCarriers(tankerInactiveCarriers);
          }
          if (newValue === 1) {
            const DryVanInactiveCarriers = dryVanCarriers.map(tanker => ({
              ...tanker,
              active: false
            }));

            setCarriers(DryVanInactiveCarriers);
          }
          if (newValue === 2) {
            const ReeferInactiveCarriers = reeferCarriers.map(tanker => ({
              ...tanker,
              active: false
            }));

            setCarriers(ReeferInactiveCarriers);
          }
          if (newValue === 3) {
            const BoxTruckInactiveCarriers = boxTruckCarriers.map(tanker => ({
              ...tanker,
              active: false
            }));

            setCarriers(BoxTruckInactiveCarriers);
          }
          if (newValue === 4) {
            const SprinterInactiveCarriers = sprinterCarriers.map(tanker => ({
              ...tanker,
              active: false
            }));

            setCarriers(SprinterInactiveCarriers);
          }
          if (newValue === 5) {
            const IntermoalInactiveCarriers = railIntermodalCarriers.map(
              tanker => ({
                ...tanker,
                active: false
              })
            );

            setCarriers(IntermoalInactiveCarriers);
          }
        };
        const handleChange = (event, newValue) => {
          // const currentCarriers = localStorage.getItem('currentCarriers');
          if (carriers.find(items => items.active)) {
            setTabChangeDialog(true);
            setTabConfirmValue(newValue);
          } else if (!tabChangeDialog) {
            // localStorage.removeItem('currentCarriers');

            setValue(newValue);
            setTabChanged(true);
            handleConfimrTabChange(newValue);
          }
        };

        const handleChangeIndex = index => {
          setValue(index);
        };

        function CarriersTabPanel(props) {
          const { children, value, index, ...other } = props;

          return (
            <div
              role='tabpanel'
              hidden={value !== index}
              id={`full-width-tabpanel-${index}`}
              aria-labelledby={`full-width-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box sx={{ p: 3 }}>
                  <Typography>{children}</Typography>
                </Box>
              )}
            </div>
          );
        }

        CarriersTabPanel.propTypes = {
          children: PropTypes.node,
          index: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired
        };

        function Carriersa11yProps(index) {
          return {
            id: `full-width-tab-${index}`,
            'aria-controls': `full-width-tabpanel-${index}`
          };
        }

        const tabPanleArr = [1, 2, 3, 4, 5, 6];
        return (
          <Grid>
            <Dialog
              sx={{ minWidth: 300 }}
              open={tabChangeDialog}
              onClose={handleClose}
              aria-labelledby='alert-dialog-title'
              aria-describedby='alert-dialog-description'
            >
              {/* <DialogTitle id='alert-dialog-title'>
                    Data will be lost on tab change. Do you want to proceed?
                  </DialogTitle> */}
              <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                  Data will be lost on tab change. Do you want to proceed?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant='outlined' onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  onClick={() => {
                    handleConfimrTabChange(tabConfirmValue);
                    setValue(tabConfirmValue);
                    setTabChangeDialog(false);
                  }}
                  // autoFocus
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            <AppBar style={{ marginTop: 10 }} position='static'>
              <Tabs
                sx={{ textTransform: 'capitalize' }}
                value={value}
                onChange={handleChange}
                variant='scrollable'
                scrollButtons='auto'
                aria-label='scrollable auto tabs example'
              >
                {/* <Tab
                      sx={{ textTransform: 'capitalize' }}
                      label='All'
                      {...Carriersa11yProps(0)}
                    /> */}
                <Tab
                  sx={{ textTransform: 'capitalize' }}
                  label='Bulk Tanker'
                  {...Carriersa11yProps(0)}
                />
                <Tab
                  sx={{ textTransform: 'capitalize' }}
                  label='Dry Van'
                  {...Carriersa11yProps(1)}
                />
                <Tab
                  sx={{ textTransform: 'capitalize' }}
                  label='Reefer'
                  {...Carriersa11yProps(2)}
                />
                {/* <Tab
                      sx={{ textTransform: 'capitalize' }}
                      label='Flat Bed'
                      {...Carriersa11yProps(3)}
                    /> */}

                <Tab
                  sx={{ textTransform: 'capitalize' }}
                  label='Box Truck'
                  {...Carriersa11yProps(3)}
                />
                <Tab
                  sx={{ textTransform: 'capitalize' }}
                  label='Sprinter'
                  {...Carriersa11yProps(4)}
                />
                <Tab
                  sx={{ textTransform: 'capitalize' }}
                  label='Intermodal'
                  {...Carriersa11yProps(5)}
                />
              </Tabs>
            </AppBar>
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Grid sx={{ mt: 0, display: 'flex', alignItems: 'center' }}>
                <Typography variant='body2'>Select All</Typography>
                <Switch
                  disabled={carriers.length === 0}
                  color='success'
                  checked={allCarriersChecked}
                  onChange={event => handleAllActiveCarriers(event)}
                />
              </Grid>
            </Grid>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              {tabPanleArr.map((items, index) => (
                <TabPanel
                  style={isMobile ? { margin: -30 } : {}}
                  value={value}
                  index={index}
                  dir={theme.direction}
                >
                  <Box
                    sx={{
                      height: '50vh',
                      overflowX: 'hidden'
                      // width: '100%',
                      // display: 'flex',
                      // justifyContent: 'space-between'
                    }}
                  >
                    <div>
                      {carriers.length === 0 ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '25vh'
                          }}
                        >
                          {' '}
                          No carrier found for this type!
                        </div>
                      ) : (
                        <Grid
                          container
                          spacing={isMobile ? 0 : 2}
                          sx={{
                            // mr: 5,
                            display: 'flex',
                            justifyContent: 'start'
                          }}
                        >
                          <Grid item xs={11} sm={12} md={4} lg={4} xl={4}>
                            {carriers.map((items, index) => {
                              if (index % 3 === 0) {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      margin: '15 10 0 0',
                                      width: '100%'
                                    }}
                                  >
                                    <Card
                                      sx={{
                                        width: '100%',
                                        cursor: 'pointer',
                                        backgroundColor: '#fbfbfb'
                                      }}
                                      // onClick={() => handleClickOpen(items, index)}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <div
                                          style={{
                                            border: '1px solid #e0e0e0',
                                            //   height: 40,
                                            backgroundColor: '#e0e0e0'
                                          }}
                                        >
                                          <img
                                            style={
                                              isMobile
                                                ? {
                                                    height: 40,
                                                    width: 60,
                                                    objectFit: 'fill'
                                                  }
                                                : {
                                                    height: 60,
                                                    width: 80
                                                  }
                                            }
                                            src={
                                              items.logo
                                                ? base_logo_url + items.logo
                                                : NoLogo
                                            }
                                            alt={items.name}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginLeft: 10
                                          }}
                                        >
                                          <div>
                                            <Typography
                                              sx={{ fontWeight: 600 }}
                                              fontSize={isMobile ? 14 : 17}
                                              variant='body1'
                                            >
                                              {items?.name?.length > 16
                                                ? items.name.substring(0, 12) +
                                                  ' ...'
                                                : items.name}{' '}
                                            </Typography>
                                            <div
                                              style={
                                                isMobile
                                                  ? { fontSize: 11 }
                                                  : { fontSize: 13 }
                                              }
                                            >
                                              {items.contactEmail.includes(',')
                                                ? items.contactEmail
                                                    .split(',')
                                                    .map(items => (
                                                      <div>{items}</div>
                                                    ))
                                                : items.contactEmail}
                                              <br />
                                            </div>
                                          </div>
                                          <Switch
                                            size={isMobile ? 'small' : 'medium'}
                                            onChange={event =>
                                              handleCarrierStatus(
                                                index,
                                                event,
                                                items
                                              )
                                            }
                                            checked={items.active}
                                          />
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }
                            })}
                          </Grid>
                          <Grid item xs={11} sm={12} md={4} lg={4} xl={4}>
                            {carriers.map((items, index) => {
                              if (index % 3 === 1) {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      margin: '15 10 0 0',
                                      width: '100%'
                                    }}
                                  >
                                    <Card
                                      sx={{
                                        width: '100%',
                                        cursor: 'pointer',
                                        backgroundColor: '#fbfbfb'
                                      }}
                                      // onClick={() => handleClickOpen(items, index)}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <div
                                          style={{
                                            border: '1px solid #e0e0e0',
                                            //   height: 40,
                                            backgroundColor: '#e0e0e0'
                                          }}
                                        >
                                          <img
                                            style={
                                              isMobile
                                                ? {
                                                    height: 40,
                                                    width: 60,
                                                    objectFit: 'fill'
                                                  }
                                                : {
                                                    height: 60,
                                                    width: 80
                                                  }
                                            }
                                            src={
                                              items.logo
                                                ? base_logo_url + items.logo
                                                : NoLogo
                                            }
                                            alt={items.name}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginLeft: 10
                                          }}
                                        >
                                          <div>
                                            <Typography
                                              sx={{ fontWeight: 600 }}
                                              fontSize={isMobile ? 14 : 17}
                                              variant='body1'
                                            >
                                              {items?.name?.length > 16
                                                ? items.name.substring(0, 12) +
                                                  ' ...'
                                                : items.name}{' '}
                                            </Typography>
                                            <div
                                              style={
                                                isMobile
                                                  ? { fontSize: 11 }
                                                  : { fontSize: 13 }
                                              }
                                            >
                                              {items.contactEmail.includes(',')
                                                ? items.contactEmail
                                                    .split(',')
                                                    .map(items => (
                                                      <div>{items}</div>
                                                    ))
                                                : items.contactEmail}
                                              <br />
                                            </div>
                                          </div>
                                          <Switch
                                            size={isMobile ? 'small' : 'medium'}
                                            onChange={event =>
                                              handleCarrierStatus(
                                                index,
                                                event,
                                                items
                                              )
                                            }
                                            checked={items.active}
                                          />
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }
                            })}
                          </Grid>
                          <Grid item xs={11} sm={12} md={4} lg={4} xl={4}>
                            {carriers.map((items, index) => {
                              if (index % 3 === 2) {
                                return (
                                  <div
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      marginTop: 15
                                    }}
                                  >
                                    <Card
                                      sx={{
                                        width: '100%',
                                        cursor: 'pointer',
                                        backgroundColor: '#fbfbfb'
                                      }}
                                      // onClick={() => handleClickOpen(items, index)}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center'
                                        }}
                                      >
                                        <div
                                          style={{
                                            border: '1px solid #e0e0e0',
                                            //   height: 40,
                                            backgroundColor: '#e0e0e0'
                                          }}
                                        >
                                          <img
                                            style={
                                              isMobile
                                                ? {
                                                    height: 40,
                                                    width: 60,
                                                    objectFit: 'fill'
                                                  }
                                                : {
                                                    height: 60,
                                                    width: 80
                                                  }
                                            }
                                            src={
                                              items.logo
                                                ? base_logo_url + items.logo
                                                : NoLogo
                                            }
                                            alt={items.name}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginLeft: 10
                                          }}
                                        >
                                          <div>
                                            <Typography
                                              sx={{ fontWeight: 600 }}
                                              fontSize={isMobile ? 14 : 17}
                                              variant='body1'
                                            >
                                              {items?.name?.length > 16
                                                ? items.name.substring(0, 12) +
                                                  ' ...'
                                                : items.name}{' '}
                                            </Typography>
                                            <div
                                              style={
                                                isMobile
                                                  ? { fontSize: 11 }
                                                  : { fontSize: 13 }
                                              }
                                            >
                                              {items.contactEmail.includes(',')
                                                ? items.contactEmail
                                                    .split(',')
                                                    .map(items => (
                                                      <div>{items}</div>
                                                    ))
                                                : items.contactEmail}
                                              <br />
                                            </div>
                                          </div>
                                          <Switch
                                            size={isMobile ? 'small' : 'medium'}
                                            onChange={event =>
                                              handleCarrierStatus(
                                                index,
                                                event,
                                                items
                                              )
                                            }
                                            checked={items.active}
                                          />
                                        </div>
                                      </div>
                                    </Card>
                                  </div>
                                );
                              }
                            })}
                          </Grid>
                        </Grid>
                      )}
                    </div>
                  </Box>
                </TabPanel>
              ))}
            </SwipeableViews>
          </Grid>
        );
      } else
        return (
          <Box
            sx={{
              marginTop: 0,
              height: '60vh',
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <Box sx={{ marginTop: 5 }}>
              <div className='OriginTitle'>Origin</div>
              <hr />

              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw' }}
                    disabled
                    label='Name'
                    variant='standard'
                    value={PreviousProps?.originContactPerson}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    sx={{ width: '20vw' }}
                    disabled
                    label='Company'
                    variant='standard'
                    value={PreviousProps?.originCompany}
                  />
                </div>
              </div>

              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Address'
                    variant='standard'
                    value={PreviousProps?.originAddress}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    variant='standard'
                    disabled
                    sx={{ width: '20vw', mt: 2 }}
                    label='City/State/Zip/Country'
                    value={
                      PreviousProps?.originCity +
                      ' ' +
                      PreviousProps?.originState +
                      ' ' +
                      PreviousProps?.originZipCode
                    }
                  />
                </div>
              </div>
              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Phone'
                    variant='standard'
                    value={PreviousProps?.originContactPhone}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Email'
                    variant='standard'
                    value={PreviousProps?.originContactEmail}
                  />
                </div>
              </div>

              <div style={{ display: 'LocationsClass' }}>
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  error={Boolean(validationErrors['originDate'])}
                  helperText={validationErrors['originDate']}
                  onChange={e => setOriginDate(new Date(e.target.value))}
                  size='small'
                  value={PreviousProps?.actualPickupDate}
                  // defaultValue={materialDateInput}
                  sx={{ width: '19vw', mt: 2 }}
                  // type='date'
                  label='Pickup Date'
                />

                {/* <TextField
                InputProps={{
                  disableUnderline: true
                }}
                disabled
                variant='standard'
                FormHelperTextProps={{
                  style: { whiteSpace: 'nowrap' }
                }}
                error={Boolean(validationErrors['originOpenTime'])}
                helperText={validationErrors['originOpenTime']}
                id='time'
                onChange={e => {
                  handleTimeChange(e, 'OriginOpenTime');
                  setValidationErrors({
                    ...validationErrors,
                    originOpenTime: null
                  });
                }}
                value={originOpenTimeValue}
                size='small'
                sx={{ width: '10vw', m: 2 }}
                type='time'
                label='Open Time'
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                InputProps={{
                  disableUnderline: true
                }}
                disabled
                variant='standard'
                FormHelperTextProps={{
                  style: { whiteSpace: 'nowrap' }
                }}
                error={Boolean(validationErrors['originCloseTime'])}
                helperText={validationErrors['originCloseTime']}
                id='time'
                value={originCloseTimeValue}
                onChange={e => {
                  handleTimeChange(e, 'OriginCloseTime');
                  setValidationErrors({
                    ...validationErrors,
                    originCloseTime: null
                  });
                }}
                size='small'
                sx={{ width: '10vw', margin: '15 0 0 0' }}
                type='time'
                label='Close Time'
                InputLabelProps={{
                  shrink: true
                }}
              /> */}
              </div>

              <Typography variant='h5' className='DestinationTitle'>
                Destination
              </Typography>
              <hr />
              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw' }}
                    disabled
                    label='Ship to Name'
                    variant='standard'
                    // defaultValue='Robert Thomas'
                    value={PreviousProps?.destinationContactPerson}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    sx={{ width: '20vw' }}
                    disabled
                    label='Company'
                    variant='standard'
                    value={PreviousProps?.destinationCompany}
                  />
                </div>
              </div>

              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Address'
                    variant='standard'
                    value={PreviousProps?.destinationAddress}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    variant='standard'
                    disabled
                    sx={{ width: '20vw', mt: 2 }}
                    label='City/State/Zip/Country'
                    value={
                      PreviousProps?.destinationCity +
                      ' ' +
                      PreviousProps?.destinationState +
                      ' ' +
                      PreviousProps?.destinationZipCode
                    }
                  />
                </div>
              </div>
              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Phone'
                    variant='standard'
                    value={PreviousProps?.destinationContactPhone}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Email'
                    variant='standard'
                    value={PreviousProps?.destinationContactEmail}
                  />
                </div>
              </div>

              <div style={{ display: 'LocationsClass' }}>
                {/*  dispatch case */}
                <TextField
                  disabled
                  InputProps={{
                    disableUnderline: true
                  }}
                  variant='standard'
                  error={Boolean(validationErrors['originDate'])}
                  helperText={validationErrors['originDate']}
                  onChange={e => setOriginDate(new Date(e.target.value))}
                  size='small'
                  value={PreviousProps?.actualDeliveryDate}
                  sx={{ width: '19vw', mt: 2 }}
                  // type='date'
                  label='Exp Delivery Date'
                />

                {/* <TextField
                InputProps={{
                  disableUnderline: true
                }}
                disabled
                variant='standard'
                FormHelperTextProps={{
                  style: { whiteSpace: 'nowrap' }
                }}
                error={Boolean(validationErrors['originOpenTime'])}
                helperText={validationErrors['originOpenTime']}
                id='time'
                onChange={e => {
                  handleTimeChange(e, 'OriginOpenTime');
                  setValidationErrors({
                    ...validationErrors,
                    originOpenTime: null
                  });
                }}
                value={originOpenTimeValue}
                size='small'
                sx={{ width: '10vw', m: 2 }}
                type='time'
                label='Open Time'
                InputLabelProps={{
                  shrink: true
                }}
              />
              <TextField
                InputProps={{
                  disableUnderline: true
                }}
                disabled
                variant='standard'
                FormHelperTextProps={{
                  style: { whiteSpace: 'nowrap' }
                }}
                error={Boolean(validationErrors['originCloseTime'])}
                helperText={validationErrors['originCloseTime']}
                id='time'
                value={originCloseTimeValue}
                onChange={e => {
                  handleTimeChange(e, 'OriginCloseTime');
                  setValidationErrors({
                    ...validationErrors,
                    originCloseTime: null
                  });
                }}
                size='small'
                sx={{ width: '10vw', margin: '15 0 0 0' }}
                type='time'
                label='Close Time'
                InputLabelProps={{
                  shrink: true
                }}
              /> */}
              </div>
            </Box>
            {/* Dispatch Preview  */}
            <div className='OriginTitle'>Shipment Information</div>
            <hr />
            <Box sx={{ mt: 5 }}>
              {/* <Typography sx={{ mb: 1 }} variant='body2'> */}
              <div className='LocationsClass'>
                <Autocomplete
                  disabled
                  sx={{ width: '20vw' }}
                  // disabled={
                  //   PreviousProps?.carrierApiNames === 'carrierApiNames' ? true : false
                  // }
                  size='small'
                  value={
                    carrierName
                      ? carrierName
                      : otherCarrierName
                      ? otherCarrierName
                      : PreviousProps?.carrierName
                  }
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      <li {...PreviousProps}>{option.title}</li>
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setCarrierName({
                        title: newValue.title
                      });

                      setValidationErrors({
                        ...validationErrors,
                        carrierName: null
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setCarrierName({
                        title: newValue.inputValue
                      });
                      setValidationErrors({
                        ...validationErrors,
                        carrierName: null
                      });
                    } else {
                      setCarrierName(newValue);
                      setValidationErrors({
                        ...validationErrors,
                        carrierName: null
                      });
                    }
                  }}
                  options={PreviousProps?.carrierApiNames}
                  getOptionLabel={option => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.title;
                  }}
                  freeSolo
                  renderInput={params => (
                    <TextField
                      disabled
                      sx={{ width: '20vw' }}
                      variant='standard'
                      error={Boolean(validationErrors['carrierName'])}
                      helperText={validationErrors['carrierName']}
                      FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                      {...params}
                      InputProps={{
                        disableUnderline: true,
                        ...params.InputProps
                      }}
                      label='Carrier Name'
                    />
                  )}
                />

                <TextField
                  // className='RightMarginItems'
                  InputProps={{
                    disableUnderline: true
                  }}
                  variant='standard'
                  disabled
                  sx={{ width: '20vw', mt: 0 }}
                  value={PreviousProps?.details?.[0]?.netWeight}
                  error={Boolean(validationErrors['carrierName'])}
                  helperText={validationErrors['carrierName']}
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  label='Weight(lbs)'
                />
              </div>
              {/* <div className='LocationsClass'>
                <Autocomplete
                  disabled
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      {option}
                    </Box>
                  )}
                  // className='Margintop'
                  sx={{ width: '20vw', mt: 2 }}
                  name='Class'
                  size='small'
                  options={ClassArray}
                  // defaultValue='50'
                  value={PreviousProps?.details?.[0]?.freightClass}
                  onChange={(event, newValue) =>
                    handleClassInputChange('Class', index, newValue)
                  }
                  // sx={{ marginLeft: 1 }}
                  renderInput={params => (
                    <TextField
                      label='Freight Class'
                      variant='standard'
                      sx={{ width: '20vw' }}
                      // placeholder='Payment Type'

                      {...params}
                      InputProps={{
                        disableUnderline: true
                        // ...params.InputProps
                      }}
                    />
                  )}
                />

                <TextField
                  // className='RightMarginItems'
                  InputProps={{
                    disableUnderline: true
                  }}
                  sx={{ width: '20vw', mt: 2 }}
                  variant='standard'
                  disabled
                  label='NMFC'
                  name='NMFCnumber'
                  // defaultValue='5242141'
                  value={PreviousProps?.details?.[0]?.nmfc}
                  onChange={event =>
                    handleClassInputChange('NMFCnumber', index, event)
                  }
                  size='small'
                  id='outlined-password-input'
                  // type='number'
                  autoComplete='current-password'
                  // value={'149980-02'}
                />
              </div> */}
              <div style={{ marginTop: 10 }} className='LocationsClass'>
                <TextField
                  disabled
                  label='SO#'
                  value={PreviousProps?.soNumber}
                  variant='standard'
                  sx={{ width: '20vw', mt: 0 }}
                  // placeholder='Payment Type'

                  InputProps={{
                    disableUnderline: true
                    // ...params.InputProps
                  }}
                />

                <TextField
                  className='RightMarginItems'
                  InputProps={{
                    disableUnderline: true
                  }}
                  sx={{ width: '20vw', ml: 0 }}
                  variant='standard'
                  disabled
                  label='Customer PO#'
                  value={PreviousProps?.clientPONumber}
                  onChange={event =>
                    handleClassInputChange('NMFCnumber', index, event)
                  }
                  size='small'
                  id='outlined-password-input'
                  // type='number'
                  autoComplete='current-password'
                  // value={'149980-02'}
                />
              </div>
              <div style={{ marginTop: 10 }} className='LocationsClass'>
                {/* <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  className='Margintop'
                  value={proNumber}
                  // onChange={e => setProNumber(e.target.value)}
                  size='small'
                  sx={
                    !proNumber ? { display: 'none' } : { width: '20vw', mt: 0 }
                  }
                  variant='standard'
                  label='PRO#'
                  type='text'
                  inputProps={{ disableUnderline: true }}
                /> */}
                {refOrQuoteNmb && (
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    disabled
                    className='RightMarginItems'
                    value={refOrQuoteNmb}
                    onChange={e => setRefOrQuoteNmb(e.target.value)}
                    size='small'
                    sx={
                      !proNumber
                        ? { width: '20vw', ml: 0 }
                        : { width: '20vw', ml: 0 }
                    }
                    label='Ref / Quote# If any'
                    type='text'
                    variant='standard'
                    inputProps={{ disableUnderline: true }}
                  />
                )}
              </div>
              <div className='LocationsClass'>
                {pickupNumber && (
                  <TextField
                    className='RightMarginItems'
                    variant='standard'
                    InputProps={{
                      disableUnderline: true
                    }}
                    disabled
                    value={pickupNumber}
                    onChange={e => setPoRefNumber(e.target.value)}
                    type='text'
                    size='small'
                    sx={{ width: '20vw', mt: 2 }}
                    label='Service Type'
                  />
                )}
              </div>
              <div className='LocationsClass'>
                <Autocomplete
                  freeSolo
                  disabled
                  className='Margintop'
                  sx={{ width: '20vw', margin: '15 0 0 0' }}
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      {option}
                    </Box>
                  )}
                  size='small'
                  options={PaymentTypeArr}
                  value={PreviousProps?.paymentTerms}
                  onChange={(event, newValue) => {
                    setPaymentType(newValue);
                    setValidationErrors({
                      ...validationErrors,
                      paymentTerms: null
                    });
                  }}
                  // sx={{ marginLeft: 1 }}
                  renderInput={params => (
                    <TextField
                      disabled
                      variant='standard'
                      FormHelperTextProps={{ style: { margin: '5 30 0 0' } }}
                      error={Boolean(validationErrors['paymentTerms'])}
                      helperText={validationErrors['paymentTerms']}
                      label='Freight Payment Type'
                      {...params}
                      InputProps={{
                        disableUnderline: true,
                        ...params.InputProps
                      }}
                    />
                  )}
                />
                {/* <Autocomplete
                freeSolo
                disabled
                sx={{ width: '20vw' }}
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    {option}
                  </Box>
                )}
                size='small'
                // options={HazmatOptions}
                value={hazmatSwitch ? 'Yes' : 'No'}
                onChange={(event, newValue) => {
                  setHazmatType(newValue);
                  setValidationErrors({
                    ...validationErrors,
                    paymentTerms: null
                  });
                }}
                // sx={{ marginLeft: 1 }}
                renderInput={params => (
                  <TextField
                    className='RightMarginItems'
                    variant='standard'
                    inputProps={{ disableUnderline: true }}
                    FormHelperTextProps={{ style: { margin: '5 30 0 0' } }}
                    error={Boolean(validationErrors['paymentTerms'])}
                    helperText={validationErrors['paymentTerms']}
                    label='Hazmat'
                    {...params}
                    InputProps={{
                      disableUnderline: true,
                      ...params.InputProps
                    }}
                  />
                )}
              /> */}
              </div>
            </Box>

            {otherCarrierName && (
              <div className='OriginTitle'>Other Carrier Info</div>
            )}
            <hr />

            {otherCarrierName && (
              <Box className='RemarksParent'>
                <TextField
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  disabled
                  error={Boolean(validationErrors['otherCarrierName'])}
                  helperText={validationErrors['otherCarrierName']}
                  value={otherCarrierName}
                  onChange={e => {
                    setOtherCarrierName(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      otherCarrierName: null
                    });
                  }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='Carrier Name'
                />
                <TextField
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  disabled
                  error={Boolean(validationErrors['truckNumber'])}
                  helperText={validationErrors['truckNumber']}
                  value={truckNumber}
                  onChange={e => {
                    setTruckNumber(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      truckNumber: null
                    });
                  }}
                  sx={{ width: '20vw' }}
                  size='small'
                  label='Truck Number'
                />
                <TextField
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  disabled
                  error={Boolean(validationErrors['driverName'])}
                  helperText={validationErrors['driverName']}
                  value={driverName}
                  onChange={e => {
                    setDriverName(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      driverName: null
                    });
                  }}
                  sx={{ width: '20vw', mt: 0 }}
                  size='small'
                  label='Driver Name'
                />
                <TextField
                  variant='standard'
                  InputProps={{ disableUnderline: true }}
                  disabled
                  error={Boolean(validationErrors['driverNumber'])}
                  helperText={validationErrors['driverNumber']}
                  value={driverNumber}
                  onChange={e => {
                    setDriverNumber(e.target.value);
                    setValidationErrors({
                      ...validationErrors,
                      driverNumber: null
                    });
                  }}
                  sx={{ width: '20vw', mt: 0 }}
                  size='small'
                  label='Driver Number'
                />
              </Box>
            )}
          </Box>
        );
    } else if (index === 2) {
      if (props?.location?.state?.disptachOpen === 'Get Quote') {
        const RatesObj = JSON.parse(props?.location?.state?.pickupRowData);
        let obj = {
          shipmentType: RatesObj?.shipmentType,
          location: 'location',
          paymentType: 'Third Party',
          shipFrom: {
            city: RatesObj?.originCity.trim(),
            stateOrProvinceCode: RatesObj?.originState.trim(),
            postalCode: RatesObj?.originZipCode,
            countryCode: 'USA',
            residential: true,
            streetLines: RatesObj?.originAddress.trim()
          },
          shipTo: {
            city: RatesObj?.destinationCity.trim(),
            stateOrProvinceCode: RatesObj?.destinationState.trim(),
            postalCode: RatesObj?.destinationZipCode,
            countryCode: 'USA',
            residential: true,
            streetLines: RatesObj?.destinationAddress.trim()
          },
          shipmentDate: new Date(),
          isHazmat: hazmatSwitch,
          commodityInfo: [
            {
              freightClass: '50',
              packaging: RatesObj?.details[0].packagingType,
              pieces: RatesObj?.details[0]?.pieces,
              weight: {
                units: 'lbs',
                value: RatesObj?.details[0]?.netWeight
              },
              nmfc: RatesObj?.details[0]?.itemNumber,
              dimensions: {
                length: 0,
                width: 0,
                height: 0,
                dimensionsUom: 'cm',
                isStackable: false
              }
            }
          ],

          pickupOptions: {
            inside: insidePickupSwitch,
            liftGateServices: liftgatePickupSwitch,
            residential: residentialPickupSwitch,
            secureAccess: secureAccesPickupSwitch,
            limitedAccess: limitedPickupAccessSwitch,
            freezeProtection: freezeProtectionPickupSwitch,
            overLength: overlengthPickupSwitch,
            overLengthValue: overLengthValue ? parseFloat(overLengthValue) : 0,
            callBeforeDelivery: false
          },
          deliveryOptions: {
            inside: insideDeliverySwitch,
            liftGateServices: liftgateDeliverySwitch,
            residential: residentialDeliverySwitch,
            secureAccess: secureAccesDeliverySwitch,
            limitedAccess: limitedDeliverySwitch,
            freezeProtection: false,
            overLength: false,
            overLengthValue: 0,
            callBeforeDelivery: callBeforeDeliveryServicesSwitch
          },
          codAmount: CODAmount ? parseInt(CODAmount) : 0,
          declaredValue: DeclaredValue ? parseInt(DeclaredValue) : 0,
          agencyId: AgencyID ? AgencyID : 0
        };

        let deepClone = JSON.parse(JSON.stringify(obj));
        deepClone.shipFrom.countryCode = 'USA';
        deepClone.shipTo.countryCode = 'USA';

        const handleChangeServicesTab = (event, newValue) => {
          setValue(newValue);
        };
        function a11yProps(index) {
          return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`
          };
        }
        function TabPanel(props) {
          const { children, value, index, ...other } = props;

          return (
            <div
              role='tabpanel'
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box sx={{ p: 3 }}>
                  <Typography>{children}</Typography>
                </Box>
              )}
            </div>
          );
        }

        TabPanel.propTypes = {
          children: PropTypes.node,
          index: PropTypes.number.isRequired,
          value: PropTypes.number.isRequired
        };

        return (
          <div>
            <ShipmentTabPanel
              previousRowDetails={previousQuoteId}
              handleViewTLRates={handleSynchRatesRequest}
              TLQuoteId={TLQuoteId}
              PreviousProps={PreviousProps}
              carriers={carriersFinalArray}
              state={obj}
              deepClone={deepClone}
              totalLBSWeight={RatesObj?.details[0]?.netWeight}
              quote='quote'
              DataToShow={JSON.parse(props?.location?.state?.pickupRowData)}
              ReloadQuote={reloadQuote}
              handleListItemClick={handleListItemClick}
              selectedIndex={selectedIndex}
              previousTab={'TLcarrier'}
              handleNegotiateRate={handleNegotiateRate}
              isLoading={isLoading}
              ApiFailed={ApiFailed}
              ApiErrorMessage={ApiErrorMessage}
            />
          </div>
        );
      } else
        return (
          <Box className='ParentFlexDiv' sx={{ mt: 5 }}>
            <div className='LocationsClass'>
              <Autocomplete
                sx={{ width: '20vw' }}
                disabled={
                  PreviousProps?.shipmentStatus === 'Booked' ? true : false
                }
                size='small'
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    <li {...PreviousProps}>
                      {' '}
                      {
                        <div className='carriers_logo_Parent'>
                          {option.title === 'Others' ? (
                            <LocalShippingIcon className='others_carrier_logo' />
                          ) : (
                            <img
                              className='carriers_logo'
                              alt={option?.carrierLogo?.name}
                              src={option?.carrierLogo?.logo}
                            />
                          )}
                        </div>
                      }{' '}
                      {option.title}
                    </li>
                  </Box>
                )}
                value={
                  PreviousProps?.shipmentStatus === 'Booked'
                    ? PreviousProps?.carrierName
                    : otherCarrierName
                    ? otherCarrierName
                    : carrierName
                }
                onChange={(event, newValue) => {
                  newValue?.title === 'Others' && setOpenOtherCarrier(true);
                  setCarrierName({
                    title: newValue?.title,
                    logo: newValue?.carrierLogo?.logo
                  });
                  newValue?.title !== 'Others' && setOtherCarrierName('');
                  setCarrierName(newValue);
                  setValidationErrors({
                    ...validationErrors,
                    carrierName: null
                  });
                }}
                options={props?.location?.state?.carrierApiNames}
                getOptionLabel={option => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.title;
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    sx={{ width: '20vw' }}
                    variant='standard'
                    error={Boolean(validationErrors['carrierName'])}
                    helperText={validationErrors['carrierName']}
                    FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                    label='Carrier Name'
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (carrierName !== null ||
                        otherCarrierName) && (
                        <div
                          style={{ margin: '0 10 0 0' }}
                          className='carriers_logo_Parent'
                        >
                          {otherCarrierName ||
                          carrierName?.title === 'Others' ? (
                            <LocalShippingIcon className='others_carrier_logo' />
                          ) : (
                            <img
                              className='carriers_logo'
                              alt={carrierName?.carrierLogo?.name}
                              src={carrierName?.carrierLogo?.logo}
                            />
                          )}
                        </div>
                      )
                    }}
                  />
                )}
              />
              <TextField
                // className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                variant='standard'
                disabled
                sx={{ width: '20vw', ml: 2 }}
                value={PreviousProps?.details?.[0]?.netWeight}
                FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                // InputLabelProps={{ style: { fontSize: 13 } }}
                label='Weight(lbs)'
              />
            </div>
            {/* <div className='LocationsClass'>
              <Autocomplete
                disabled
                renderOption={(PreviousProps, option) => (
                  <Box style={{ fontSize: 13 }} {...PreviousProps}>
                    {option}
                  </Box>
                )}
                className='Margintop'
                sx={{ width: '20vw' }}
                name='Class'
                size='small'
                options={ClassArray}
                // defaultValue='50'
                value={PreviousProps?.details?.[0]?.freightClass}
                onChange={(event, newValue) =>
                  handleClassInputChange('Class', index, newValue)
                }
                // sx={{ marginLeft: 1 }}
                renderInput={params => (
                  <TextField
                    label='Freight Class'
                    variant='standard'
                    sx={{ width: '20vw' }}
                    // placeholder='Payment Type'

                    {...params}
                    InputProps={{
                      disableUnderline: true
                      // ...params.InputProps
                    }}
                  />
                )}
              />

              <TextField
                className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                sx={{ width: '20vw', m: 2 }}
                variant='standard'
                disabled={
                  PreviousProps?.carrierApiNames === 'carrierApiNames'
                    ? false
                    : true
                }
                label='NMFC'
                name='NMFCnumber'
                // defaultValue={
                //   PreviousProps?.carrierApiNames === 'carrierApiNames'
                //     ? ''
                //     : '5242141'
                // }
                value={PreviousProps?.details?.[0]?.nmfc}
                onChange={event =>
                  handleClassInputChange('NMFCnumber', index, event)
                }
                size='small'
                id='outlined-password-input'
                // type='number'
                autoComplete='current-password'
                // value={'149980-02'}
              />
            </div> */}
            <div className='LocationsClass'>
              <TextField
                disabled
                label='SO#'
                variant='standard'
                sx={{ width: '20vw', mt: 2 }}
                value={PreviousProps?.soNumber}
                // placeholder='Payment Type'

                InputProps={{
                  disableUnderline: true
                  // ...params.InputProps
                }}
              />
              <TextField
                className='RightMarginItems'
                InputProps={{
                  disableUnderline: true
                }}
                sx={{ width: '20vw', m: 2 }}
                variant='standard'
                disabled
                label='Customer PO#'
                value={PreviousProps?.clientPONumber}
                onChange={event =>
                  handleClassInputChange('NMFCnumber', index, event)
                }
                size='small'
                id='outlined-password-input'
                // type='number'
                autoComplete='current-password'
                // value={'149980-02'}
              />
            </div>
            <div className='LocationsClass'>
              {/* <TextField
                error={Boolean(validationErrors['proNumber'])}
                helperText={validationErrors['proNumber']}
                className='Margintop'
                value={proNumber}
                onChange={e => setProNumber(e.target.value)}
                // onChange={e => {
                //   setProNumber(e.target.value);
                //   setValidationErrors({ ...validationErrors, proNumber: null });
                // }}
                size='small'
                sx={{ width: '20vw' }}
                variant='standard'
                label='PRO#'
                type='text'
                inputProps={{ disableUnderline: true }}
              /> */}
              <TextField
                className='RightMarginItems'
                value={refOrQuoteNmb}
                onChange={e => setRefOrQuoteNmb(e.target.value)}
                size='small'
                sx={{ width: '20vw', ml: 2 }}
                label='Ref / Quote# If any'
                type='text'
                variant='standard'
                inputProps={{ disableUnderline: true }}
              />
            </div>
            <div className='LocationsClass'>
              <TextField
                className='RightMarginItems'
                variant='standard'
                inputProps={{ disableUnderline: true }}
                disabled
                value={pickupNumber}
                onChange={e => setPickupNumber(e.target.value)}
                type='text'
                size='small'
                sx={{ width: '20vw', m: 2 }}
                label='Service Type'
              />
            </div>
          </Box>
        );
    } else if (index === 3) {
      if (props?.location?.state?.disptachOpen === 'Get Quote') {
        return (
          <Box
            sx={{
              marginTop: 0,
              height: '70vh',
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <Box sx={{ marginTop: 5 }}>
              <div className='OriginTitle'>Origin</div>
              <hr />

              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw' }}
                    disabled
                    label='Name'
                    variant='standard'
                    value={PreviousProps?.originContactPerson}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    sx={{ width: '20vw' }}
                    disabled
                    label='Company'
                    variant='standard'
                    value={PreviousProps?.originCompany}
                  />
                </div>
              </div>

              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Address'
                    variant='standard'
                    value={PreviousProps?.originAddress}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    variant='standard'
                    disabled
                    sx={{ width: '20vw', mt: 2 }}
                    label='City/State/Zip/Country'
                    value={
                      PreviousProps?.originCity +
                      ' ' +
                      PreviousProps?.originState +
                      ' ' +
                      PreviousProps?.originZipCode
                    }
                  />
                </div>
              </div>
              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Phone'
                    variant='standard'
                    value={PreviousProps?.originContactPhone}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Email'
                    variant='standard'
                    value={PreviousProps?.originContactEmail}
                  />
                </div>
              </div>

              <div style={{ display: 'LocationsClass' }}>
                {/* direct dispatch case */}
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  error={Boolean(validationErrors['originDate'])}
                  helperText={validationErrors['originDate']}
                  onChange={e => setOriginDate(new Date(e.target.value))}
                  size='small'
                  value={selectedPickupdate}
                  // defaultValue={materialDateInput}
                  sx={{ width: '19vw', mt: 2 }}
                  // type='date'
                  label='Pickup Date'
                />

                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  FormHelperTextProps={{
                    style: { whiteSpace: 'nowrap' }
                  }}
                  error={Boolean(validationErrors['originOpenTime'])}
                  helperText={validationErrors['originOpenTime']}
                  id='time'
                  onChange={e => {
                    handleTimeChange(e, 'OriginOpenTime');
                    setValidationErrors({
                      ...validationErrors,
                      originOpenTime: null
                    });
                  }}
                  value={originOpenTimeValue}
                  size='small'
                  sx={{ width: '10vw', m: 2 }}
                  type='time'
                  label='Open Time'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  FormHelperTextProps={{
                    style: { whiteSpace: 'nowrap' }
                  }}
                  error={Boolean(validationErrors['originCloseTime'])}
                  helperText={validationErrors['originCloseTime']}
                  id='time'
                  value={originCloseTimeValue}
                  onChange={e => {
                    handleTimeChange(e, 'OriginCloseTime');
                    setValidationErrors({
                      ...validationErrors,
                      originCloseTime: null
                    });
                  }}
                  size='small'
                  sx={{ width: '10vw', margin: '15 0 0 0' }}
                  type='time'
                  label='Close Time'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

              <Typography variant='h5' className='DestinationTitle'>
                Destination
              </Typography>
              <hr />
              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw' }}
                    disabled
                    label='Ship to Name'
                    variant='standard'
                    // defaultValue='Robert Thomas'
                    value={PreviousProps?.destinationContactPerson}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    sx={{ width: '20vw' }}
                    disabled
                    label='Company'
                    variant='standard'
                    value={PreviousProps?.destinationCompany}
                  />
                </div>
              </div>

              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Address'
                    variant='standard'
                    value={PreviousProps?.destinationAddress}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    variant='standard'
                    disabled
                    sx={{ width: '20vw', mt: 2 }}
                    label='City/State/Zip/Country'
                    value={
                      PreviousProps?.destinationCity +
                      ' ' +
                      PreviousProps?.destinationState +
                      ' ' +
                      PreviousProps?.destinationZipCode
                    }
                  />
                </div>
              </div>
              <div className='LocationsClass'>
                <div>
                  <TextField
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Phone'
                    variant='standard'
                    value={PreviousProps?.destinationContactPhone}
                    InputProps={{
                      disableUnderline: true
                    }}
                  />
                </div>
                <div>
                  <TextField
                    InputProps={{
                      disableUnderline: true
                    }}
                    sx={{ width: '20vw', mt: 2 }}
                    disabled
                    label='Email'
                    variant='standard'
                    value={PreviousProps?.destinationContactEmail}
                  />
                </div>
              </div>

              <div style={{ display: 'LocationsClass' }}>
                <TextField
                  disabled
                  InputProps={{
                    disableUnderline: true
                  }}
                  variant='standard'
                  error={Boolean(validationErrors['originDate'])}
                  helperText={validationErrors['originDate']}
                  onChange={e => setOriginDate(new Date(e.target.value))}
                  size='small'
                  value={selectedDeliveryDate}
                  sx={{ width: '19vw', mt: 2 }}
                  // type='date'
                  label='Exp Delivery Date'
                />

                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  FormHelperTextProps={{
                    style: { whiteSpace: 'nowrap' }
                  }}
                  error={Boolean(validationErrors['originOpenTime'])}
                  helperText={validationErrors['originOpenTime']}
                  id='time'
                  onChange={e => {
                    handleTimeChange(e, 'OriginOpenTime');
                    setValidationErrors({
                      ...validationErrors,
                      originOpenTime: null
                    });
                  }}
                  value={destinationOpenTimeValue}
                  size='small'
                  sx={{ width: '10vw', m: 2 }}
                  type='time'
                  label='Open Time'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  variant='standard'
                  FormHelperTextProps={{
                    style: { whiteSpace: 'nowrap' }
                  }}
                  error={Boolean(validationErrors['originCloseTime'])}
                  helperText={validationErrors['originCloseTime']}
                  id='time'
                  value={destinationCloseTimeValue}
                  onChange={e => {
                    handleTimeChange(e, 'OriginCloseTime');
                    setValidationErrors({
                      ...validationErrors,
                      originCloseTime: null
                    });
                  }}
                  size='small'
                  sx={{ width: '10vw', margin: '15 0 0 0' }}
                  type='time'
                  label='Close Time'
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </Box>
            {/* get Rates Preview */}
            <div className='OriginTitle'>Shipment Information</div>
            <hr />
            <Box sx={{ mt: 5 }}>
              {/* <Typography sx={{ mb: 1 }} variant='body2'> */}
              <div className='LocationsClass'>
                <Autocomplete
                  disabled
                  sx={{ width: '20vw' }}
                  size='small'
                  value={carrierName}
                  renderOption={(PreviousProps, option) => (
                    <Box style={{ fontSize: 13 }} {...PreviousProps}>
                      <li {...PreviousProps}>{option.title}</li>
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                      setCarrierName({
                        title: newValue.title
                      });
                      // setSelectedCarrierID({
                      //   ID: newValue.carrierID
                      // });
                      setValidationErrors({
                        ...validationErrors,
                        carrierName: null
                      });
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setCarrierName({
                        title: newValue.inputValue
                      });
                      setValidationErrors({
                        ...validationErrors,
                        carrierName: null
                      });
                    } else {
                      setCarrierName(newValue);
                      setValidationErrors({
                        ...validationErrors,
                        carrierName: null
                      });
                    }
                  }}
                  options={PreviousProps?.carrierApiNames}
                  getOptionLabel={option => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.title;
                  }}
                  // renderOption={(PreviousProps, option) => <li {...PreviousProps}>{option.title}</li>}
                  freeSolo
                  renderInput={params => (
                    <TextField
                      disabled
                      sx={{ width: '20vw' }}
                      variant='standard'
                      error={Boolean(validationErrors['carrierName'])}
                      helperText={validationErrors['carrierName']}
                      FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                      {...params}
                      InputProps={{
                        disableUnderline: true,
                        ...params.InputProps
                      }}
                      label='Carrier Name'
                    />
                  )}
                />

                <TextField
                  // className='RightMarginItems'
                  InputProps={{
                    disableUnderline: true
                  }}
                  variant='standard'
                  disabled
                  sx={{ width: '20vw' }}
                  value={PreviousProps?.details?.[0]?.netWeight}
                  error={Boolean(validationErrors['carrierName'])}
                  helperText={validationErrors['carrierName']}
                  FormHelperTextProps={{ style: { margin: ' 5 30 0 0' } }}
                  // InputLabelProps={{ style: { fontSize: 13 } }}
                  label='Weight(lbs)'
                />
              </div>
              <div className='LocationsClass'>
                <TextField
                  disabled
                  label='SO#'
                  value={PreviousProps?.soNumber}
                  variant='standard'
                  sx={{ width: '20vw', mt: 2 }}
                  // placeholder='Payment Type'

                  InputProps={{
                    disableUnderline: true
                    // ...params.InputProps
                  }}
                />

                <TextField
                  className='RightMarginItems'
                  InputProps={{
                    disableUnderline: true
                  }}
                  sx={{ width: '20vw', mt: 2 }}
                  variant='standard'
                  disabled
                  label='Customer PO#'
                  value={PreviousProps?.clientPONumber}
                  onChange={event =>
                    handleClassInputChange('NMFCnumber', index, event)
                  }
                  size='small'
                  id='outlined-password-input'
                  // type='number'
                  autoComplete='current-password'
                  // value={'149980-02'}
                />
              </div>
              <div style={{ marginTop: 15 }} className='LocationsClass'>
                <TextField
                  InputProps={{
                    disableUnderline: true
                  }}
                  disabled
                  className='RightMarginItems'
                  value={selectedCarrierQuotaionID}
                  onChange={e => setRefOrQuoteNmb(e.target.value)}
                  size='small'
                  sx={{ width: '20vw' }}
                  label='Service Type'
                  type='text'
                  variant='standard'
                  inputProps={{ disableUnderline: true }}
                />
              </div>
            </Box>
            <br />

            <br />
            {(pickupInstContent || dilveryInstContent) && (
              <>
                <div className='OriginTitle'>Remarks</div>
                <hr />
                <Box className='RemarksParent'>
                  {pickupInstContent && (
                    <div>
                      <Typography variant='caption' color='GrayText'>
                        Pickup Instructions:
                      </Typography>
                      <div
                        dangerouslySetInnerHTML={{ __html: pickupInstContent }}
                      />
                    </div>
                  )}
                  {dilveryInstContent && (
                    <div style={{ marginTop: 10 }}>
                      <Typography variant='caption' color='GrayText'>
                        Delivery Instructions:
                      </Typography>
                      <div
                        dangerouslySetInnerHTML={{ __html: dilveryInstContent }}
                      />
                    </div>
                  )}
                </Box>
              </>
            )}
          </Box>
        );
      }
    }
  };

  const handleDoneShipment = DoneStatus => {
    if (DoneStatus === 'booked') {
      history.push({
        pathname: '/manage-shipments',
        state: { selectedTab: 'Booked' }
      });
    }
    if (DoneStatus === 'InTransit') {
      history.push({ pathname: '/', state: { selectedTab: 'InTransit' } });
    }
    if (DoneStatus === 'Delivered') {
      history.push({ pathname: '/', state: { selectedTab: 'Delivered' } });
    }

    // history.push('/');
    // if (props?.location?.state?.disptachOpen !== 'Get Quote') {
    handleReset();
    setShipmentBooked(false);
    setShipmentDispatchMsg('');
    handleResetAllData();
    // }

    // if (props?.location?.state?.disptachOpen === 'Dispatch') {
    //   setOpenOtherCarrier(false);
    //   handleReset();
    //   handlePickupClose();
    //   setShipmentDispatchMsg('');
    //   handleResetAllData();
    // }
  };

  const handleCancelShipment = () => {
    // handlePickupClose();
    handleReset();
    setShipmentFailed(false);
    history.push('/');
  };

  const handleBackShipment = () => {
    setShipmentFailed(false);
    setShipmentDispatchFailed(false);
    handleReset();
  };
  const handleStep = (step, label) => () => {
    setActiveStep(step);
    setActiveTabLabel(label);
  };
  const stepsArray = () => {
    if (props?.location?.state?.disptachOpen === 'Get Quote') {
      const steps = [
        'Pickup Info',
        'Select Carriers',
        'Carrier Rates',
        'Preview'
      ];
      return steps;
    } else {
      const steps = ['Shipment Info', 'Preview'];
      return steps;
    }
  };
  const handleOtherCarrierClose = () => {
    setOpenOtherCarrier(false);
    setOpen(true);
    setCarrierName(null);
    // setOtherCarrierName('');
  };
  const handleSaveOtherCarrier = () => {
    const errors = {};
    if (!otherCarrierName) {
      errors['otherCarrierName'] = 'Enter carrier name';
    } else if (!truckNumber) {
      errors['truckNumber'] = 'Enter truck number';
    } else if (!driverName) {
      errors['driverName'] = 'Enter driver name';
    } else if (!driverNumber) {
      errors['driverNumber'] = 'Enter driver number';
    } else if (phoneNumberValidError) {
      errors['driverNumber'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
    }
    setValidationErrors(errors);
    if (otherCarrierName && truckNumber && driverName && driverNumber) {
      handleOtherCarrierClose();
    }
  };
  const handleNextButton = () => {
    if (stepsArray().length === 2) {
      if (
        props?.location?.state?.disptachOpen === 'Dispatch' &&
        !shipmentDispatchFailed
      ) {
        return 'Dispatch';
      } else if (
        props?.location?.state?.disptachOpen === 'PickUp' &&
        !shipmentDispatchFailed
      ) {
        return 'Confirm PickUp';
      } else {
        return 'Cancel';
      }
    }
    if (
      props?.location?.state?.disptachOpen === 'Get Quote' &&
      !shipmentFailed &&
      stepsArray().length === 4
    ) {
      return 'Confirm';
    }
    if (
      props?.location?.state?.disptachOpen === 'Get Quote' &&
      shipmentFailed &&
      stepsArray().length === 3
    ) {
      return 'Cancel';
    }
  };
  const handlePhoneNumberPaste = event => {
    // Prevent the default paste behavior
    event.preventDefault();

    // Get the pasted text from clipboard
    const pastedText = event.clipboardData.getData('text/plain');

    // Remove non-numeric characters from pasted text
    const cleanedText = pastedText.replace(/\D/g, '');

    // Apply formatting logic
    const formattedValue = cleanedText.replace(
      /^(\d{3})(\d{3})(\d{4})$/,
      '($1) $2-$3'
    );
    setDriverNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, driverNumber: null });
    } else {
      setPhoneNumberValidError(true);
      errors['driverNumber'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };
  const handlePhoneNumberChange = event => {
    const inputValue = event.target.value;
    // Remove non-numeric characters and retain formatting
    const formattedValue = inputValue
      .replace(/\D/g, '')
      .replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    setDriverNumber(formattedValue);

    // Perform validation on the formatted value
    const errors = {};
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    const isValidPhoneNumber = phoneRegex.test(formattedValue);

    if (isValidPhoneNumber) {
      setPhoneNumberValidError(false);
      setValidationErrors({ ...validationErrors, driverNumber: null });
    } else {
      setPhoneNumberValidError(true);
      errors['driverNumber'] = 'Enter Valid Phone Number (XXX) XXX-XXXX';
      setValidationErrors(errors);
    }
  };

  const isMobile = useIsMobile();
  useEffect(() => {}, []);
  const ShipmentContent = () => {
    if (pickUpLoader) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <CircularProgress />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Box>
      );
    }
    if (shipmentFailed || shipmentDispatchFailed) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <WarningAmberIcon style={{ fontSize: 150, color: '#D32F2F' }} />{' '}
          <br />
          <br />
          <Typography variant='h4'>Error</Typography>
          <br />
          <div>{shipmentFailedMsg}</div>
          <br />
          <br />
        </Box>
      );
    }
    if (shipmentDispatchMsg && !shipmentDispatchFailed) {
      return (
        <Box sx={{ textAlign: 'center' }}>
          <br />
          <br />
          <br />
          <br />
          <br />
          <CheckCircleIcon style={{ fontSize: 150, color: '#2E7D32' }} />{' '}
          <Typography variant='h4'>
            {shipmentDispatchMsg === 'InTransit'
              ? 'Your shipment has been picked-up and saved in-transit'
              : 'Your shipment has been picked-up and saved in delivered'}
          </Typography>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          {/* <br /> */}
          <Button
            sx={{
              width: '25%',
              height: 40
            }}
            variant='contained'
            onClick={() => handleDoneShipment(shipmentDispatchMsg)}
          >
            Done
          </Button>
        </Box>
      );
    }
    if (shipmentBooked) {
      return (
        <>
          <Box sx={{ textAlign: 'center' }}>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <CheckCircleIcon style={{ fontSize: 150, color: '#2E7D32' }} />{' '}
            <Typography variant='h4'>{pickupSuccessRemarks}</Typography>
            <br />
            <div className='ParentCopyNmb'>
              <Typography className='OuterCopyNmb'>
                Your Shipment has been Booked!
              </Typography>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button
              sx={{
                width: '25%',
                height: 40
              }}
              variant='contained'
              onClick={() => handleDoneShipment('booked')}
            >
              Done
            </Button>
          </Box>
        </>
      );
    } else {
      return (
        <Box sx={{ height: 'auto', width: '100%', overflow: 'hidden' }}>
          <Stepper
            // className='PickupStepperParent'
            activeStep={activeStep}
            alternativeLabel={isMobile ? true : false}
            // nonLinear
          >
            {stepsArray().map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    color='inherit'
                    onClick={handleStep(index, label)}
                  >
                    {label}
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          {handleStepperContent(activeStep, activeTabLabel)}
        </Box>
      );
    }
  };

  return (
    <Grid sx={{ display: 'flex', justifyContent: 'center' }} container>
      <Grid item xs={12} sm={12} lg={11} xl={11}>
        <Box sx={{ maxHeight: '90vh' }}>
          <Paper className='Grid_Item_Get_Quote'>
            <Dialog
              className='InnerDialog'
              open={openOtherCarrier}
              onClose={handleOtherCarrierClose}
            >
              <DialogContent>
                <DialogContentText>
                  <TextField
                    variant='standard'
                    error={Boolean(validationErrors['otherCarrierName'])}
                    helperText={validationErrors['otherCarrierName']}
                    value={otherCarrierName}
                    onChange={e => {
                      setOtherCarrierName(e.target.value);
                      setValidationErrors({
                        ...validationErrors,
                        otherCarrierName: null
                      });
                    }}
                    sx={{ width: '100%', mt: 5 }}
                    size='small'
                    label='Carrier Name'
                  />
                  <TextField
                    variant='standard'
                    error={Boolean(validationErrors['truckNumber'])}
                    helperText={validationErrors['truckNumber']}
                    value={truckNumber}
                    onChange={e => {
                      setTruckNumber(e.target.value);
                      setValidationErrors({
                        ...validationErrors,
                        truckNumber: null
                      });
                    }}
                    sx={{ width: '100%', mt: 5 }}
                    size='small'
                    label='Truck Number'
                  />
                  <TextField
                    variant='standard'
                    error={Boolean(validationErrors['driverName'])}
                    helperText={validationErrors['driverName']}
                    value={driverName}
                    onChange={e => {
                      setDriverName(e.target.value);
                      setValidationErrors({
                        ...validationErrors,
                        driverName: null
                      });
                    }}
                    sx={{ width: '49%', mt: 5 }}
                    size='small'
                    label='Driver Name'
                  />
                  <TextField
                    variant='standard'
                    error={Boolean(validationErrors['driverNumber'])}
                    helperText={validationErrors['driverNumber']}
                    value={driverNumber}
                    onChange={handlePhoneNumberChange}
                    onPaste={handlePhoneNumberPaste}
                    sx={{ width: '49%', margin: '40 0 0 8' }}
                    size='small'
                    label='Driver Number'
                    type='text'
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{ width: '49%' }}
                  variant='outlined'
                  onClick={handleOtherCarrierClose}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ width: '49%' }}
                  variant='contained'
                  onClick={handleSaveOtherCarrier}
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
            <div>
              {ShipmentContent()}
              {!shipmentDispatchMsg && !shipmentBooked && (
                <div
                  style={
                    isMobile ? { display: 'flex', flexDirection: 'column' } : {}
                  }
                  className={
                    props?.location?.state?.disptachOpen === 'Get Quote'
                      ? 'NextBtnRates'
                      : 'NextBtnDiv'
                  }
                >
                  {activeStep !== 0 && (
                    <Button
                      fullWidth={isMobile ? true : false}
                      disabled={activeStep === 2 ? true : false}
                      sx={isMobile ? { mt: 6 } : { mr: 2, mt: 5 }}
                      onClick={
                        shipmentFailed || shipmentDispatchFailed
                          ? handleBackShipment
                          : handleBack
                      }
                      // className='BtnColor'
                      variant='outlined'
                    >
                      <ChevronLeftIcon sx={{ m: 0 }} className='PrevBtnIcon' />{' '}
                      Previous
                    </Button>
                  )}
                  <Button
                    fullWidth={isMobile ? true : false}
                    sx={
                      isMobile && activeStep === 0
                        ? { mt: 9 }
                        : isMobile && activeStep === 1
                        ? { mt: 2 }
                        : { mt: 5 }
                    }
                    disabled={
                      ((activeStep === 1 && enableNext) ||
                        (activeStep === 2 &&
                          selectedCarrierNameQuoteRates === '') ||
                        (activeStep === 2 &&
                          selectedCarrierNameQuoteRates === 0)) &&
                      props?.location?.state?.disptachOpen === 'Get Quote'
                        ? true
                        : false
                    }
                    onClick={
                      shipmentFailed || shipmentDispatchFailed
                        ? () => handleCancelShipment()
                        : () => handleNext(activeStep)
                    }
                    className='BtnColor'
                    variant='contained'
                  >
                    {' '}
                    {activeStep === stepsArray().length - 1
                      ? handleNextButton()
                      : shipmentFailed || shipmentDispatchFailed
                      ? 'Cancel'
                      : props?.location?.state?.disptachOpen === 'Get Quote' &&
                        activeStep === 0
                      ? 'Next'
                      : props?.location?.state?.disptachOpen === 'Get Quote' &&
                        activeStep === 2
                      ? 'Book'
                      : 'Next'}
                    {/* {!shipmentFailed ||
                    (shipmentDispatchFailed && ( */}
                    <ChevronRightIcon className='NextBtnIcon' />
                    {/* ))} */}
                  </Button>
                </div>
              )}
            </div>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
}
